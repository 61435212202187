import { FC, useState } from "react";
import { useTheme, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  Menu,
  DataTable,
  Button,
  Input,
  Box,
  Switch,
} from "@ntpkunity/controls";
import { ConfirmationDialog, PaginationWrap } from "@sharedComponents";
import ChevronDown from "../../shared/assets/images/chevron-down";

import {
  UseQuery_getAllContractTerms_filter,
  UseMutation_UpdateContractTerms,
  UseMutation_DeleteContractTerms,
} from "services";
import { styled } from "@mui/material/styles";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import {
  IContractTerms,
  IContractTermsFilter,
  ICreditRating,
  ICreditRatingFilter,
} from "Interface";
import { useQueryClient } from "react-query";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const ContractTermsTable: FC<{ onEdit: (data: IContractTerms) => unknown }> = ({
  onEdit,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data } = UseQuery_getAllContractTerms_filter(columnFilters);

  const [codeFilter, setCodeFilter] = useState("");
  const [valueFilter, setValueFilter] = useState();
  const [statusFilter, setStatusFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (valueFilter) {
      query_string = query_string.concat(`&term=${valueFilter}`);
    }
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&code=${codeFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&status=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&status=${false}`);
      } else {
        query_string = query_string.concat(`&status=None`);
      }
    }
    return query_string;
  };
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const contractTermsFilter: IContractTermsFilter = data;

  const [actionData, setActionData] = useState<ICreditRating>(undefined);
  const { mutate: contractTerms } = UseMutation_DeleteContractTerms();
  const { mutate: UpdateContractTerms } =
    UseMutation_UpdateContractTerms(undefined);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    contractTerms(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };
  44;

  const handleEnableChange = (data: IContractTerms, event) => {
    UpdateContractTerms({ ...data, is_active: event.target.checked });
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        variant={"basic"}
        theadChildren={
          <>
            <TableRow>
              {/* <TableCell className="checkbox-cell">
              <Checkbox label=""></Checkbox>
            </TableCell> */}
              <TableCell>Code</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
            <TableRow className="filters-row">
              {/* <TableCell className="checkbox-cell"></TableCell> */}
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setCodeFilter(value);
                    }}
                    value={codeFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    onChange={(value) => {
                      setValueFilter(value);
                    }}
                    value={valueFilter}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          contractTermsFilter?.result &&
          contractTermsFilter.result.map((contractTerm, index) => (
            <TableRow key={index} className="child-tr">
              <TableCell>{contractTerm.code}</TableCell>
              <TableCell>{contractTerm.term}</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={"basic"}
                  switchEnabled={contractTerm.is_active}
                  onChange={(event) => handleEnableChange(contractTerm, event)}
                  label={contractTerm.is_active ? "Enabled" : "Disabled"}
                />
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={tableOptions.map((option) => {
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: contractTerm,
                    };
                  })}
                  handleOptionClick={handleSelection}
                  render={(onMenuSelection) => (
                    <Button
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </TableCell>
            </TableRow>
          ))
        }
      ></DataTable>
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={contractTermsFilter ? contractTermsFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default ContractTermsTable;
