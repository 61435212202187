import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Accordion, Button, Icon, Input } from "@ntpkunity/controls";
import { Controller, get } from "react-hook-form";

const DealerSocial: FC<{
  form: any;
  profiledata: any;
  setSocialDirty: any;
}> = ({ form, profiledata, setSocialDirty }) => {
  const theme = useTheme();
  const {
    control,
    resetField,
    formState: { errors },
  } = form;

  const [isFacebook, setFacebook] = useState(true);
  const [isInstagram, setInstagram] = useState(false);
  const [isGoogle, setGoogle] = useState(false);
  const [isTwitter, setTwitter] = useState(false);
  const [isLinkedIn, setLinkedin] = useState(false);
  const [isYoutube, setYoutube] = useState(false);

  return (
    <>
      <Accordion
        theme={theme}
        varient="grouped"
        shouldCollapse={false}
        items={[
          {
            title: "Facebook",
            subtitle: profiledata?.facebook ?? "",
            isExpanded: isFacebook,
            key: "0",
            onOpenTab(event, e) {
              setFacebook(true);
            },
            onCloseTab(event, e) {
              setFacebook(false);
            },

            content: (
              <>
                <Controller
                  name="profile.facebook"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      {...field}
                      label="URL"
                      placeholder="Type here.."
                      error={errors?.profile?.facebook?.message}
                      value={field.value ?? ""}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={(event) => {
                    event.stopPropagation();
                    setSocialDirty(true);
                    resetField("profile.facebook", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "Google",
            subtitle: profiledata?.google ?? "",
            isExpanded: isGoogle,
            key: "1",
            onOpenTab(event, e) {
              setGoogle(true);
            },
            onCloseTab(event, e) {
              setGoogle(false);
            },
            content: (
              <>
                <Controller
                  name="profile.google"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      {...field}
                      value={field.value ?? ""}
                      error={errors?.profile?.google?.message}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={(event) => {
                    event.stopPropagation();
                    setSocialDirty(true);
                    resetField("profile.google", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "Instagram",
            subtitle: profiledata?.instagram ?? "",
            isExpanded: isInstagram,
            key: "2",
            onOpenTab(event, e) {
              setInstagram(true);
            },
            onCloseTab(event, e) {
              setInstagram(false);
            },
            content: (
              <>
                <Controller
                  name="profile.instagram"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      {...field}
                      value={field.value ?? ""}
                      error={errors?.profile?.instagram?.message}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={(event) => {
                    event.stopPropagation();
                    setSocialDirty(true);
                    resetField("profile.instagram", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "LinkedIn",
            subtitle: profiledata?.linkedin ?? "",
            isExpanded: isLinkedIn,
            key: "3",
            onOpenTab(event, e) {
              setLinkedin(true);
            },
            onCloseTab(event, e) {
              setLinkedin(false);
            },
            content: (
              <>
                <Controller
                  name="profile.linkedin"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      {...field}
                      value={field.value ?? ""}
                      error={errors?.profile?.linkedin?.message}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={(event) => {
                    event.stopPropagation();
                    setSocialDirty(true);
                    resetField("profile.linkedin", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "Twitter",
            subtitle: profiledata?.twitter ?? "",
            isExpanded: isTwitter,
            key: "4",
            onOpenTab(event, e) {
              setTwitter(true);
            },
            onCloseTab(event, e) {
              setTwitter(false);
            },
            content: (
              <>
                <Controller
                  name="profile.twitter"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      {...field}
                      value={field.value ?? ""}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={(event) => {
                    event.stopPropagation();
                    setSocialDirty(true);
                    resetField("profile.twitter", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "Youtube",
            subtitle: profiledata?.youtube ?? "",
            isExpanded: isYoutube,
            key: "5",
            onOpenTab(event, e) {
              setYoutube(true);
            },
            onCloseTab(event, e) {
              setYoutube(false);
            },
            content: (
              <>
                <Controller
                  name="profile.youtube"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      {...field}
                      value={field.value ?? ""}
                      error={errors?.profile?.youtube?.message}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={(event) => {
                    event.stopPropagation();
                    setSocialDirty(true);
                    resetField("profile.youtube", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default DealerSocial;
