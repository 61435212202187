import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "../Typography";
import { IAccordionProps } from "./Accordion.types";
import Icon from "../../assets/images/chevron";
import Box from "../Box";
import clsx from "clsx";

const Accordion = styled(MuiAccordion, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IAccordionProps>>(({ theme }) => ({
  "&.u-accordian-wrap": {
    color: theme.palette.common.black,
    border: "none",
    marginBottom: 24,
    "&::before": {
      display: "none",
    },
    "&.u-accordion-group": {
      marginBottom: 0,
      borderRadius: theme.shape.borderRadius,
      border: "1px solid" + theme.palette.grey[100],
      "&:not(:last-of-type)": {
        borderBottom: "0px none",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: 0,
      },
      "&:last-of-type": {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
  },
}));

const AccordionSummary = styled(MuiAccordionSummary, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IAccordionProps>>(({ theme }) => ({
  "&.u-accordion-header": {
    backgroundColor: "transparent",
    "&.filter-accordion": {
      padding: 0,
      minHeight: 0,
      ".MuiAccordionSummary-content": {
        margin: 0,
      },
    },
    ".MuiAccordionSummary-content": {
      justifyContent: "space-between",
    },
    ".MuiAccordionSummary-expandIconWrapper": {
      marginLeft: 8,
    },
    "&.Mui-expanded": {
      ".MuiAccordionSummary-expandIconWrapper": {
        "&.Mui-expanded": {
          transform: "rotate(180deg)",
        },
      },
    },
    ".sub-title": {
      color: theme.palette.grey[900],
      maxWidth: 160,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    "&.u-accordion-group-header": {
      padding: "4px 16px 4px 16px",
      ".MuiAccordionSummary-content": {
        flexWrap: "wrap",
        margin: 0,
      },
      ".main-title": {
        fontWeight: theme.customVariables.fontWeightSemiBold,
        flex: "0 0 30%",
        maxWidth: "30%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        ".title-with-icon": {
          "span, svg": {
            float: "left",
          },
          span: {
            maxWidth: "90%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
          svg: {
            marginLeft: 10,
            height: 20,
          },
        },
      },
      ".sub-title": {
        color: theme.palette.grey[600],
        flex: "0 0 60%",
        maxWidth: "60%",
      },
      ".action-btn": {
        ".btn": {
          padding: "0 10px",
        },
      },
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IAccordionProps>>(({ theme }) => ({
  "&.u-accordian-body": {
    borderTop: "none",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[100],
    "&.filter-accordion": {
      padding: "0px 0 24px",
      maxHeight: 144,
      overflowX: "hidden",
      overflowY: "auto",
      paddingTop: 24,
      "@supports (-moz-appearance:none)": {
        scrollbarWidth: "thin",
      },
      "&::-webkit-scrollbar": {
        width: 7,
        height: 7,
      },
      "&::-webkit-scrollbar-track": {
        padding: "0 1px",
        backgroundColor: "#f0f0f0",
      },
      "&::-webkit-scrollbar-thumb": {
        transition: "all 0.3s",
        backgroundColor: "#cdcdcd",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#a6a6a6",
      },
    },
    "&.u-accordion-group-body": {
      padding: 40,
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "none",
    },
  },
}));

const CustomizedAccordions: React.FC<IAccordionProps> = ({
  items,
  theme,
  className,
  variant1,
  ...props
}) => {
  const [accordions, setAccordions] = React.useState(items);
  const handleChange = (
    event: any,
    key: string,
    onClose?: (event: any, e: string) => void,
    onOpen?: (event: any, e: string) => void
  ) => {
    const updated = accordions.map((e) => {
      if (e.key === key) {
        if (e.isExpanded) {
          onClose?.(event, e.key);
        } else {
          onOpen?.(event, e.key);
        }
        return {
          ...e,
          isExpanded: e.key === key && !e.isExpanded,
        };
      }
      return e;
    });
    setAccordions(updated);
  };

  React.useEffect(() => {
    setAccordions(items);
  }, [items]);

  return (
    <>
      {accordions.map(
        ({
          content,
          key,
          onCloseTab,
          onOpenTab,
          isExpanded,
          title,
          subtitle,
          actionBtn,
        }) => (
          <Accordion
            {...props}
            className={clsx({
              "u-accordian-wrap": true,
              "u-accordion-group": variant1 === "grouped",
              className,
            })}
            disableGutters
            elevation={0}
            key={key}
            square
            defaultExpanded={isExpanded}
            onChange={(e) => handleChange(e, key, onCloseTab, onOpenTab)}
            theme={theme}
          >
            <AccordionSummary
              className={clsx({
                "u-accordion-header": true,
                "filter-accordion": variant1 === "filters",
                "u-accordion-group-header": variant1 === "grouped",
              })}
              expandIcon={<Icon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
              theme={theme}
            >
              {title && (
                <Typography
                  theme={theme}
                  className="main-title"
                  variant="subtitle2"
                  component="span"
                >
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography
                  theme={theme}
                  className="sub-title"
                  variant="body2"
                  component="span"
                >
                  {subtitle}
                </Typography>
              )}
              {actionBtn && (
                <Box theme={theme} className="action-btn">
                  {actionBtn}
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails
              theme={theme}
              className={clsx({
                "u-accordian-body": true,
                "filter-accordion": variant1 === "filters",
                "u-accordion-group-body": variant1 === "grouped",
              })}
            >
              {typeof content === "string" ? (
                <Typography theme={theme} variant="body1" component="span">
                  {content}
                </Typography>
              ) : (
                <>{content}</>
              )}
            </AccordionDetails>
          </Accordion>
        )
      )}
    </>
  );
};

export default CustomizedAccordions;
