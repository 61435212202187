import { FC, useState, useEffect } from "react";
import { Grid, Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { useTheme } from "@mui/material";
import { AddNewAssetCondition } from "components";
import { ActionOptions } from "Enums";
import { IAssetCondition } from "Interface";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import AssetConditionTable from "./asset-condition-table";

const AssetCondition: FC = () => {
  const [AssetConditionState, setAssetConditionState] =
    useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Asset Condition"
  );
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<IAssetCondition>(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (AssetConditionState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [AssetConditionState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Asset Condition");
        setActionData(value);
        setAssetConditionState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IAssetCondition) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Asset Condition");
    setActionData(formData);
    setAssetConditionState(true);
    dispatch(setOpenDrawer(true));
  };

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <AssetConditionTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Asset Condition"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {AssetConditionState && (
          <AddNewAssetCondition
            openPopUp={AssetConditionState}
            setPopUpState={setAssetConditionState}
            title={actionTitle}
            actionType={actionType}
            AssetConditionData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default AssetCondition;
