import { FC } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material";
import { Textarea } from "@ntpkunity/controls";
import { TextAreaWrap } from "@sharedComponents";
import { validation } from "shared/helper/methods";

const IndividualizedAgreementDrawer: FC<{ form: any }> = ({ form }) => {
  const theme = useTheme();

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <>
      <TextAreaWrap className="full-drawer-height mb-0">
        <Controller
          name="agreement_clause"
          control={control}
          defaultValue={""}
          rules={validation("Individualized Agreement", true)}
          render={({ field }) => (
            <Textarea
              theme={theme}
              label={"Individualized Agreement"}
              type="text"
              id="IndividualizedAgreementId"
              {...field}
              error={errors?.agreement_clause?.message}
              rows={3}
              fullWidth
            />
          )}
        />
      </TextAreaWrap>
    </>
  );
};

export default IndividualizedAgreementDrawer;
