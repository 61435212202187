import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  Select,
  Typography,
  Button,
  ConfirmationDialog,
} from "@sharedComponents";
import { INewAnnualMileagePopUp } from "./AddNewAnnualMileage.type";
import { Controller, useForm } from "react-hook-form";
import {
  UseMutation_AddAnnualMileage,
  UseMutation_UpdateAnnualMileage,
} from "services";
import { IAnnualMileage } from "Interface";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store/SetupsStoreProvider";
import { useQueryClient } from "react-query";
import { Grid, PersistentDrawer, Input, Icon } from "@ntpkunity/controls";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";

const NewAnnualMileage: FC<INewAnnualMileagePopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  annualMileageData,
  enabledPin,
}) => {
  const [state] = useSetupsStore();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [mileageRange, setMileageRange] = useState<number[]>([0, 100000]);
  const [isMileageUnitDisabled, setIsMileageUnitDisabled] =
    useState<boolean>(false);
  const [mileageUnit, setMileageUnit] = useState<string>(
    companyProfile?.mileage_unit ? companyProfile?.mileage_unit : "Kilometers"
  );

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isDirty },
    watch,
  } = useForm<IAnnualMileage>();
  const watchMileages = watch(["distance_from", "distance_to"]);
  const watchMileageUnit = watch(["mileage_unit"]);
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const { mutate: AddAnnualMileage } = UseMutation_AddAnnualMileage();
  const { mutate: EditAnnualMileage } = UseMutation_UpdateAnnualMileage(
    getValues("id")
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setMileageUnit(value.mileage_unit);
      setIsMileageUnitDisabled(companyProfile?.mileage_unit ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watchMileageUnit]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let distanceFrom, distanceTo;
      distanceFrom =
        getValues("distance_from") >= 0 ? getValues("distance_from") : 0;
      distanceTo = getValues("distance_to") >= 0 ? getValues("distance_to") : 0;
      setMileageRange([+distanceFrom, +distanceTo]);
    });
    return () => subscription.unsubscribe();
  }, [watchMileages]);

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", annualMileageData.id);
    }
    setValue(
      "distance_from",
      annualMileageData ? annualMileageData.distance_from : mileageRange[0]
    );
    setValue(
      "distance_to",
      annualMileageData ? annualMileageData.distance_to : mileageRange[1]
    );
    setValue(
      "mileage_unit",
      annualMileageData ? annualMileageData.mileage_unit : mileageUnit
    );
    setValue(
      "is_active",
      annualMileageData ? annualMileageData.is_active : true
    );

    setMileageRange([getValues("distance_from"), getValues("distance_to")]);

    if (actionType === ActionOptions.ADD) {
      reset();
    }
  }, [annualMileageData]);

  function onSubmit(data) {
    if (data.distance_from >= 0 && data.distance_from <= 100000) {
      clearErrors("distance_from");
    } else {
      setError("distance_from", {
        message: "Value should be in between 0 - 100000",
      });
    }
    if (data.distance_to >= 0 && data.distance_to <= 100000) {
      clearErrors("distance_to");
    } else {
      setError("distance_to", {
        message: "Value should be in between 0 - 100000",
      });
    }
    if (
      actionType === ActionOptions.ADD &&
      data.distance_to >= data.distance_from &&
      data.distance_to <= 100000 &&
      data.distance_from >= 0 &&
      data.distance_from <= 100000
    ) {
      AddAnnualMileage(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {},
      });
    }
    if (
      actionType === ActionOptions.EDIT &&
      data.distance_to >= data.distance_from &&
      data.distance_to <= 100000 &&
      data.distance_from >= 0 &&
      data.distance_from <= 100000
    ) {
      EditAnnualMileage(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {},
      });
      onClose();
    }
    setMileageRange([0, 100000]);
  }

  const onSliderChange = (value: Array<number>) => {
    setValue("distance_from", value[0]);
    setValue("distance_to", value[1]);
    setMileageRange(value);
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const onCancel = () => {
    onClose();
  };
  const onDistanceFromChange = (e) => {
    if (e > 100000) {
      setError("distance_from", {
        message: "Value should be in between 0 - 100000",
      });
      setValue("distance_from", parseInt(e));
    } else {
      setValue("distance_from", parseInt(e));
      clearErrors("distance_from");
    }
  };

  const onDistanceToChange = (e) => {
    if (e > 100000) {
      setError("distance_to", {
        message: "Value should be in between 0 - 100000",
      });
      setValue("distance_to", parseInt(e));
    } else {
      setValue("distance_to", parseInt(e));
      clearErrors("distance_to");
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
              <Grid theme={theme} item xs={12} style={{ display: "none" }}>
                <Controller
                  name="mileage_unit"
                  control={control}
                  defaultValue={mileageUnit}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      theme={theme}
                      label={"Mileage Unit"}
                      id="mileage_unit"
                      items={[
                        { text: "Kilometers", value: "Kilometers" },
                        { text: "Miles", value: "Miles" },
                      ]}
                      value={value}
                      onChange={onChange}
                      disabled={isMileageUnitDisabled}
                    ></Select>
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="distance_from"
                  control={control}
                  defaultValue={0}
                  rules={{
                    required: ValidationMessages.ANNUAL_MILEAGE_FROM_REQUIRED,
                    max: {
                      value: getValues("distance_to"),
                      message:
                        ValidationMessages.ANNUAL_MILEAGE_FROM_LESS_THAN_ANNUAL_MILEAGE_TO,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"0"}
                      label={"Annual Mileage From"}
                      type="text"
                      adornmentOnFocus
                      endAdornment={
                        <>
                          <span className="MuiInputAdornment-root">
                            <Typography
                              theme={theme}
                              variant="caption"
                              children={mileageUnit}
                            />
                          </span>
                        </>
                      }
                      onChange={(e) => {
                        onDistanceFromChange(e);
                      }}
                      value={value}
                      maskNumeric
                      maskDecimalScale={0}
                      masking
                      error={errors?.distance_from?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="distance_to"
                  defaultValue={100000}
                  control={control}
                  rules={{
                    required: ValidationMessages.ANNUAL_MILEAGE_TO_REQUIRED,
                    min: {
                      value: getValues("distance_from"),
                      message:
                        ValidationMessages.ANNUAL_MILEAGE_TO_GREATER_THAN_ANNUAL_MILEAGE_FROM,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      fullWidth={true}
                      theme={theme}
                      placeholder={"0"}
                      label={"Annual Mileage To"}
                      type="text"
                      id="distance_to"
                      adornmentOnFocus
                      endAdornment={
                        <>
                          <span className="MuiInputAdornment-root">
                            <Typography
                              theme={theme}
                              variant="caption"
                              children={mileageUnit}
                            />
                          </span>
                        </>
                      }
                      onChange={(e) => {
                        onDistanceToChange(e);
                      }}
                      value={value}
                      maskNumeric
                      maskDecimalScale={0}
                      masking
                      error={errors?.distance_to?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="is_active"
                  control={control}
                  defaultValue={true}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      theme={theme}
                      label={"Status"}
                      placeholder={"Select"}
                      items={[
                        { text: "Enabled", value: true },
                        { text: "Disabled", value: false },
                      ]}
                      value={value}
                      onChange={onChange}
                    ></Select>
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <Button
            primary
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default NewAnnualMileage;
