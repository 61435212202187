import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Input, Select, Button, ConfirmationDialog } from "@sharedComponents";
import { useStoreContext } from "Stores/SetupStore/Store";
import { INewCreditRatingPopUp } from "./AddNewCreditRating.type";
import { ICreditRating } from "Interface";
import { Controller, useForm } from "react-hook-form";
import {
  UseMutation_AddCreditRating,
  UseMutation_UpdateCreditRating,
} from "services";
import { ActionOptions, ValidationMessages, DialogMessages } from "Enums";
import { logout } from "@ntpkunity/controls-ums";
import { Grid, Icon, PersistentDrawer } from "@ntpkunity/controls";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";

const AddNewCreditRating: FC<INewCreditRatingPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  creditRatingData,
  enabledPin,
}) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm<ICreditRating>();
  const watchScores = watch(["score_from", "score_to"]);
  const { actions } = useStoreContext();
  const [creditScoreRange, setCreditScoreRange] = useState<number[]>([0, 1500]);
  const { mutate: AddCreditRating } = UseMutation_AddCreditRating();
  const { mutate: UpdateCreditRating } = UseMutation_UpdateCreditRating(
    getValues("id")
  );
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let scoreFrom, ScoreTo;
      scoreFrom = getValues("score_from") >= 0 ? getValues("score_from") : 0;
      ScoreTo = getValues("score_to") >= 0 ? getValues("score_to") : 0;
      setCreditScoreRange([+scoreFrom, +ScoreTo]);
    });
    return () => subscription.unsubscribe();
  }, [watchScores]);

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", creditRatingData?.id);
    }
    setValue("description", creditRatingData?.description);
    setValue(
      "score_from",
      creditRatingData ? creditRatingData.score_from : creditScoreRange[0]
    );
    setValue(
      "score_to",
      creditRatingData ? creditRatingData.score_to : creditScoreRange[1]
    );
    setValue("is_active", creditRatingData ? creditRatingData.is_active : true);
    setCreditScoreRange([getValues("score_from"), getValues("score_to")]);
    if (actionType === ActionOptions.ADD) {
      reset();
      setCreditScoreRange([0, 1500]);
    }
  }, [creditRatingData]);

  function onSubmit(data) {
    data.score_from = data.score_from >= 0 ? data.score_from : 0;
    data.score_to = data.score_to >= 0 ? data.score_to : 0;
    if (actionType === ActionOptions.ADD) {
      AddCreditRating(data, {
        onSuccess: (response) => {
          reset();
          setCreditScoreRange([0, 1500]);
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.description ===
            "Description should be unique"
          ) {
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
    if (actionType === ActionOptions.EDIT) {
      UpdateCreditRating(data, {
        onSuccess: (response) => {
          reset();
          setCreditScoreRange([0, 1500]);
          onClose();
        },
        onError: (error) => {
          if (error?.response?.data?.detail?.code === 400) {
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  const onScoreFromChange = (e) => {
    if ((e < 1500 && e >= 0) || e == "") {
      setValue("score_from", parseInt(e));
    }
  };
  const onScoreToChange = (e) => {
    if ((e <= 1500 && e > 0) || e == "") {
      setValue("score_to", parseInt(e));
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: ValidationMessages.DESCRIPTION_REQUIRED,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      id="drawerDescription"
                      testid="drawerDescriptionTest"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Type here..."}
                      label={"Description"}
                      type="text"
                      value={value?.trimStart()}
                      onChange={onChange}
                      error={errors?.description?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="score_from"
                  control={control}
                  defaultValue={creditScoreRange[0]}
                  rules={{
                    required: ValidationMessages.CREDIT_SCORE_FROM_REQUIRED,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      id="drawerScoreFrom"
                      testid="drawerScoreFromTest"
                      theme={theme}
                      placeholder={"0"}
                      label={"Credit Score From"}
                      fullWidth={true}
                      type="number"
                      value={value}
                      onChange={onScoreFromChange}
                      error={errors?.score_from?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="score_to"
                  control={control}
                  defaultValue={creditScoreRange[1]}
                  rules={{
                    required: ValidationMessages.CREDIT_SCORE_TO_REQUIRED,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      id="drawerScoreTo"
                      testid="drawerScoreToTest"
                      theme={theme}
                      placeholder={"1500"}
                      label={"Credit Score To"}
                      fullWidth={true}
                      type="number"
                      value={value}
                      onChange={onScoreToChange}
                      error={errors?.score_to?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="is_active"
                  control={control}
                  defaultValue={true}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      id="drawerStatus"
                      testid="drawerStatusTest"
                      theme={theme}
                      label={"Status"}
                      placeholder={"Select"}
                      items={[
                        { text: "Enabled", value: true },
                        { text: "Disabled", value: false },
                      ]}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <Button
            id="saveCreditScore"
            primary
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default AddNewCreditRating;
