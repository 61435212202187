import { FC, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  Menu,
  DataTable,
  Checkbox,
  Button,
  Input,
  Box,
  Switch,
  ImageViewer,
} from "@ntpkunity/controls";
import {
  useUpdateOemOption,
  useDeleteOemOptionById,
  UseQuery_getAllOEMOptions_filter,
} from "services/options.service";
import ChevronDown from "shared/assets/images/chevron-down";
import { ConfirmationDialog, PaginationWrap } from "@sharedComponents";

import { IOemOption, IOemOptionFilter } from "Interface";
import { DialogMessages, QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { TablePagination, useTheme, Link } from "@mui/material";
import { useQueryClient } from "react-query";

const OemOptionsTable: FC<{
  categoryDropDownDataProp;
  onEdit: (data: any) => unknown;
  isEditMode: boolean;
}> = ({ categoryDropDownDataProp, onEdit, isEditMode }) => {
  const { actions } = useStoreContext();
  const { mutate: updateDealerOemOption } = useUpdateOemOption();
  const { mutate: deleteDealerOemOption } = useDeleteOemOptionById();
  const [rowId, setRowId] = useState<number>();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [product_nameFilter, setProduct_nameFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [offered_byFilter, setOffered_byFilter] = useState("");
  const [part_noFilter, setPart_noFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [installation_modeFilter, setInstallation_modelFilter] = useState("");
  const [compatible_modelsFilter, setCompatible_modelsFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );
  const [priceFilter, setPriceFilter] = useState("");
  const [rvFilter, setRvFilter] = useState("");
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [images, setImages] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [index, setIndex] = useState(0);
  const queryClient = useQueryClient();

  const setQueryString = (pgNo, pgSize) => {
    let queryString: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (
      product_nameFilter.trimStart() != null &&
      product_nameFilter.trimStart() != ""
    ) {
      queryString = queryString.concat(`&product_name=${product_nameFilter}`);
    }
    if (
      categoryFilter.trimStart() != null &&
      categoryFilter.trimStart() != ""
    ) {
      queryString = queryString.concat(`&category=${categoryFilter}`);
    }
    if (priceFilter) {
      queryString = queryString.concat(`&price=${priceFilter}`);
    }
    if (rvFilter) {
      queryString = queryString.concat(`&rv_adder=${rvFilter}`);
    }
    if (
      offered_byFilter.trimStart() != null &&
      offered_byFilter.trimStart() != ""
    ) {
      queryString = queryString.concat(`&offered_by=${offered_byFilter}`);
    }
    if (
      installation_modeFilter.trimStart() != null &&
      installation_modeFilter.trimStart() != ""
    ) {
      queryString = queryString.concat(
        `&installation_mode=${installation_modeFilter}`
      );
    }
    if (
      compatible_modelsFilter.trimStart() != null &&
      compatible_modelsFilter.trimStart() != ""
    ) {
      queryString = queryString.concat(
        `&compatible_models=${compatible_modelsFilter}`
      );
    }
    if (part_noFilter.trimStart() != null && part_noFilter.trimStart() != "") {
      queryString = queryString.concat(`&part_no=${part_noFilter}`);
    }
    if (
      descriptionFilter.trimStart() != null &&
      descriptionFilter.trimStart() != ""
    ) {
      queryString = queryString.concat(`&description=${descriptionFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        queryString = queryString.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        queryString = queryString.concat(`&is_active=${false}`);
      } else {
        queryString = queryString.concat(`&is_active=None`);
      }
    }
    return queryString;
  };
  const { data } = UseQuery_getAllOEMOptions_filter(columnFilters);
  const OEMOptionsFilter: IOemOptionFilter = data;
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const onDeleteConfirm = (e: any) => {
    deleteDealerOemOption(
      { id: rowId },
      {
        onSuccess: () => {
          actions.setToast({
            toastMessage: ToastMessages.Record_Deleted_Success,
            toastState: true,
          });
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  const handleImage = (imagesData) => {
    const image_url = imagesData?.map((image) => {
      return image?.location;
    });

    setImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  const theme = useTheme();

  useEffect(() => {
    if (isEditMode) {
      queryClient.invalidateQueries(QueryKeys.GET_ALL_OEM_OPTIONS_FILTER);
    }
  }, [isEditMode]);

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        sx={{ mb: 3 }}
        theadChildren={
          <>
            <TableRow>
              {/* <TableCell className="checkbox-cell fixed-left">
                <Checkbox label=""></Checkbox>
              </TableCell> */}
              <TableCell align="center" valign="middle" className="img-cell">
                <Icon name="ImageIcon" />
              </TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Part No.</TableCell>
              <TableCell>Installation Mode</TableCell>
              <TableCell>Compatible Assets</TableCell>
              <TableCell>Price</TableCell>
              <TableCell> RV On Option</TableCell>
              <TableCell className="action-cell fixed-cell space-fixed" />
            </TableRow>
            <TableRow className="filters-row">
              {/* <TableCell className="checkbox-cell"></TableCell> */}
              <TableCell className="img-cell"></TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setProduct_nameFilter(value);
                    }}
                    value={product_nameFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setDescriptionFilter(value);
                    }}
                    value={descriptionFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setCategoryFilter(value);
                    }}
                    value={categoryFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setOffered_byFilter(value);
                    }}
                    value={offered_byFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setPart_noFilter(value);
                    }}
                    value={part_noFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setInstallation_modelFilter(value);
                    }}
                    value={installation_modeFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setCompatible_modelsFilter(value);
                    }}
                    value={compatible_modelsFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    onChange={(value) => {
                      setPriceFilter(value);
                    }}
                    value={priceFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setRvFilter(value);
                    }}
                    value={rvFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {OEMOptionsFilter?.result &&
              OEMOptionsFilter?.result.map(
                (addOnDetail: IOemOption, index: number) => {
                  return (
                    <TableRow key={index} className="child-tr">
                      {/* <TableCell className="checkbox-cell fixed-left">
                      <Checkbox label=""></Checkbox>
                    </TableCell> */}
                      <TableCell className="img-cell">
                        {addOnDetail?.image?.length > 0 && (
                          <Link
                            onClick={() => {
                              handleImage(addOnDetail.image);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              className="prd-img"
                              src={addOnDetail.image[0]?.location}
                              alt="Car"
                            />
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>{addOnDetail?.product_name}</TableCell>
                      <TableCell>{addOnDetail?.description}</TableCell>

                      <TableCell>
                        {categoryDropDownDataProp?.find(
                          (x) => x.value == addOnDetail?.category_id
                        )?.text ?? "-"}
                      </TableCell>

                      <TableCell>
                        <Switch
                          theme={theme}
                          varient={"basic"}
                          label={addOnDetail.is_active ? "Enabled" : "Disabled"}
                          switchEnabled={addOnDetail.is_active}
                          onChange={(event) => {
                            updateDealerOemOption(
                              {
                                ...addOnDetail,
                                compatible_models:
                                  addOnDetail.compatible_models.map(
                                    (trim) => trim.id
                                  ),
                                is_active: event.target.checked,
                              },
                              {
                                onSuccess: () => {
                                  actions.setToast({
                                    toastMessage:
                                      ToastMessages.Record_Updated_Success,
                                    toastState: true,
                                  });
                                },
                                onError: (error: any) => {
                                  actions.setToast({
                                    toastMessage: error.error.toString(),
                                    toastState: true,
                                    variant: "error",
                                  });
                                },
                              }
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>{addOnDetail?.offered_by}</TableCell>
                      <TableCell>{addOnDetail?.part_no}</TableCell>
                      <TableCell>{addOnDetail.installation_mode}</TableCell>
                      <TableCell>
                        {addOnDetail?.compatible_models.map((trim, index) =>
                          index > 0
                            ? ", " + (trim.trim_name ?? trim.text)
                            : trim.trim_name ?? trim.text
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Box theme={theme} sx={{ mr: 4.3 }}>
                          {defaultCurrency}{" "}
                          {addOnDetail?.price.toLocaleString()}{" "}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box theme={theme} sx={{ mr: 4.3 }}>
                          {defaultCurrency}{" "}
                          {addOnDetail?.rv_adder.toLocaleString()}{" "}
                        </Box>
                      </TableCell>
                      <TableCell className="action-cell fixed-cell space-fixed">
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: "Edit",
                              optionValue: "edit",
                            },
                            {
                              optionText: "Delete",
                              optionValue: "delete",
                            },
                          ]}
                          handleOptionClick={(_event, _key, value) => {
                            switch (value) {
                              case "delete":
                                setRowId(addOnDetail.id);
                                setConfirmationPopupState(true);
                                break;
                              case "edit":
                                onEdit(addOnDetail);
                                break;
                            }
                          }}
                          render={(onMenuSelection) => (
                            <Button
                              defaultBtn
                              iconText={<Icon name="MoreIcon" />}
                              onClick={onMenuSelection}
                            ></Button>
                          )}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={OEMOptionsFilter ? OEMOptionsFilter.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={images}
        isLoading={false}
        overlayCheck={true}
        index={index}
        setIndex={setIndex}
        visible={visibility}
        onClose={handleClose}
      />

      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default OemOptionsTable;
