import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { NewAnnualMileage } from "components";
import { ActionOptions } from "Enums";
import { IAnnualMileage } from "Interface";
import AnnualMileageTable from "./annual-mileage-table";
import { IAnnualMileageProps } from "./annual-mileage.type";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

const AnnualMileage: FC<IAnnualMileageProps> = () => {
  const [annualMileageState, setAnnualMileageState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Annual Mileage"
  );
  const [state, dispatch] = useSetupsStore();
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IAnnualMileage>(undefined);

  useEffect(() => {
    if (annualMileageState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [annualMileageState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Annual Mileage");
        setActionData(value);
        setAnnualMileageState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IAnnualMileage) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Annual Mileage");
    setActionData(formData);
    setAnnualMileageState(true);
    dispatch(setOpenDrawer(true));
  };
  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <AnnualMileageTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Annual Mileage"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {annualMileageState && (
          <NewAnnualMileage
            openPopUp={annualMileageState}
            setPopUpState={setAnnualMileageState}
            title={actionTitle}
            actionType={actionType}
            annualMileageData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default AnnualMileage;
