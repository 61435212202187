import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Grid, Select } from "@ntpkunity/controls";
import { CustomGrid } from "@sharedComponents";

import { Controller } from "react-hook-form";
import { PurchaseOptionType, TaxAmortization } from "Enums";
import { IProgramEndOfTerm } from "./program-end-of-term.type";

const ProgramEndofTerm: FC<IProgramEndOfTerm> = ({ form, isDisabled }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = form;
  const [isTypeDisabled, setIsTypeDisabled] = useState<string>("");

  const purchase = watch("program_end_of_terms.purchase_option_applicable");
  useEffect(() => {
    setIsTypeDisabled(purchase);
  }, [purchase]);
  const theme = useTheme();
  return (
    <>
      <CustomGrid
        className="main-content-section"
        theme={theme}
        container
        item
        spacing={3}
      >
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_end_of_terms.purchase_option_applicable"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <Select
                id="PurchaseOptionApplicable"
                theme={theme}
                label={"Purchase Option Applicable"}
                placeholder={"Select"}
                items={[
                  { text: TaxAmortization.YES, value: TaxAmortization.YES },
                  { text: TaxAmortization.NO, value: TaxAmortization.NO },
                ]}
                value={value ?? ""}
                onChange={(e: any) => {
                  onChange(e);
                  setIsTypeDisabled(e.target.value);
                }}
                disabled={isDisabled}
                selectError={
                  errors?.program_end_of_terms?.purchase_option_applicable
                    ?.message
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_end_of_terms.purchase_option_type"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <Select
                id="PurchaseOptionType"
                theme={theme}
                label={"Purchase Option Type"}
                placeholder={"Select"}
                items={[
                  {
                    text: PurchaseOptionType.ONE_$_OUT,
                    value: PurchaseOptionType.ONE_$_OUT,
                  },
                  {
                    text: PurchaseOptionType.FMV,
                    value: PurchaseOptionType.FMV,
                  },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={!(isTypeDisabled == "Yes") || isDisabled}
                selectError={
                  errors?.program_end_of_terms?.purchase_option_type?.message
                }
              />
            )}
          />
        </Grid>
      </CustomGrid>
    </>
  );
};
export default ProgramEndofTerm;
