import { useTheme } from "@mui/material";
import { Input, MultiSelect, Select } from "@ntpkunity/controls";
import { FC} from "react";
import { Controller } from "react-hook-form";
import { validation } from "shared/helper/methods";
import { ValidationMessages } from "Enums";
export const AddNewLender: FC<{
  form: any;
  popUpOpenState: string;
}> = ({ form, popUpOpenState }) => {
  const {
    control,
    setValue,
    getValues,
    resetField,
    formState: { errors },
  } = form;
  const theme = useTheme();

  return (
    <>
      <Controller
        name="name"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.NAME_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"Lender Name"}
            type="text"
            {...field}
            error={errors?.name?.message}
          />
        )}
      />
      <Controller
        name="external_code"
        control={control}
        defaultValue={""}
        rules={{
          maxLength: {
            value: 5,
            message: ValidationMessages.EXTERNAL_CODE_MAX_LENGTH,
          },
          required: ValidationMessages.EXTERNAL_CODE_REQUIRED,
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            fullWidth
            placeholder={"Type here.."}
            label={"External Code"}
            type="text"
            {...field}
            error={errors?.external_code?.message}
          />
        )}
      />
      <Controller
        name="finance_type"
        control={control}
        rules={validation("Applicable Finance Type(s)", true)}
        defaultValue={["lease", "finance"]}
        render={({ field }) => (
          <MultiSelect
            id="financeType"
            name="finance_type"
            theme={theme}
            label={"Finance Type(s)"}
            items={[
              { text: "Lease", value: "lease" },
              { text: "Finance", value: "finance" },
            ]}
            {...field}
            value={field.value}
            selectError={errors?.finance_type?.message as string}
          />
        )}
      />
      <Controller
        name="program"
        control={control}
        defaultValue={"Internal"}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Program"}
            placeholder={"Select"}
            items={[{ text: "Internal", value: "Internal" }]}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="e_contracting"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"E-Contracting"}
            placeholder={"Select"}
            items={[
              { text: "Yes", value: true },
              { text: "No", value: false },
            ]}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="default_lender"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Default"}
            placeholder={"Select"}
            items={[
              { text: "Yes", value: true },
              { text: "No", value: false },
            ]}
            value={true}
            disabled={true}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Status"}
            placeholder={"Select"}
            items={[
              { text: "Enabled", value: true },
              { text: "Disabled", value: false },
            ]}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  );
};
