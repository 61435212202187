import { FC, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { Icon, Button, Box, PersistentDrawer } from "@ntpkunity/controls";
import {
  DrawerWrap,
  DataTableWrap,
  ConfirmationDialog,
} from "@sharedComponents";
import NewOemOption from "./oem-option-drawer";
import OemOptionsTable from "./oem-option-table";
import { useForm } from "react-hook-form";
import { UseQuery_getAllOptionCategorys } from "services/order-category.service";
import {
  IAddress,
  ISelectItemList,
  IOptionCategory,
  IOptionCategoryFilter,
  IImage,
} from "Interface";
import { useQueryClient } from "react-query";
import { ActionOptions, QueryKeys, DialogMessages } from "Enums";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { logout } from "@ntpkunity/controls-ums";
interface IFormInputs {
  id: number;
  offered_by: string;
  description: string;
  product_name: string;
  category_id: string;
  part_no: string;
  installation_mode: string;
  price: number;
  is_price_inclusive: boolean;
  rv_adder: number;
  image: string[];
  video_url: string;
  pdf_url: string;
  is_active: boolean;
  compatible_models: any[];
}
const OemOption: FC<{
  onPopupStateChange: (popUpState: any) => unknown;
}> = ({ onPopupStateChange }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [formDefaultData, setFormDefaultData] = useState<IFormInputs>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [popUpState, setPopUpState] = useState(false);
  const [compatibleModels, setcompatibleModels] = useState<any[]>([]);
  const [compatibleTrimModels, setCompatibleTrimModels] = useState<any[]>([]);
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [productId, setProductId] = useState(null);
  const [images, setImages] = useState<Array<IImage>>([]);
  const [state, dispatch] = useSetupsStore();

  onPopupStateChange(popUpState);
  const handleshow = () => {
    setFormDefaultData({} as IFormInputs);
    form.reset({} as IFormInputs);
    setPopUpState(true);
    setIsEditMode(false);
    setImages([]);
    setcompatibleModels(undefined);
    setProductId(null);
  };

  useEffect(() => {
    dispatch(setOpenDrawer(popUpState));
  }, [popUpState]);

  const onSubmit = (formValues: IFormInputs): void => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    } else {
      setPopUpState(true);
    }
    setFormDefaultData({} as IFormInputs);
    form.reset({} as IFormInputs);
    setIsEditMode(false);
    setImages([]);
    setcompatibleModels(undefined);
    setProductId(null);
  };
  const form: {
    handleSubmit;
    control;
    setValue;
    reset;
    getValues;
    resetField;
    watch;
    setError;
    clearErrors;
    formState: { errors; isDirty };
  } = useForm<IFormInputs>();
  // const form: {
  //   handleSubmit: any;
  //   reset: any;
  // } = useForm<IFormInputs>();

  UseQuery_getAllOptionCategorys();
  const queryClient = useQueryClient();
  const optionCategoryData: Array<IOptionCategory> = queryClient.getQueryData(
    QueryKeys.GET_ALL_OPTION_CATEGORIES
  );
  const [categoryDropDownData, setCategoryDropDownData] =
    useState<Array<ISelectItemList>>();

  useEffect(() => {
    queryClient.invalidateQueries(QueryKeys.GET_ALL_OPTION_CATEGORIES);
    setCategoryDropDownData(
      optionCategoryData?.map((currentOptionCategory) => ({
        value: currentOptionCategory.id,
        text: currentOptionCategory.description,
      }))
    );
  }, [optionCategoryData]);

  const defaultValues: IFormInputs = useMemo(() => {
    const updatedFormDefaultValues = formDefaultData;
    setCompatibleTrimModels(
      formDefaultData?.compatible_models?.map((trim) => trim.model_id)
    );
    if (updatedFormDefaultValues) {
      updatedFormDefaultValues.compatible_models = formDefaultData
        ? formDefaultData.compatible_models?.reduce<
            { text: string; value: any }[]
          >((previous, model: any) => {
            return [...previous, { text: model.trim_name, value: model.id }];
          }, [])
        : [];
      setFormDefaultData(updatedFormDefaultValues);
    }
    return formDefaultData;
  }, [formDefaultData]) as unknown as IFormInputs;
  const onEdit = (formData: IFormInputs) => {
    setFormDefaultData(formData);
    setIsEditMode(true);
    setPopUpState(true);
  };

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!popUpState) {
      setIsEditMode(false);
    }
  }, [popUpState]);
  const onSaveConfirm = (e: any) => {
    form.handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };

  usePrompt(
    "Leave screen?",
    form.formState.isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      form.reset(form.getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <DataTableWrap theme={theme} className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <OemOptionsTable
          categoryDropDownDataProp={categoryDropDownData}
          onEdit={(data) => onEdit(data)}
          isEditMode={isEditMode}
        ></OemOptionsTable>
        <Button
          theme={theme}
          secondary
          fullWidth
          id="AddNewOEMOption"
          text={"Add New OEM Option"}
          onClick={handleshow}
        ></Button>
        {popUpState && (
          <NewOemOption
            categoryDropDownDataProp={categoryDropDownData}
            form={form}
            openPopUp={popUpState}
            setPopUpState={setPopUpState}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            defaultFormValues={defaultValues}
            setcompatibleModels={setcompatibleModels}
            compatibleModels={compatibleModels}
            compatibleTrimModels={compatibleTrimModels}
            setCompatibleTrimModels={setCompatibleTrimModels}
            setImages={setImages}
            images={images}
          ></NewOemOption>
        )}
      </DataTableWrap>
    </>
  );
};

export default OemOption;
