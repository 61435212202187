import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Collapse, FormHelperText, ListItemIcon, styled } from "@mui/material";
import clsx from "clsx";
import { Checkbox, Icon } from "@ntpkunity/controls";
import { INestedMultiSelect } from "./INestedMultiSelect.type";
import ChevronDown from "../../assets/images/chevron-down";
import { useState } from "react";

const CustomizedSelectWrapper = styled(
  "div",
  {}
)<Partial<INestedMultiSelect>>(({ theme }) => ({
  "&.u-form-group": {
    marginBottom: 24,
    "&:last-of-type": {
      marginBottom: 0,
    },
    ".u-form-control": {
      height: 48,
      color: "rgba(0,0,0,0.87)",
      "&.u-select": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 16px",
        },
        svg: {
          position: "absolute",
          top: "auto",
          right: 12,
          cursor: "pointer",
          userSelect: "none",
          PointerEvent: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
      "&:hover": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-focused": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.MuiInputBase-adornedStart": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 0px",
        },
        fieldset: {
          legend: {
            maxWidth: 0,
          },
        },
        "&.Mui-focused": {
          fieldset: {
            legend: {
              maxWidth: "100%",
            },
          },
        },
        "~": {
          ".u-input-label": {
            transform: "translate(38px, 12px) scale(1)",
            "&.Mui-focused": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
            "&.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
          },
        },
      },
      "&.MuiInputBase-adornedEnd": {
        ".MuiInputBase-input": {
          padding: "12px 0px 12px 16px",
        },
      },
      "&.Mui-error": {
        fieldset: {
          borderWidth: 2,
          borderColor: theme.palette.error.main,
        },
        "~": {
          ".u-input-label": {
            color: theme.palette.error.main,
            "&.Mui-focused": {
              color: theme.palette.error.main,
            },
            "&.MuiFormLabel-filled": {
              color: theme.palette.error.main,
            },
          },
        },
      },
    },
    ".u-input-label": {
      color: "rgba(0,0,0,0.87)",
      lineHeight: theme.typography.body1.lineHeight,
      transform: "translate(14px, 12px) scale(1)",
      padding: "0 5px 0 0px",
      backgroundColor: theme.palette.common.white,
      "&.Mui-focused": {
        color: theme.palette.text.secondary,
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
      "&.MuiFormLabel-filled": {
        color: theme.palette.text.secondary,
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
    },
    ".u-help-text": {
      marginLeft: 15,
    },
    ".u-error-text": {
      color: theme.palette.error.main,
      marginLeft: 15,
    },
    ".text-capitalize": {
      textTransform: "capitalize",
    },
    ".text-uppercase": {
      textTransform: "uppercase",
    },
  },
}));

const MenuSx = {
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#EBECF2",
  boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
  padding: "0",
  color: "#515466",
  maxHeight: 250,
  ".MuiMenu-list": {
    padding: "8px",
  },
  "@supports (-moz-appearance:none)": {
    scrollbarWidth: "thin",
  },
  "&::-webkit-scrollbar": {
    width: 7,
    height: 7,
  },
  "&::-webkit-scrollbar-track": {
    padding: "0 1px",
    backgroundColor: "#f0f0f0",
  },
  "&::-webkit-scrollbar-thumb": {
    transition: "all 0.3s",
    backgroundColor: "#cdcdcd",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a6a6a6",
  },
  ".nested-item": {
    display: "flex",
    width: "96.8%",
    padding: "12px 24px 12px 16px",
    borderRadius: "8px",
    ".checkbox-item": {
      width: "100%",
      ".custom-checkbox-wrap": {
        ".u-custom-control-label": {
          margin: 0,
        },
      },
    },
    ".icon-item": {
      float: "right",
      cursor: "pointer",
      minWidth: "20px",
      position: "relative",
      right: "-8px",

      ".MuiButtonBase-root": {
        padding: 0,
        backgroundColor: "transparent",
        border: "0 solid transparent",
        borderRadius: 0,
        ".MuiTouchRipple-root": {
          display: "none",
        },
        "&.Mui-selected": {
          svg: {
            transform: "scaleY(-1)",
          },
        },
      },
    },
    "&:hover": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    ".toggle-btn": {
      transform: "rotate(0deg)",
      webkitTransform: "rotate(0deg)",
      "&.ch-down": {
        transform: "rotate(180deg)",
        webkitTransform: "rotate(180deg)",
      },
    },
  },
  ".MuiCollapse-wrapperInner": {
    ".u-dropdown-item": {
      padding: "0 10px",
      ".custom-checkbox-wrap": {
        width: "100%",
        padding: "12px 24px 12px 16px",
        borderRadius: "8px",
        ".u-custom-control-label": {
          margin: 0,
        },
        "&:hover": {
          backgroundColor: "#F8F8F8",
          color: "#12131A",
        },
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
};
const NestedMultiSelect: React.FC<INestedMultiSelect> = ({
  theme,
  onChange: onChangePorps,
  name,
  items = [],
  label,
  value = [],
  nestedValues,
  helperText = "",
  disabled = false,
  id = "select-simple",
  capitalizeLabel,
  uppercaseLabel,
  selectError,
}) => {
  const [selectedNestValues, setSelectedNestedValues] = React.useState<any[]>(
    value as any[]
  );
  const selectedValues = React.useRef<any[]>(value as any[]);

  React.useEffect(() => {
    setSelectedNestedValues(value as any[]);
    selectedValues.current = nestedValues ? nestedValues : [];
  }, [nestedValues]);

  const [states, setStates] = React.useState<Array<{ [key: number]: boolean }>>(
    items?.map((_option, index) => {
      return { [index]: false };
    }) || []
  );
  const [open, setOpen] = useState(false);
  const nestedItemCheckedChange = (e: any, key: any): void => {
    const index = items ? items.findIndex((item) => item.value === key) : -1;
    if (index > -1) {
      if (e.target.checked) {
        setSelectedNestedValues([...(selectedNestValues || []), key]);
        const filteredSubItems = items ? items[index].subItems : [];
        selectedValues.current = [
          ...selectedValues?.current,
          ...filteredSubItems?.filter(
            (subItem) =>
              selectedValues.current?.findIndex(
                (selectedValue) => selectedValue.value === subItem.value
              ) < 0
          ),
        ];
      } else {
        setSelectedNestedValues(
          selectedNestValues?.filter((item) => item !== key)
        );
        selectedValues.current = selectedValues.current.filter(
          (item) =>
            !(items
              ? items[index].subItems.some(
                  (filteredItem) =>
                    JSON.stringify(filteredItem) === JSON.stringify(item)
                )
              : [])
        );
      }
    }
    onChangePorps?.(e, selectedValues.current);
  };

  const onChange = (event: any, subItem: any): void => {
    if (
      selectedValues.current?.some(
        (item) => JSON.stringify(item) === JSON.stringify(subItem)
      )
    ) {
      selectedValues.current = selectedValues.current.filter(
        (item) => item.value !== subItem.value
      );
    } else {
      selectedValues.current = [...selectedValues.current, subItem];
    }
    onChangePorps?.(event, selectedValues.current);
  };
  return (
    <CustomizedSelectWrapper theme={theme} className="u-form-group">
      <FormControl fullWidth>
        <Select
          MenuProps={{
            PaperProps: {
              sx: MenuSx,
            },
          }}
          inputProps={{
            name: [name],
          }}
          disabled={disabled}
          className="u-form-control u-select"
          labelId="demo-simple-select-label"
          id={id}
          value={selectedValues.current}
          label={label}
          error={!!selectError}
          renderValue={(selected) => {
            if (
              items &&
              items.length > 0 &&
              selectedValues.current.length > 0
            ) {
              return selectedValues.current
                ?.map((item) => item.text)
                .join(", ");
            } else {
              return items
                .filter((item) => selected.includes(item.value))
                .map((item) => item.text)
                .join(", ");
            }
          }}
          sx={{ borderRadius: "8px" }}
          IconComponent={ChevronDown}
        >
          {items &&
            items.length > 0 &&
            items?.map((nestedItem, index) => {
              return (
                <div key={index}>
                  <div className="nested-item">
                    <div className="checkbox-item">
                      <Checkbox
                        theme={theme}
                        id={"nestedItem"}
                        checkBoxChecked={selectedNestValues?.includes(
                          nestedItem.value
                        )}
                        onChange={(e) =>
                          nestedItemCheckedChange(e, nestedItem.value)
                        }
                        label={nestedItem.text}
                      />
                    </div>
                    <ListItemIcon
                      className="icon-item"
                      onClick={(_e) => {
                        const newStates = [...states];
                        newStates[index] = { [index]: !states[index][index] };
                        setStates(newStates);
                      }}
                    >
                      <Icon
                        name="ChevronDown"
                        className={`toggle-btn ${
                          states[index][index] === true ? "ch-down" : ""
                        }`}
                      />
                    </ListItemIcon>
                  </div>
                  <Collapse
                    in={states[index][index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {nestedItem.subItems?.map((subItem) => (
                      <MenuItem
                        className="u-dropdown-item"
                        key={subItem.value}
                        value={subItem.value}
                      >
                        <Checkbox
                          theme={theme}
                          id={"subItem"}
                          checkBoxChecked={
                            selectedValues.current?.filter(
                              (item) => item.value === subItem.value
                            ).length > 0
                          }
                          onChange={(e) => onChange(e, subItem)}
                          label={subItem.text}
                        />
                      </MenuItem>
                    ))}
                  </Collapse>
                </div>
              );
            })}
        </Select>
        <InputLabel
          className={clsx({
            "u-input-label": true,
            "text-capitalize": capitalizeLabel,
            "text-uppercase": uppercaseLabel,
          })}
          id="demo-simple-select-label"
        >
          {label}
        </InputLabel>
        {selectError && (
          <FormHelperText className="u-error-text" id={id}>
            {selectError}
          </FormHelperText>
        )}
        <FormHelperText className="u-help-text">{helperText}</FormHelperText>
      </FormControl>
    </CustomizedSelectWrapper>
  );
};

export default NestedMultiSelect;
function setActiveClass(arg0: string) {
  throw new Error("Function not implemented.");
}
