import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import {
  Company,
  AssetSetup,
  Charts,
  Programs,
  ProgramList,
  InventoryManagement,
  DealerOptions,
  DealerConfigurations,
  FinancialInsurance,
  DealerManagement,
  OrderSetup,
  GeneralSetup,
  ManageUsers,
  UserProfile,
  Feedback,
  LenderProfileList,
  ProgramSetup,
} from "pages";
import { APP_ROUTES } from "shared/config/links";
import { indexPermissions } from "@ntpkunity/controls-ums/indexPermissions";
import { Validate } from "@ntpkunity/controls-ums";
import DefaultRoute from "../../pages/default-routing";

const ConfigurationRoutes: FC = () => {
  return (
    <Routes>
      <Route
        path={ APP_ROUTES.COMPANY_PROFILE}
        element={
            <DefaultRoute />
        }
      />
      <Route
        path={APP_ROUTES.Asset_Setups}
        element={
          <Validate
            permission={indexPermissions.ROUTING.ASSET_SETUPS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <AssetSetup />
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.General_Setups}
        element={
          <Validate
            permission={indexPermissions.ROUTING.GENERAL_SETUPS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <GeneralSetup />
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Order_Setups}
        element={
          <Validate
            permission={indexPermissions.ROUTING.ORDER_SETUPS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <OrderSetup />
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Charts}
        element={
          <Validate
            permission={indexPermissions.ROUTING.CHARTS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <Charts />
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Program}
        element={
          <Validate
            permission={indexPermissions.ROUTING.PROGRAM}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <Programs />
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Program + "/:id"}
        element={
          <Validate
            permission={indexPermissions.ROUTING.PROGRAM}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <Programs />
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Programs}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.PROGRAMS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ProgramList />
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Inventory_Management}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.INVENTORY_MANAGEMENT}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <InventoryManagement />
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Dealer_Options}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.DEALER_OPTIONS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <DealerOptions />
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Dealer_Configurations}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.DEALER_CONFIGURATIONS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <DealerConfigurations />
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Financial_Insurance}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.FINANCIAL_INSURANCE}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <FinancialInsurance />
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Dealer_Management}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.DEALERSHIP_MANAGEMENT}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <DealerManagement />
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Manage_Users}`}
        element={
          <Validate
            permission={indexPermissions.ROUTING.MANAGE_USERS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ManageUsers />
          </Validate>
        }
      />
      <Route
        path={APP_ROUTES.Program_Setups}
        element={
          <Validate
            permission={indexPermissions.ROUTING.ASSET_SETUPS}
            url={APP_ROUTES.COMPANY_PROFILE}
          >
            <ProgramSetup />
          </Validate>
        }
      />
      <Route
        path={`${APP_ROUTES.Lender_Profile_List}`}
        element={<LenderProfileList />}
      />
      <Route path={`${APP_ROUTES.User_Profile}`} element={<UserProfile />} />
      <Route path={`${APP_ROUTES.Feedback}`} element={<Feedback />} />
    </Routes>
  );
};

export default ConfigurationRoutes;
