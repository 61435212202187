import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  Icon,
  PersistentDrawer,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "@sharedComponents";
import { INewAssetMakePopUp } from "./AddNewAssetMake.type";
import { useStoreContext } from "Stores/SetupStore/Store";
import { Controller, useForm } from "react-hook-form";
import {
  useMutation_AddNewAssetMake,
  useMutation_UpdateAssetMake,
} from "services";
import { IAssetMake } from "Interface";
import { ActionOptions, ValidationMessages, DialogMessages } from "Enums";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";

const NewAssetMake: FC<INewAssetMakePopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  assetMakeData,
  enabledPin,
  ...props
}) => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    reset,
    setError,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IAssetMake>();
  const { mutate: AddAssetMake } = useMutation_AddNewAssetMake();
  const { mutate: EditAssetMake } = useMutation_UpdateAssetMake(
    getValues("id")
  );
  const navigate = useNavigate();
  const { actions } = useStoreContext();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", assetMakeData.id);
    }
    setValue("name", assetMakeData?.name);
    setValue("external_code", assetMakeData?.external_code);
    setValue("description", assetMakeData?.description);
    setValue("is_active", assetMakeData ? assetMakeData.is_active : true);
  }, [assetMakeData]);

  function onSubmit(data) {
    if (actionType === ActionOptions.ADD) {
      AddAssetMake(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Name should be unique"
          ) {
            setError("external_code", { message: "Code should be unique" });
            setError("name", { message: "Name should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("external_code", { message: "Code should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Name should be unique"
          ) {
            setError("name", { message: "Name should be unique" });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    } else if (actionType === ActionOptions.EDIT) {
      let updatedData = {
        ...data,
      };
      EditAssetMake(updatedData, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Name should be unique"
          ) {
            setError("external_code", { message: "Code should be unique" });
            setError("name", { message: "Name should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("external_code", { message: "Code should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Name should be unique"
          ) {
            setError("name", { message: "Name should be unique" });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        title={title}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="name"
              control={control}
              rules={{
                required: ValidationMessages.ASSET_MAKE_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  // {...field}
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Make"}
                  type="string"
                  id="name"
                  onChange={onChange}
                  value={value}
                  error={errors?.name?.message}
                ></Input>
              )}
            />
            <Controller
              name="external_code"
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.CODE_MAX_LENGTH,
                },
                required: ValidationMessages.CODE_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  fullWidth={true}
                  theme={theme}
                  placeholder={"Type here..."}
                  label={"Code"}
                  type="string"
                  id="external_code"
                  value={value}
                  onChange={onChange}
                  // onBlur={(e) => {
                  //   checkValue(e.target.value);
                  // }}
                  error={errors?.external_code?.message}
                ></Input>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Description (Optional)"}
                  id="description"
                  type="string"
                  value={value}
                  onChange={onChange}
                ></Input>
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                ></Select>
              )}
            />
          </>
        }
        customFooter={
          <Button
            primary
            text={"Save"}
            fullWidth={true}
            theme={theme}
            onClick={handleSubmit(onSubmit)}
          ></Button>
        }
      />
    </>
  );
};

export default NewAssetMake;
