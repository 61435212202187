import { FC } from "react";
import { useTheme, Stack, styled, Divider } from "@mui/material";
import {
  Button,
  Switch,
  Box,
  Icon,
  Grid,
  Typography,
  Menu,
} from "@ntpkunity/controls";
import { IDealerAgreement } from "Interface";

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const IndividualizedAgreementList: FC<{
  individualizedAgreements?: IDealerAgreement[];
  handleDefaultValueChange: (id: number, isDefault: boolean) => void;
  handleshowEdit: (values) => void;
  handleDeleteAgreementPopup: (id: number) => void;
}> = ({
  handleDefaultValueChange,
  handleshowEdit,
  handleDeleteAgreementPopup,
  individualizedAgreements,
}) => {
  const theme = useTheme();

  return (
    <>
      <Box theme={theme} className="individualized-agreement-list" mb={3}>
        {(individualizedAgreements || [])?.map((item, index) => {
          const defaultValue = item.is_default;
          return (
            <Box
              theme={theme}
              className="agreement"
              key={`${index}-${Date.now()}`}
            >
              <Grid theme={theme} container columnSpacing={2}>
                <Grid theme={theme} item xl={11} lg={10} md={9} sm={8} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    children={item?.agreement_clause}
                  />
                </Grid>
                <Grid theme={theme} item xl={1} lg={2} md={3} sm={4} xs={12}>
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    className="stack"
                    sx={{ mr: 2 }}
                  >
                    <Item theme={theme}>
                      <Switch
                        theme={theme}
                        varient={"basic"}
                        label={"Default"}
                        labelPlacement="start"
                        switchEnabled={defaultValue}
                        onChange={() => {
                          handleDefaultValueChange(item.id, !item.is_default);
                        }}
                      />
                    </Item>
                    <Item theme={theme}>
                      <Menu
                        theme={theme}
                        disablePortal
                        options={[
                          {
                            optionText: (
                              <>
                                <Icon className="menu-icon" name="EditIcon" />
                                {"Edit"}
                              </>
                            ),
                            optionValue: "edit",
                          },
                          {
                            optionText: (
                              <>
                                <Icon className="menu-icon" name="DeleteIcon" />
                                {"Delete"}
                              </>
                            ),
                            optionValue: "delete",
                          },
                        ]}
                        handleOptionClick={(_event, _key, value) => {
                          if (value === "edit") handleshowEdit(item);
                          else handleDeleteAgreementPopup(item.id);
                        }}
                        render={(onMenuSelection) => (
                          <Button
                            defaultBtn
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      />
                    </Item>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default IndividualizedAgreementList;
