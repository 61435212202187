import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Button, Box } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { AddNewStipulation } from "components";
import { ActionOptions } from "Enums";
import { IStipulation } from "Interface";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import StipulationTable from "./stipulation-table";

const Stipulation = () => {
  const [StipulationState, setStipulationState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Stipulation");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<IStipulation>(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (StipulationState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [StipulationState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Stipulation");
        setActionData(value);
        setStipulationState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IStipulation) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Stipulation");
    setActionData(formData);
    setStipulationState(true);
    dispatch(setOpenDrawer(true));
  };

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <StipulationTable onEdit={(data) => onEdit(data)} />
        <Button
          id="addNewStipulation"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Stipulation"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {StipulationState && (
          <AddNewStipulation
            openPopUp={StipulationState}
            setPopUpState={setStipulationState}
            title={actionTitle}
            actionType={actionType}
            stipulationData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default Stipulation;
