import { ToggleButton, Theme, Link } from "@mui/material";

import {
  Button,
  Checkbox,
  Grid,
  IAddressLayoutProps,
  Select,
  Input,
  Typography,
  Box,
  Icon,
  Autocomplete,
  Dialog,
  IBoxProps,
  Tooltip,
} from "@ntpkunity/controls";
import { AutoSearch } from "@sharedComponents";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IAddress, ICountry } from "Interface";
import { styled } from "@mui/material/styles";
import { LocalStorage } from "shared/helper/browserStorage";
import { ACCESS_TOKEN_KEY } from "shared/config/constants";
import { EP_GET_VERIFY_ADDRESS } from "shared/config/endpoints";

const AddressLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.address-layout-wrap": {
    ".u-form-group": {
      ".u-form-control": {
        "&.MuiInputBase-adornedEnd": {
          ".styled-tooltip": {
            height: 24,
            ".MuiTooltip-popper": {
              '&[data-popper-placement*="bottom"]': {
                ".MuiTooltip-arrow": {
                  transform: "none !important",
                  marginLeft: 7,
                },
              },
              '&[data-popper-placement*="left"]': {
                marginTop: "-7px !important",
              },
            },
          },
        },
      },
    },
    ".tooltip-content": {
      ".fw-bold": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
}));

const VerifyAddressUi = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  ".MuiFormGroup-root": {
    ".MuiFormControlLabel-root": {
      marginRight: "24px !important",

      "&:last-of-type": {
        marginRight: 0,
      },
    },
  },
}));
interface SelectItem {
  value: any;
  text: string;
}
interface sameAsAddress {
  required: boolean; // if false, this component will not render
  requiredType: number; // 1 for checkbox, 2 for drop down
  sameAsLable: string; // Lable for CheckBox [SameAs Address]
  saValues: SelectItem[]; // Options for drop down
}
interface IAddressProps {
  handleCountryChange?: any;
  theme: Theme;
  states: any;
  countries?: any;
  isContactRequired: boolean;
  addressVerificationRequired: boolean;
  value: any;
  sameAsAddress?: sameAsAddress;
  validationProps: any;
  verifyAddressURL?: string;
  onChange: (e: any) => unknown;
  setIsAddressDirty?: any;
  showCountryDropdown?: boolean;
  makeFieldsDisabled?: boolean | undefined;
  xApiKey?: string;
  disableCounty?: boolean;
  hideAddressButton?: boolean;
  hasAddressTooltip?: boolean;
  dealer_code?: string;
  tooltipPlacement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
    providerByCompany?:boolean
}
const CustomAddressLayout: React.FC<IAddressProps> = ({
  theme,
  states = [],
  isContactRequired = false,
  addressVerificationRequired,
  value,
  validationProps,
  sameAsAddress,
  verifyAddressURL,
  onChange,
  countries,
  setIsAddressDirty,
  handleCountryChange,
  disableCounty,
  hasAddressTooltip,
  tooltipPlacement = "left",
  dealer_code,
  providerByCompany,
}) => {
  const {
    control,
    reset,
    getValues,
    formState: { isDirty },
  } = useForm({ defaultValues: value });
  const [open, setOpen] = React.useState(false);
  const [openCountry, setOpenCountry] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState<ICountry>(
    countries?.[0]
  );
  const selectedValues = React.useRef<any>({
    id: undefined,
    address_line_1: "",
    address_line_2: "",
    city: "",
    state_id: "",
    state_name: "",
    zip_code: "",
    county: "",
    contact_number: "",
    sa_address_id: "",
    field5: "",
    field6: "",
    country_id: { label: "", id: "" },
    sa_address_boolean: false,
    verified: false,
    location: { lat: "", lng: "" },
  });
  const [stNoShortName, setStreetNoShortName] = useState("");
  const [rtNoShortName, setRouteNoShortName] = useState("");
  const [localityShortName, setLocalityShortName] = useState("");

  const [adminAreaL2LongName, setAdminAreaL2LongName] = useState("");
  const [adminAreaL1LongName, setAdminAreaL1LongName] = useState("");
  const [postalCodeLongName, setPostalCodeLongName] = useState("");
  const [addressState, setAddressState] = useState(false);
  const [showCounty, setShowCounty] = useState(false);
  let legalAddressReturned = false;
  function checkIfAddressNull(obj: any): boolean {
    if (obj && obj.hasOwnProperty("country_id")) {
      const { country_id, ...objWithoutCountryId } = selectedValues.current;
      return Object.keys(objWithoutCountryId).length === 0;
    } else {
      return true;
    }
  }
  useEffect(() => {
    selectedValues.current = null;
    selectedValues.current = value;
    if (checkIfAddressNull(selectedValues.current)) {
      reset({
        id: undefined,
        address_line_1: "",
        address_line_2: "",
        city: "",
        state_id: "",
        state_name: "",
        zip_code: "",
        county: "",
        contact_number: "",
        sa_address_id: "",
        field5: "",
        field6: "",
        country_id: { label: "", id: "" },
        sa_address_boolean: false,
        verified: false,
      });
    } else {
      reset(selectedValues.current);
    }
    if (value?.country_id) {
      setSelectedCountry(
        countries?.find((x) => x?.id == value?.country_id?.id)
      );
    }
    setShowCounty(selectedCountry?.address_template?.field4_visible);
  }, [value]);
  useEffect(() => {
    if (isDirty) {
      setIsAddressDirty && setIsAddressDirty(true);
      setAddressState(true);
      selectedValues.current = {
        ...selectedValues.current,
        verified: false,
      };
    }
  }, [isDirty]);
  function handleChangeAddrL1(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      address_line_1: e.target.value,
      verified: false,
    };
    onChange(selectedValues.current);
  }
  function handleChangeStateName(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      state_name: e.target.value,
      verified: false,
    };
    onChange(selectedValues.current);
  }
  function handleChangeAddrL2(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      address_line_2: e.target.value,
      verified: false,
    };
    onChange(selectedValues.current);
  }

  function handleChangeCity(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      city: e.target.value,
      verified: false,
    };
    onChange(selectedValues.current);
  }

  const handleChangeState = (event: { target: { value: any; name: any } }) => {
    selectedValues.current = {
      ...selectedValues.current,
      state_id: event.target.value,
      state_name: states?.find((x) => x.id == event.target.value)?.name,
      verified: false,
    };
    onChange(selectedValues.current);
  };

  function handleChangeZipCode(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      zip_code: e.target.value,
      verified: false,
    };
    onChange(selectedValues.current);
  }

  function handleChangeContact(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      contact_number: e.target.value,
      verified: false,
    };
    onChange(selectedValues.current);
  }

  function handleChangeCounty(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      county: e.target.value,
      verified: false,
    };
    onChange(selectedValues.current);
  }

  const handleChangeSAAddress = (event: { target: { value: any } }) => {
    selectedValues.current = {
      ...selectedValues.current,
      sa_address_id: event.target.value,
    };
    onChange(selectedValues.current);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectedValues.current = {
      ...selectedValues.current,
      sa_address_boolean: event.target.checked,
    };
    onChange(selectedValues.current);
  };
  const handleChangeCountry = (value: any): any => {
    selectedValues.current = {
      ...selectedValues.current,
      country_id: { label: value?.name, id: value?.id },
      address_line_1: "",
      address_line_2: "",
      city: "",
      state_id: null,
      state_name: "",
      zip_code: "",
      county: "",
      field5: "",
      field6: "",
    };
    onChange(selectedValues.current);
  };
  function handleChangeField5(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      field5: e.target.value,
    };
    onChange(selectedValues.current);
  }
  function handleChangeField6(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    selectedValues.current = {
      ...selectedValues.current,
      field6: e.target.value,
    };
    onChange(selectedValues.current);
  }
  const confirmDialog = (): void => {
    let verifiedAddress = {
      address_line_1: stNoShortName + " " + rtNoShortName,
      city: localityShortName,
      state_name: adminAreaL2LongName,
      zip_code: postalCodeLongName,
      county: adminAreaL1LongName,
    };

    selectedValues.current = {
      ...selectedValues.current,
      address_line_1: verifiedAddress?.address_line_1,
    };
    selectedValues.current = {
      ...selectedValues.current,
      address_line_2: "",
    };

    selectedValues.current = {
      ...selectedValues.current,
      city: verifiedAddress?.city,
    };
    onChange(selectedValues.current);
    selectedValues.current = {
      ...selectedValues.current,
      state_id: states?.find((x) => x.name == adminAreaL2LongName)?.id,
    };
    selectedValues.current = {
      ...selectedValues.current,
      state_name: verifiedAddress?.state_name,
    };
    onChange(selectedValues.current);

    selectedValues.current = {
      ...selectedValues.current,
      zip_code: verifiedAddress?.zip_code,
    };
    onChange(selectedValues.current);

    selectedValues.current = {
      ...selectedValues.current,
      county: verifiedAddress?.county,
    };
    onChange(selectedValues.current);
    if (
      verifiedAddress?.address_line_1 === "" ||
      verifiedAddress?.city === "" ||
      verifiedAddress?.state_name === "" ||
      verifiedAddress?.zip_code === ""
    ) {
      selectedValues.current = {
        ...selectedValues.current,
        verified: false,
      };
    } else {
      selectedValues.current = {
        ...selectedValues.current,
        verified: true,
      };
      setAddressState(false);
    }
    onChange(selectedValues.current);
    reset(selectedValues.current);
    setOpen(false);
    // setIsAddressDirty && setIsAddressDirty(false);
  };
  const cancelDialog = (): void => {
    setOpen(false);
    // setAddressState(false);
  };

  const verifyAddress: () => void = () => {
    let address: IAddress = null;
    const _localStorage = new LocalStorage();

    const token = _localStorage.getItemByKey(ACCESS_TOKEN_KEY);

    const request = new XMLHttpRequest();
    const queryAddress =
      String(
        `${process.env.INTEGRATION_API_GATEWAY}/${EP_GET_VERIFY_ADDRESS}/`
      ) +
      String(selectedValues.current.address_line_1) +
      " " +
      String(selectedValues.current.address_line_2) +
      ", " +
      String(selectedValues.current.city) +
      ", " +
      selectedValues.current.state_name;
    request.open("GET", queryAddress);
    if(!providerByCompany){
      request.setRequestHeader("x-dealer-code", dealer_code);
    }
    request.setRequestHeader("Authorization", `Bearer ${token}`);
    request.send();
    request.onload = () => {
      if (request.status === 200) {
        const returnedAddress: any = JSON.parse(request.response);
        if (returnedAddress.results.length > 0) {
          let formattedAddressComponents =
            returnedAddress.results[0].formatted_address.split(",");
          setStreetNoShortName(formattedAddressComponents[0]);
          selectedValues.current = {
            ...selectedValues.current,
            location: returnedAddress?.results[0]?.geometry?.location,
          };
          for (
            let ii = 0;
            ii < returnedAddress.results[0].address_components.length;
            ii++
          ) {
            let types =
              returnedAddress.results[0].address_components[ii].types.join(",");
            switch (types) {
              case "locality,political":
                setLocalityShortName(
                  returnedAddress.results[0].address_components[ii].long_name
                );
                break;
              case "administrative_area_level_1,political":
                setAdminAreaL2LongName(
                  returnedAddress.results[0].address_components[ii].long_name
                );
                break;
              case "administrative_area_level_2,political":
                if (
                  // countries.find((x) => x.id == address?.country_id?.id
                  // )?.address_template?.field4_visible
                  showCounty
                )
                  setAdminAreaL1LongName(
                    returnedAddress.results[0].address_components[ii].long_name
                  );
                break;
              case "postal_code":
                setPostalCodeLongName(
                  returnedAddress.results[0].address_components[ii]
                    .long_name !== ""
                    ? returnedAddress.results[0].address_components[ii]
                        .long_name
                    : undefined
                );
                break;
            }
          }
        }
        legalAddressReturned = true;
        setOpen(true);
      }
    };
  };
  return (
    <AddressLayoutWrap theme={theme} className="address-layout-wrap">
      <form>
        <Grid theme={theme} container spacing={3}>
          <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
            <Grid theme={theme} item xs={12}>
              <AutoSearch
                theme={theme}
                className="auto-search with-address-layout"
              >
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ mr: 1 }}
                  theme={theme}
                >
                  Select Country:
                </Typography>
                <ToggleButton
                  value="check"
                  selected={openCountry}
                  onChange={() => {
                    setOpenCountry(!openCountry);
                  }}
                >
                  {selectedCountry?.name}
                  <Icon name="ChevronDown" />
                </ToggleButton>
                {openCountry && (
                  <>
                    <Link
                      className="overlay"
                      onClick={() => setOpenCountry(!openCountry)}
                    />
                    <Box theme={theme} className="list-wrap">
                      <Controller
                        name="country_id"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            theme={theme}
                            defaultValue={
                              selectedCountry
                                ? {
                                    label: selectedCountry?.name,
                                    id: selectedCountry?.id,
                                  }
                                : { label: "", id: "" }
                            }
                            disablePortal={true}
                            capitalizeLabel={true}
                            open={openCountry}
                            onChange={(_e: any, value: any) => {
                              field.onChange(value);
                              handleCountryChange && handleCountryChange();
                              setSelectedCountry(
                                countries.find((x) => x.id == value.id)
                              );
                              setOpenCountry(!openCountry);
                              handleChangeCountry(
                                countries.find((x) => x.id == value.id)
                              );
                            }}
                            items={
                              countries
                                ? countries.map((x) => {
                                    return { label: x.name, id: x.id };
                                  })
                                : []
                            }
                            // value={field.value || null}
                            value={
                              selectedCountry
                                ? {
                                    label: selectedCountry?.name,
                                    id: selectedCountry?.id,
                                  }
                                : null
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.id}>
                                <span>{option.label}</span>
                              </li>
                            )}
                          />
                        )}
                      />
                    </Box>
                  </>
                )}
              </AutoSearch>
            </Grid>
            {sameAsAddress &&
            sameAsAddress.required &&
            sameAsAddress.requiredType == 1 ? (
              <Checkbox
                label={"Same As " + sameAsAddress.sameAsLable}
                defaultChecked={false}
                onChange={handleChangeCheckBox}
              >
                {" "}
              </Checkbox>
            ) : (
              <></>
            )}
            {sameAsAddress &&
            sameAsAddress.required &&
            sameAsAddress.requiredType == 2 ? (
              <Select
                label={"Same As "}
                theme={theme}
                items={sameAsAddress.saValues}
                sxProps={{ m: 2, width: 100 }}
                onChange={handleChangeSAAddress}
              ></Select>
            ) : (
              <></>
            )}
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              name="address_line_1"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Address Line 1"
                  fullWidth
                  value={field.value?.trimStart()}
                  type={"text"}
                  startAdornment={""}
                  endAdornment={
                    <>
                      {hasAddressTooltip ? (
                        <>
                          <Tooltip
                            placement={tooltipPlacement}
                            title={
                              <Box theme={theme} className="tooltip-content">
                                <Typography
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={"block"}
                                >
                                  <span className="fw-bold">
                                    Street Address:
                                  </span>{" "}
                                  38-30 211th St.
                                </Typography>
                                <Typography
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={"block"}
                                >
                                  <span className="fw-bold">PO Box:</span> PO
                                  Box 450
                                </Typography>
                                <Typography
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={"block"}
                                >
                                  <span className="fw-bold">Rural Route:</span>{" "}
                                  RR 21 Box 450
                                </Typography>
                              </Box>
                            }
                            theme={theme}
                          >
                            <Icon name="InfoIcon" />
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  }
                  onBlur={handleChangeAddrL1}
                  theme={theme}
                  error={validationProps?.address_line_1?.message}
                />
              )}
            />
          </Grid>

          <Grid theme={theme} item xs={12}>
            <Controller
              name="address_line_2"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Address Line 2 (Optional)"
                  fullWidth={true}
                  value={field.value?.trimStart()}
                  type={"text"}
                  startAdornment={""}
                  endAdornment={
                    <>
                      {hasAddressTooltip ? (
                        <>
                          <Tooltip
                            placement={tooltipPlacement}
                            title={
                              <Box theme={theme} className="tooltip-content">
                                <Typography
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={"block"}
                                >
                                  <span className="fw-bold">Apartment #:</span>{" "}
                                  Apt. #2
                                </Typography>
                                <Typography
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={"block"}
                                >
                                  <span className="fw-bold">Suite #:</span> Ste.
                                  #450
                                </Typography>
                              </Box>
                            }
                            theme={theme}
                          >
                            <Icon name="InfoIcon" />
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  }
                  onBlur={handleChangeAddrL2}
                  theme={theme}
                />
              )}
            />
          </Grid>
          {(selectedCountry?.address_template == null ||
            selectedCountry?.address_template?.field1_visible) && (
            <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
              <Controller
                name="city"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={
                      selectedCountry?.address_template?.field1_label
                        ? selectedCountry?.address_template?.field1_label
                        : "City"
                    }
                    fullWidth={true}
                    value={field.value?.trimStart()}
                    onBlur={handleChangeCity}
                    theme={theme}
                    error={
                      validationProps?.city?.message
                        ? (selectedCountry?.address_template?.field1_label
                            ? selectedCountry?.address_template?.field1_label
                            : "City") + " is required"
                        : ""
                    }
                    type={"text"}
                  />
                )}
              />
            </Grid>
          )}
          {selectedCountry?.address_template?.field2_visible && (
            <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
              <Controller
                name="state_id"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={selectedCountry?.address_template?.field2_label}
                    fullWidth
                    disablePortal
                    theme={theme}
                    items={states
                      ?.filter((x) => x.country_id == selectedCountry?.id)
                      ?.map((state: any) => {
                        return {
                          text: state.name,
                          value: state.id,
                        };
                      })}
                    sxProps={{ m: 2, width: 100 }}
                    onChange={(e) => {
                      handleChangeState(e);
                      field.onChange(e);
                    }}
                    value={field.value ?? ""}
                    selectError={
                      validationProps?.state_id?.message
                        ? selectedCountry?.address_template?.field2_label +
                          " is required"
                        : ""
                    }
                  ></Select>
                )}
              />
            </Grid>
          )}
          {(selectedCountry?.address_template == null ||
            selectedCountry?.address_template?.field3_visible) && (
            <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
              <Controller
                name="zip_code"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={
                      selectedCountry?.address_template?.field3_label
                        ? selectedCountry?.address_template?.field3_label
                        : "Postal Code"
                    }
                    fullWidth
                    value={field.value}
                    type={"text"}
                    startAdornment={""}
                    endAdornment={""}
                    onBlur={handleChangeZipCode}
                    theme={theme}
                    error={
                      validationProps?.zip_code?.message
                        ? (selectedCountry?.address_template?.field3_label
                            ? selectedCountry?.address_template?.field3_label
                            : "Postal Code") + " is required"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          )}
          {selectedCountry?.address_template?.field4_visible && (
            <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
              <Controller
                name="county"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={selectedCountry?.address_template?.field4_label}
                    fullWidth
                    value={field.value?.trimStart()}
                    type={"text"}
                    startAdornment={""}
                    endAdornment={""}
                    disabled={disableCounty}
                    onBlur={handleChangeCounty}
                    theme={theme}
                    error={
                      validationProps?.county?.message
                        ? selectedCountry?.address_template?.field4_label +
                          " is required"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          )}
          {selectedCountry?.address_template == null && (
            <Grid theme={theme} item xs={3}>
              <Controller
                name={"state_name"}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    theme={theme}
                    fullWidth={true}
                    label={"State/Province/Region"}
                    type="text"
                    onBlur={handleChangeStateName}
                    value={field.value}
                  ></Input>
                )}
              />
            </Grid>
          )}
          {selectedCountry?.address_template?.field5_visible && (
            <Grid theme={theme} item xs={3}>
              <Controller
                name={"field5"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    theme={theme}
                    fullWidth={true}
                    label={selectedCountry?.address_template?.field5_label}
                    type="text"
                    onChange={onChange}
                    onBlur={handleChangeField5}
                    value={value}
                  ></Input>
                )}
              />
            </Grid>
          )}
          {selectedCountry?.address_template?.field6_visible && (
            <Grid theme={theme} item xs={3}>
              <Controller
                name={"field6"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    theme={theme}
                    fullWidth={true}
                    label={selectedCountry?.address_template?.field6_label}
                    type="text"
                    onChange={onChange}
                    onBlur={handleChangeField6}
                    value={value}
                  ></Input>
                )}
              />
            </Grid>
          )}
          {isContactRequired ? (
            <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
              <Input
                label="Contact Number"
                fullWidth
                value={selectedValues?.current?.contact_number}
                type={""}
                startAdornment={""}
                endAdornment={""}
                inputMask="000-000-0000"
                onBlur={handleChangeContact}
                masking
                id={""}
                theme={theme}
                dynamic={false}
                error={validationProps?.contact_number?.message}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid theme={theme} item xs={12}>
            {addressState && addressVerificationRequired ? (
              <Button
                theme={theme}
                secondary
                text="Verify Address"
                onClick={verifyAddress}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Dialog
            open={open}
            size="sm"
            title={"Verify Address"}
            theme={theme}
            variant={undefined}
            onCloseDialog={cancelDialog}
            disablePortal
            customFooter={
              <>
                <Grid container rowSpacing={3} columnSpacing={3} theme={theme}>
                  <Grid item xs={3} sm={3} theme={theme}>
                    <Button
                      text="Cancel"
                      theme={theme}
                      secondary
                      onClick={cancelDialog}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9} theme={theme}>
                    <Button
                      theme={theme}
                      primary
                      fullWidth
                      text="Confirm"
                      onClick={confirmDialog}
                    />
                  </Grid>
                </Grid>
              </>
            }
            children={
              <VerifyAddressUi theme={theme}>
                <Grid container rowSpacing={3} columnSpacing={3} theme={theme}>
                  <Grid item xs={12} sm={12} theme={theme}>
                    <Box theme={theme}>
                      <Typography theme={theme} variant="caption" component="p">
                        Address Line 1
                      </Typography>
                      <Typography theme={theme} variant="body1" component="h3">
                        {stNoShortName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} theme={theme}>
                    <Box theme={theme}>
                      <Typography theme={theme} variant="caption" component="p">
                        Address Line 2
                      </Typography>
                      <Typography theme={theme} variant="body1" component="h3">
                        {""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} theme={theme}>
                    <Box theme={theme}>
                      <Typography theme={theme} variant="caption" component="p">
                        City
                      </Typography>
                      <Typography theme={theme} variant="body1" component="h3">
                        {localityShortName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} theme={theme}>
                    <Box theme={theme}>
                      <Typography theme={theme} variant="caption" component="p">
                        State
                      </Typography>
                      <Typography theme={theme} variant="body1" component="h3">
                        {adminAreaL2LongName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} theme={theme}>
                    <Box theme={theme}>
                      <Typography theme={theme} variant="caption" component="p">
                        Zip Code
                      </Typography>
                      <Typography theme={theme} variant="body1" component="h3">
                        {postalCodeLongName}
                      </Typography>
                    </Box>
                  </Grid>
                  {showCounty && (
                    <Grid item xs={12} sm={6} theme={theme}>
                      <Box theme={theme}>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component="p"
                        >
                          County
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="body1"
                          component="h3"
                        >
                          {adminAreaL1LongName}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </VerifyAddressUi>
            }
          ></Dialog>
        </Grid>
      </form>
    </AddressLayoutWrap>
  );
};

export default CustomAddressLayout;
