import { FC, useEffect, useRef, useState } from "react";
import {
  useTheme,
  Button as MuiButton,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  DataTableWrap,
  TheadHiddenScroll,
  Typography,
  FileUploadTable,
} from "@sharedComponents";
import { styled } from "@mui/system";
import {
  Box,
  Button,
  DataTable,
  Dialog,
  Icon,
  CircleLoader,
  Grid,
  Tooltip,
} from "@ntpkunity/controls";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { FireTruck } from "@mui/icons-material";
import { mapQueryStatusFilter } from "react-query/types/core/utils";

const Loader = styled(
  Box,
  {}
)<any>(({ theme }) => ({
  ".loader-wrap": {
    ".loader": {
      top: "68% !important",
    },
  },
}));

const AssetCataloguePopup: FC<{
  openPopup: boolean;
  setopenAssetDialog: any;
  assetData: any;
  setAssetData: any;
}> = ({ openPopup, setopenAssetDialog, assetData, setAssetData }) => {
  const theme = useTheme();
  const handleCloseDialog = () => {
    setopenAssetDialog(false);
    setAssetData(null);
  };
  const record_count: any = assetData?.at(0);
  const asset_records: any = assetData?.at(1);

  const Data: FC = () => {
    return (
      <>
        {" "}
        {assetData ? (
          <FileUploadTable>
            <Box
              theme={theme}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box theme={theme} className="counter-box" sx={{ mr: 1, mb: 3 }}>
                <Box theme={theme} className="counter-text" sx={{ mr: 2 }}>
                  Total Record(s)
                </Box>
                <Box theme={theme} className="counter-num">
                  {record_count.total_records}
                </Box>
              </Box>
              <Box theme={theme} className="counter-box" sx={{ mr: 1, mb: 3 }}>
                <Box theme={theme} className="counter-text" sx={{ mr: 2 }}>
                  Duplicate Record(s)
                </Box>
                <Box theme={theme} className="counter-num">
                  {record_count.existing_records}
                </Box>
              </Box>
              <Box theme={theme} className="counter-box" sx={{ mr: 1, mb: 3 }}>
                <Box theme={theme} className="counter-text" sx={{ mr: 2 }}>
                  Uploaded Record(s)
                </Box>
                <Box theme={theme} className="counter-num">
                  {record_count.inserted_records}
                </Box>
              </Box>
              <Box theme={theme} className="counter-box" sx={{ mr: 1, mb: 3 }}>
                <Box theme={theme} className="counter-text" sx={{ mr: 2 }}>
                  Errors
                </Box>
                <Box theme={theme} className="counter-num">
                  {record_count.error_records}
                </Box>
              </Box>
            </Box>
            <DataTable
              theme={theme}
              variant={"basic"}
              theadChildren={
                <>
                  <TableRow>
                    <TableCell className="action-cell head"></TableCell>
                    <TableCell className="head">Make Name</TableCell>
                    <TableCell className="head">Make Code</TableCell>
                    <TableCell className="head">Make Description</TableCell>
                    <TableCell className="head">Make Status</TableCell>
                    <TableCell className="head">Model Name</TableCell>
                    <TableCell className="head">Model Code</TableCell>
                    <TableCell className="head">Model Description</TableCell>
                    <TableCell className="head">Model Status</TableCell>
                    <TableCell className="head">Trim Name</TableCell>
                    <TableCell className="head">Trim Code </TableCell>
                    <TableCell className="head">Trim Description</TableCell>
                    <TableCell className="head">Trim Status</TableCell>
                    <TableCell className="head">Trim Image</TableCell>
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {asset_records.map((asset: any, index: number) => {
                    return (
                      <TableRow key={index} className="child-tr">
                        <TableCell className="action-cell head">
                          {asset.line_number}
                        </TableCell>
                        {asset.make_name.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.make_name
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.make_name.split("; ")[0] == "nan"
                                        ? " "
                                        : asset.make_name.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.make_name}</TableCell>
                        )}
                        {asset.make_code.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.make_code
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.make_code.split("; ")[0] == "nan"
                                        ? " "
                                        : asset.make_code.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.make_code}</TableCell>
                        )}
                        {asset.make_description.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.make_description
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.make_description.split("; ")[0] ==
                                      "nan"
                                        ? " "
                                        : asset.make_description.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.make_description}</TableCell>
                        )}
                        {asset.is_make_active.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.is_make_active
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.is_make_active.split("; ")[0] ==
                                      "nan"
                                        ? " "
                                        : asset.is_make_active.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.is_make_active}</TableCell>
                        )}
                        {asset.model_name.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.model_name
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.model_name.split("; ")[0] == "nan"
                                        ? " "
                                        : asset.model_name.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.model_name}</TableCell>
                        )}
                        {asset.model_code.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.model_code
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {" "}
                                      {asset.model_code.split("; ")[0] == "nan"
                                        ? " "
                                        : asset.model_code.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.model_code}</TableCell>
                        )}
                        {asset.model_description.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.model_description
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.model_description.split("; ")[0] ==
                                      "nan"
                                        ? " "
                                        : asset.model_description.split(
                                            "; "
                                          )[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.model_description}</TableCell>
                        )}
                        {asset.is_model_active.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.is_model_active
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.is_model_active.split("; ")[0] ==
                                      "nan"
                                        ? " "
                                        : asset.is_model_active.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.is_model_active}</TableCell>
                        )}
                        {asset.trim_name.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.trim_name
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {" "}
                                      {asset.trim_name.split("; ")[0] == "nan"
                                        ? " "
                                        : asset.trim_name.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.trim_name}</TableCell>
                        )}
                        {asset.trim_code.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.trim_code
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.trim_code.split("; ")[0] == "nan"
                                        ? " "
                                        : asset.trim_code.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.trim_code}</TableCell>
                        )}
                        {asset.trim_description.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.trim_description
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.trim_description.split("; ")[0] ==
                                      "nan"
                                        ? " "
                                        : asset.trim_description.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.trim_description}</TableCell>
                        )}
                        {asset.is_trim_active.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.is_trim_active
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {asset.is_trim_active.split("; ")[0] ==
                                      "nan"
                                        ? " "
                                        : asset.is_trim_active.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.is_trim_active}</TableCell>
                        )}
                        {asset.trim_image_url.includes("; ") ? (
                          <TableCell className="error">
                            <Box theme={theme} className="red-box">
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  theme={theme}
                                  title={asset.trim_image_url
                                    .split("; ")
                                    .slice(1)
                                    .map((item, index) => item + "\n")}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Icon className="icon" name="InfoIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                    >
                                      {" "}
                                      {asset.trim_image_url.split("; ")[0]}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{asset.trim_image_url}</TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </>
              }
            ></DataTable>
          </FileUploadTable>
        ) : (
          <Loader>
            <CircleLoader theme={theme} />
          </Loader>
        )}
      </>
    );
  };

  return (
    <>
      <Dialog
        variant={undefined}
        size="xl"
        title="Review And Finalize"
        open={openPopup}
        onCloseDialog={handleCloseDialog}
        disablePortal
        customFooter={
          <>
            {/* <FileUploadTable>
              <Typography variant="body2" component="p" sx={{ mb: 4 }}>
              Your file has been successfully uploaded. The entries shown in the above table have some errors.
              Please fix them and re-upload.
              </Typography>
            </FileUploadTable> */}
          </>
        }
        theme={theme}
        children={
          <>
            <Data />
          </>
        }
      />
    </>
  );
};
export default AssetCataloguePopup;
