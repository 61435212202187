import { FC, useState } from "react";
import { useTheme, Link } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Menu, ImageViewer } from "@ntpkunity/controls";
import {
  useMutation_DeleteAssetTrim,
  useMutation_UpdateAssetTrim,
} from "services";
import {
  Checkbox,
  Box,
  Button,
  ConfirmationDialog,
  Switch,
} from "@sharedComponents";
import { IAssetTrim, IImage } from "Interface";
import { ActionOptions, DialogMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";

interface AssetTrimProps {
  assetTrim: IAssetTrim;
  showCheckBox?: boolean;
  onEditAssetTrim?: (data: IAssetTrim) => unknown;
  handleImage?: (data: IImage[]) => unknown;
}

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const AssetTrimTable: FC<AssetTrimProps> = ({
  assetTrim,
  showCheckBox,
  onEditAssetTrim,
  handleImage,
}) => {
  const { mutate: deleteTrim } = useMutation_DeleteAssetTrim();
  const { mutate: UpdateModel } = useMutation_UpdateAssetTrim(undefined);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [actionData, setActionData] = useState<IAssetTrim>(undefined);
  const { actions } = useStoreContext();

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEditAssetTrim(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteTrim(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  const handleEnableChange = (data: IAssetTrim, event) => {
    let updatedData = {
      id: data.id,
      is_active: event?.target?.checked,
      trim_name: data.trim_name,
      trim_code: data.trim_code,
      trim_description: data.trim_description,
      model_id: data.model_id,
    };
    UpdateModel(updatedData, {
      onError: (error) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    });
  };

  const theme = useTheme();

  return (
    <>
      <TableRow className="child-tr">
        {showCheckBox ? (
          <TableCell className="checkbox-cell fixed-cell">
            <Checkbox label="" />
          </TableCell>
        ) : null}
        <TableCell className="indent-cell third-level-cell">
          <Box theme={theme} className="group-title">
            {assetTrim.trim_name}
          </Box>
        </TableCell>
        <TableCell className="img-cell">
          {assetTrim?.trim_image?.length > 0 && (
            <Link
              onClick={() => {
                handleImage(assetTrim.trim_image);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                className="prd-img"
                alt={assetTrim.trim_name}
                src={assetTrim.trim_image?.[0]?.location ?? ""}
              />
            </Link>
          )}
        </TableCell>
        <TableCell>{assetTrim.trim_description}</TableCell>
        <TableCell>{assetTrim.trim_code}</TableCell>
        <TableCell>
          <Switch
            id={"trimStatus" + assetTrim.trim_name}
            theme={theme}
            varient={"basic"}
            label={assetTrim.is_active ? "Enabled" : "Disabled"}
            switchEnabled={assetTrim.is_active}
            onChange={(event) => handleEnableChange(assetTrim, event)}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: assetTrim,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                id={"trimEllipses" + assetTrim.trim_name}
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default AssetTrimTable;
