import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Input, Button, ConfirmationDialog } from "@sharedComponents";
import { INewAssetModelPopUp } from "./AddNewAssetModel.type";
import { IAssetMake, IAssetModel, ISelectItemList } from "Interface";
import { Controller, useForm } from "react-hook-form";
import {
  useMutation_AddNewAssetModel,
  UseQuery_GetAllAssetMakes,
  useMutation_UpdateAssetModel,
} from "services";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { Select, PersistentDrawer, Icon } from "@ntpkunity/controls";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { useStoreContext } from "Stores/SetupStore/Store";
import { logout } from "@ntpkunity/controls-ums";
const NewAssetModel: FC<INewAssetModelPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  assetModelData,
  enabledPin,
  ...props
}) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IAssetModel>();
  const { mutate: AddAssetModel } = useMutation_AddNewAssetModel();
  const { mutate: EditAssetModel } = useMutation_UpdateAssetModel(
    getValues("id")
  );
  const { actions } = useStoreContext();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [assetMakeId, setAssetMakeId] = useState(null);

  useEffect(() => {
    if (actionType !== ActionOptions.ADD && assetModelData) {
      setValue("id", assetModelData.id);
      setValue("make_id", assetModelData.make_id);
      setAssetMakeId(assetModelData?.make_id);
    } else if (actionType === ActionOptions.ADD) {
      setValue("make_id", null);
    }
    setValue("model_name", assetModelData?.model_name);
    setValue("model_code", assetModelData?.model_code);
    setValue("description", assetModelData?.description);
    setValue("is_active", assetModelData ? assetModelData.is_active : true);
  }, [assetModelData, openPopUp]);

  UseQuery_GetAllAssetMakes();
  const queryClient = useQueryClient();
  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const [assetmakes, setAssetmakes] = useState<Array<ISelectItemList>>(
    assetMakes?.map((make) => ({
      value: make.id,
      text: make.name,
    }))
  );

  useEffect(() => {
    setAssetmakes(
      assetMakes?.map((make) => ({
        value: make.id,
        text: make.name,
      }))
    );
  }, [assetMakes, assetMakeId]);

  const onChangeAssetMake = (event) => {
    setAssetMakeId(event.target.value);
    setValue("make_id", event.target.value);
  };

  function onSubmit(data) {
    if (
      actionType === ActionOptions.ADD ||
      actionType === ActionOptions.ADD_MODEL
    ) {
      AddAssetModel(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error: any) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Name should be unique"
          ) {
            setError("model_code", { message: "Code should be unique" });
            setError("model_name", { message: "Name should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("model_code", { message: "Code should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Name should be unique"
          ) {
            setError("model_name", { message: "Name should be unique" });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    } else if (actionType === ActionOptions.EDIT) {
      let updatedData = {
        ...data,
      };
      EditAssetModel(updatedData, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Name should be unique"
          ) {
            setError("model_code", { message: "Code should be unique" });
            setError("model_name", { message: "Name should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("model_code", { message: "Code should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Name should be unique"
          ) {
            setError("model_name", { message: "Name should be unique" });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      setPopUpState(false);
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        title={title}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="make_id"
              control={control}
              rules={{
                required: ValidationMessages.ASSET_MAKE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="make"
                  testid="makeTest"
                  theme={theme}
                  label={"Make"}
                  placeholder={"Select"}
                  items={assetmakes}
                  value={value ?? ""}
                  onChange={(e) => {
                    onChange(e);
                    onChangeAssetMake;
                  }}
                  selectError={errors?.make_id?.message}
                  sxProps={""}
                ></Select>
              )}
            />
            <Controller
              name="model_name"
              control={control}
              rules={{
                required: ValidationMessages.MODEL_NAME_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="model"
                  testid="modelTest"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Model"}
                  type="string"
                  onChange={onChange}
                  value={value}
                  error={errors?.model_name?.message}
                ></Input>
              )}
            />
            <Controller
              name="model_code"
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.CODE_MAX_LENGTH,
                },
                required: ValidationMessages.CODE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="modelCode"
                  testid="modelCodeTest"
                  fullWidth={true}
                  theme={theme}
                  placeholder={"Type here..."}
                  label={"Code"}
                  type="string"
                  onChange={onChange}
                  value={value}
                  error={errors?.model_code?.message}
                ></Input>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="addNewDescription"
                  testid="descriptionTest"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Description (Optional)"}
                  type="string"
                  onChange={onChange}
                  value={value}
                ></Input>
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="addNewStatus"
                  testid="statusTest"
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                  sxProps={undefined}
                ></Select>
              )}
            />
          </>
        }
        customFooter={
          <Button
            primary
            text={"Save"}
            fullWidth={true}
            style={{ paddingRight: "82px", paddingLeft: "82px" }}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default NewAssetModel;
