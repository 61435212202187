export const APP_ROUTES = {
  COMPANY_PROFILE: "/configuration" /* pages/vehicle-selection.tsx */,
  // CUSTOMER_DETAILS: "/pos/customer-details" /* pages/customer-details.tsx */,
  // QUOTATION: "/pos/quotation" /* pages/quotation.tsx */,
  // ORDER_SUMMARY: "/pos/order-summary" /* pages/order-summary.tsx */,
  Asset_Setups: "/configuration/asset-setups",
  Charts: "/configuration/charts",
  Program: "/configuration/program",
  Programs: "/configuration/program-list",
  Inventory_Management: "/configuration/inventory-management",
  Dealer_Options: "/configuration/dealer-options",
  Dealer_Configurations: "/configuration/dealer-configurations",
  Dealer_Management: "/configuration/dealer-management",
  Financial_Insurance: "/configuration/financial-insurance",
  General_Setups: "/configuration/general-setups",
  Program_Setups: "/configuration/program-setups",
  Order_Setups: "/configuration/order-setups",
  Manage_Users: "/configuration/user-management",
  User_Profile: "/configuration/user-profile",
  Change_Password: "/configuration/change-password",
  log_out: " /market-place/signup",
  Feedback: "/configuration/submit-feedback",
  Lender_Profile_List: "/configuration/lender-profile-list",
};
