import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material";
import { Select, IBoxProps } from "@ntpkunity/controls";
import { Grid, Box, TextBox } from "@sharedComponents";
import { styled } from "@mui/material/styles";

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.tab-wrap": {
    width: "100%",
    display: "inline-block",

    hr: {
      opacity: 0.4,
    },

    ".title-icon": {
      width: "100%",
      display: "inline-block",
      "span, Icon": {
        float: "left",
      },
      span: {
        marginRight: 10,
      },
    },
    ".buttons-row": {
      display: "inline-block",
      textAlign: "center",
      width: "100%",

      Button: {
        margin: "0 20px 20px 0",

        "&:last-of-type": {
          marginRight: 0,
        },
      },
    },
  },
}));

const DealerTradeIn: FC<{
  form: any;
  partner;
  kbbState: boolean;
}> = ({ form, partner, kbbState }) => {
  const theme = useTheme();

  const [applyValidation, setApplyValidation] = useState<boolean>(false);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form;
  function setError(): boolean {
    let status = false;
    if (
      getValues("tradein.partner_name") == null &&
      getValues("tradein.percentage") == null
    ) {
      status = false;
    } else {
      status = true;
    }
    return status;
  }
  useEffect(() => {
    // if (!watch("tradein.partner_name")) {
    //   setValue("tradein.percentage", "");
    // }
    if (getValues("tradein.partner_name") === null && !kbbState) {
      setValue("tradein.partner_name", partner?.[0]?.value);
      setIsDisabled(true);
    } else {
      setValue("tradein.partner_name", partner?.[0]?.value);
    }
  }, [watch("tradein.partner_name")]);

  return (
    <TabWrap className="tab-wrap">
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mb: 2.7 }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="tradein.partner_name"
            control={control}
            defaultValue={partner?.[0]?.value}
            rules={{
              required: {
                value: applyValidation,
                message: "Provider is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                theme={theme}
                label={"Provider"}
                items={partner}
                value={value ?? ""}
                onChange={(e) => {
                  onChange(e);
                  setApplyValidation(setError);
                }}
                sxProps={""}
                selectError={errors?.tradein?.partner_name?.message}
                disabled={!kbbState}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="tradein.percentage"
            control={control}
            render={({ field }) => (
              <TextBox
                theme={theme}
                label="Percentage"
                type="text"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                endAdornment={
                  <>
                    <span className="MuiInputAdornment-root">%</span>
                  </>
                }
                masking
                scale={2}
                numeric
                adornmentOnFocus={true}
                error={!kbbState ? "" : errors?.tradein?.percentage?.message}
                disabled={!kbbState}
              />
            )}
          />
        </Grid>
      </Grid>
    </TabWrap>
  );
};
export default DealerTradeIn;
