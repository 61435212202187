import { FC, useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Menu, Tooltip } from "@ntpkunity/controls";
import {
  useMutation_DeleteRvChart,
  UseQuery_GetAllAssetMakes,
  UseQuery_GetAllAssetModels,
  UseQuery_GetAllAssetTrims,
  UseQuery_getAllMileage,
} from "services";
import { useTheme } from "@mui/material";
import { Box, Button, ConfirmationDialog, Typography } from "@sharedComponents";
import {
  IRvChart,
  IRvChartCriteria,
  ICharts,
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  ITerms,
  ISelectItemList,
  IAssetCondition,
  IAnnualMileage,
} from "Interface";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { useQueryClient } from "react-query";
import { convertArray } from "shared/helper/methods";
interface RvCriteriaProps {
  criteria: IRvChart;
  chart: ICharts;
  onEditCriteria?: (data) => unknown;
  AssetCondition?: IAssetCondition[];
  terms?: ITerms[];
  annualMileages?: IAnnualMileage[];
}

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const RvCriteriaTable: FC<RvCriteriaProps> = ({
  criteria,
  chart,
  onEditCriteria,
  AssetCondition,
  terms,
  annualMileages,
}) => {
  UseQuery_GetAllAssetMakes();
  UseQuery_GetAllAssetModels();
  UseQuery_GetAllAssetTrims();
  const theme = useTheme();
  const [termsArr, setTermsArr] = useState([]);
  const [assetConditionArr, setAssetConditionArr] = useState([]);
  const [mileageArr, setMileageArr] = useState([]);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const { mutate: DeleteCriteria } = useMutation_DeleteRvChart();
  const [actionData, setActionData] = useState<IRvChartCriteria>(undefined);
  const queryClient = useQueryClient();
  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );

  useEffect(() => {
    const term = JSON.stringify(criteria?.terms);
    const termData = JSON.parse(term);
    setTermsArr(termData);

    const asset = JSON.stringify(criteria?.asset_condition);
    const assetData = JSON.parse(asset);
    setAssetConditionArr(assetData);

    const mileage = JSON.stringify(criteria.annual_usage);
    const mileageData = JSON.parse(mileage);
    setMileageArr(mileageData);
  }, [criteria]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        const data = {
          chart: chart,
          criteria: value,
          chartId: 0,
        };
        onEditCriteria(data);
        return;
      case ActionOptions.DELETE:
        setActionData(value.id);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    DeleteCriteria(actionData, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };
  return (
    <>
      <TableRow className="child-tr level-two">
        <TableCell>
          <Box theme={theme} className="group-title"></Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria.asset_make_name} {criteria.asset_model_name}{" "}
                  {criteria.asset_trim_name}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography variant="body2" sx={{ ml: 0.7 }}>
                  {
                    <>
                      {criteria.asset_make_name} {criteria.asset_model_name}{" "}
                      {criteria.asset_trim_name}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.asset_condition
                    ? criteria?.asset_condition.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography variant="body2" sx={{ ml: 0.7 }}>
                  {
                    <>
                      {criteria?.asset_condition
                        ? criteria?.asset_condition.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.asset_usages
                    ? criteria?.asset_usages.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography variant="body2" sx={{ ml: 0.7 }}>
                  {
                    <>
                      {criteria?.asset_usages
                        ? criteria?.asset_usages.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria &&
                    criteria?.is_rv_guaranteed
                      ?.map((rv) => (rv == "True" ? "Yes" : "No"))
                      .join(", ")}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography variant="body2" sx={{ ml: 0.7 }}>
                  {
                    <>
                      {criteria &&
                        criteria?.is_rv_guaranteed
                          ?.map((rv) => (rv == "True" ? "Yes" : "No"))
                          .join(", ")}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={<>{criteria?.terms ? criteria?.terms.join(", ") : "-"}</>}
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography variant="body2" sx={{ ml: 0.7 }}>
                  {<>{criteria?.terms ? criteria?.terms.join(", ") : "-"}</>}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.annual_usage
                    ? convertArray(criteria?.annual_usage).join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                <Typography variant="body2" sx={{ ml: 0.7 }}>
                  {
                    <>
                      {criteria?.annual_usage
                        ? convertArray(criteria?.annual_usage).join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.purchase_option
                    ? criteria?.purchase_option.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {criteria?.purchase_option
                        ? criteria?.purchase_option.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.financed_amount
                    ? convertArray(criteria?.financed_amount).join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {criteria?.financed_amount
                        ? convertArray(criteria?.financed_amount).join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>{criteria.rv_value.toFixed(2) + "%"}</TableCell>
        <TableCell>
          {criteria.minimum_rv && criteria.maximum_rv
            ? criteria.minimum_rv.toFixed(2) +
              "% - " +
              criteria.maximum_rv.toFixed(2) +
              "%"
            : "-"}
        </TableCell>
        <TableCell></TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: criteria,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default RvCriteriaTable;
