import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { AddressType, QueryKeys, ValidationMessages } from "Enums";
import { Select, Input, Grid, MultiSelect } from "@ntpkunity/controls";
import { CurrencyInputWrap, MultiSelectWrap } from "@sharedComponents";
import { Controller } from "react-hook-form";
import { IGeneralProps } from "./general.type";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { ISelectItemList, ICompanyProfile, ICountry } from "Interface";
import { useQueryClient } from "react-query";

const CompanyProfile: FC<IGeneralProps> = ({
  control,
  errors,
  setValue,
  getValues,
  setCompanyFieldState,
  setDefaultCurrency,
  defaultCurrency,
  selectedDefaultCurrencyId,
  setSelectedDefaultCurrencyId,
  setCompanyCountries,
  resetMailingAddress,
}) => {
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();

  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const allCountries: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_COUNTRIES
  );

  const alldefaultRadius: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_RADIUS
  );
  const { currencies } = state;
  const [Currencies, setCurrencies] = useState<Array<ISelectItemList>>();
  const [countries, setCountries] = useState<Array<ISelectItemList>>();
  const [defaultRadius, setDefaultRadius] = useState<ISelectItemList[]>([]);
  const [mileageUnit, setMileageUnit] = useState("");
  const [selectedDefaultCurrency, setSelectedDefaultCurrency] =
    useState<string>("");
  const setDefaultDropdown = (values) => {
    let arr: Array<ISelectItemList> = [];
    if (values) {
      for (let i = 0; i < values?.length; i++) {
        let foundCurrency = currencies?.find((x) => x.id == values[i]);
        if (foundCurrency)
          arr.push({ text: foundCurrency.name, value: foundCurrency.id });
      }
      if (arr?.length > 0) {
        setValue("CompanyProfile.default_currency", arr?.[0]?.value, {
          shouldValidate: true,
        });
        setSelectedDefaultCurrencyId(Number(arr[0]?.value));
      } else if (arr?.length === 0 && values?.length === 0) {
        setValue("CompanyProfile.default_currency", "", {
          shouldValidate: true,
        });
      }
      setDefaultCurrency(arr);
    }
  };

  useEffect(() => {
    if (companyProfile) {
      localStorage.setItem("company_id", companyProfile?.id);
    }
  }, [companyProfile]);
  useEffect(() => {
    setSelectedDefaultCurrency(
      currencies?.filter(
        (currency) => currency.id === Number(selectedDefaultCurrencyId)
      )?.length > 0
        ? currencies?.filter(
            (currency) => currency.id === Number(selectedDefaultCurrencyId)
          )[0].symbol
        : ""
    );
  }, [selectedDefaultCurrencyId, companyProfile]);

  useEffect(() => {
    setCurrencies(
      currencies?.map((currency) => ({
        value: currency.id,
        text: currency.name,
      }))
    );
    let CompanyProfile: ICompanyProfile = companyProfile;
    setDefaultDropdown(
      CompanyProfile?.preferred_currency_ids?.split(",").map(Number)
    );
  }, [currencies, companyProfile]);
  useEffect(() => {
    setCountries(
      allCountries?.map((country) => ({
        value: country.id,
        text: country.name,
      }))
    );
  }, [allCountries]);

  const storedMileageUnit = getValues("CompanyProfile.mileage_unit");
  useEffect(() => {
    if (storedMileageUnit) {
      setMileageUnit(storedMileageUnit);
      setDefaultRadius(
        alldefaultRadius?.map((radius: any) => ({
          text: `${radius.radius_value} ${storedMileageUnit}`,
          value: radius.radius_value,
        }))
      );
    }
  }, [alldefaultRadius, storedMileageUnit]);

  const handleMileageUnitChange = (newValue) => {
    setValue("CompanyProfile.mileage_unit", newValue);
    setMileageUnit(newValue);
    setDefaultRadius(
      alldefaultRadius?.map((radius: any) => ({
        text: `${radius.radius_value} ${newValue || mileageUnit}`,
        value: radius.radius_value,
      }))
    );
  };

  return (
    <Grid theme={theme} container spacing={3}>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_Name">
        <Controller
          name="CompanyProfile.name"
          control={control}
          rules={{
            required: ValidationMessages.NAME_REQUIRED,
            pattern: {
              value: /^[A-Za-z0-9 ]+$/,
              message: ValidationMessages.COMPANY_NAME_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              theme={theme}
              id="company_name"
              inputClass="string"
              label="Company Name"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              placeholder="Type here..."
              type="string"
              value={value}
              wrapperClass="string"
              fullWidth={true}
              error={errors?.CompanyProfile?.name?.message}
            />
          )}
        />
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_TradingName"
      >
        <Controller
          name="CompanyProfile.trading_name"
          control={control}
          rules={{
            required: ValidationMessages.TRADING_NAME_REQUIRED,
            pattern: {
              value: /^[A-Za-z0-9 ]+$/,
              message: ValidationMessages.TRADING_NAME_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="company_trading_name"
              inputClass="string"
              label="Company Trading Name"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              placeholder="Type here..."
              theme={theme}
              type="string"
              wrapperClass="string"
              value={value}
              fullWidth={true}
              error={errors?.CompanyProfile?.trading_name?.message}
            />
          )}
        />
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CompanyWebsite"
      >
        <Controller
          name="CompanyProfile.url"
          control={control}
          rules={{
            required: ValidationMessages.URL_REQUIRED,
            pattern: {
              value:
                /(http:\/\/|https:\/\/|www\.|[a-zA-Z])+([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,6}/,
              message: ValidationMessages.INVALID_URL,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="company_url"
              inputClass="string"
              label="Company's Website"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              placeholder="www.sample.com"
              theme={theme}
              type="string"
              wrapperClass="string"
              value={value}
              error={errors?.CompanyProfile?.url?.message}
              fullWidth={true}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_email">
        <Controller
          name="CompanyProfile.email"
          control={control}
          rules={{
            required: ValidationMessages.EMAIL_REQUIRED,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: ValidationMessages.INVALID_EMAIL,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="company_email"
              inputClass="string"
              label="Email"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              theme={theme}
              placeholder={"sample@sample.com"}
              type="string"
              wrapperClass="string"
              value={value}
              error={errors?.CompanyProfile?.email?.message}
              fullWidth={true}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_taxId">
        <Controller
          name="CompanyProfile.tax_id"
          control={control}
          rules={{
            required: ValidationMessages.TAX_ID_REQUIRED,
            minLength: {
              value: 9,
              message: ValidationMessages.INVALID_TAX_ID_FORMAT,
            },
            maxLength: {
              value: 9,
              message: ValidationMessages.INVALID_TAX_ID_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Input
                id="company_tax_id"
                label="Tax ID"
                onChange={(e) => {
                  onChange(e);
                  if (e !== companyProfile?.tax_id) setCompanyFieldState(true);
                }}
                placeholder="00-0000000"
                theme={theme}
                type="text"
                value={value}
                error={errors?.CompanyProfile?.tax_id?.message}
                format="##-#######"
                masking={true}
                fullWidth={true}
                maskNumeric={true}
              />
            </>
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_mileageUnits"
      >
        <Controller
          name="CompanyProfile.mileage_unit"
          control={control}
          rules={{
            required: ValidationMessages.MILEAGE_UNIT
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              disablePortal
              id="company_mileage_unit"
              label={"Mileage Unit"}
              placeholder={"Select"}
              items={[
                { text: "Miles", value: "Miles" },
                { text: "Kilometers", value: "Kilometers" },
              ]}
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
                handleMileageUnitChange(e.target.value as string);
                setCompanyFieldState(true);
              }}
              selectError={errors?.CompanyProfile?.mileage_unit?.message}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_default_search_radius"
      >
        <Controller
          name="CompanyProfile.default_radius"
          control={control}
          rules={{
            required: ValidationMessages.DEFAULT_RADIUS,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              id={"company_default_search_radius"}
              theme={theme}
              disablePortal
              label={"Default Search Radius"}
              placeholder={"Select"}
              items={defaultRadius}
              value={value ?? ""}
              onChange={(e: any) => {
                onChange(e.target.value);
                setCompanyFieldState(true);
              }}
              selectError={errors?.CompanyProfile?.default_radius?.message}
              sxProps={{ m: 0, width: "100%" }}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_initials">
        <Controller
          name="CompanyProfile.initials"
          control={control}
          rules={{
            required: ValidationMessages.INITIALS_REQUIRED,
            maxLength: {
              value: 2,
              message: ValidationMessages.INVALID_INITIAL,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="company_initials"
              inputClass="string"
              label="Initials"
              onChange={(e) => {
                onChange(
                  e
                    .replace(/[^a-z]/gi, "")
                    .toUpperCase()
                    .slice(0, 2)
                );
                setCompanyFieldState(true);
              }}
              placeholder="Type here..."
              theme={theme}
              type="text"
              wrapperClass="string"
              fullWidth={true}
              format="##"
              masking={false}
              maskNumeric={false}
              value={value}
              error={errors?.CompanyProfile?.initials?.message}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_preferred_currrency"
      >
        <MultiSelectWrap>
          <Controller
            name="CompanyProfile.countries"
            control={control}
            rules={{
              required: ValidationMessages.COUNTRY_REQUIRED,
            }}
            render={({ field: { value, onChange } }) => (
              <MultiSelect
                // multi-select-all={true}
                id={"company_countries"}
                theme={theme}
                label={"Country"}
                placeholder={""}
                items={countries}
                value={value || []}
                onChange={(e: any) => {
                  let arr: Array<ICountry> = [];
                  if (e.target.value) {
                    for (let i = 0; i < e.target.value?.length; i++) {
                      arr.push(
                        allCountries?.find((x) => x.id == e.target.value[i])
                      );
                    }
                  }
                  setCompanyCountries(arr);
                  setCompanyFieldState(true);
                  onChange(e);
                  const country_id = companyProfile.addresses?.find(
                    (x) => x.address_type == AddressType.MAILING
                  ).country_id;
                  if (!arr.find((x) => x.id == country_id)) {
                    resetMailingAddress();
                  }
                }}
                selectError={errors?.CompanyProfile?.countries?.message}
                sxProps={{ m: 0, width: "100%" }}
              />
            )}
          />
        </MultiSelectWrap>
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_preferred_currrency"
      >
        <MultiSelectWrap>
          <Controller
            name="CompanyProfile.preferred_currency_ids"
            control={control}
            rules={{
              required: ValidationMessages.PREFERRED_CURRENCY_REQUIRED,
            }}
            render={({ field: { value, onChange } }) => (
              <MultiSelect
                // multi-select-all={true}
                id={"company_preferred_currrency"}
                theme={theme}
                label={"Preferred Currency"}
                placeholder={""}
                items={Currencies}
                value={value || []}
                onChange={(e: any) => {
                  setDefaultDropdown(e.target.value);
                  setCompanyFieldState(true);
                  onChange(e);
                }}
                selectError={
                  errors?.CompanyProfile?.preferred_currency_ids?.message
                }
                sxProps={{ m: 0, width: "100%" }}
              />
            )}
          />
        </MultiSelectWrap>
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_default_currency"
      >
        <Controller
          name="CompanyProfile.default_currency"
          control={control}
          rules={{
            required: ValidationMessages.DEFAULT_CURRENCY_REQUIRED,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              id={"company_default_currency"}
              theme={theme}
              disablePortal
              label={"Default Currency"}
              placeholder={"Select"}
              value={defaultCurrency?.length == 0 ? "" : value ?? ""}
              selectError={errors?.CompanyProfile?.default_currency?.message}
              items={defaultCurrency}
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
                setSelectedDefaultCurrencyId(Number(e.target.value));
              }}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CompanySlug"
      >
        <Controller
          name="CompanyProfile.slug"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="company_slug"
              inputClass="string"
              label="Slug"
              onChange={(e) => {
                onChange(e);
                setCompanyFieldState(true);
              }}
              placeholder="sample-slug"
              theme={theme}
              type="string"
              wrapperClass="string"
              value={value}
              fullWidth={true}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CompanyMinOdometer"
      >
        <Controller
          name="CompanyProfile.min_odometer_prior_use"
          control={control}
         
          render={({ field: { onChange, value } }) => {
            value= parseInt(value?.toString().split('.')[0]);
            return (
            <Input
            
              formControlClass="string"
              id="min_odometer_prior_use"
              inputClass="string"
              label="Min. Odometer For Prior Use"
              onChange={(e) => {
                e= parseInt(e.toString().split('.')[0]);
                onChange(e);
                setCompanyFieldState(true);
              }}
              placeholder="00"
              theme={theme}
              type="number"
              wrapperClass="string"
              value={value}
              fullWidth={true}
              maskNumeric={true}
              maskDecimalScale={0}
            />
          )}
        }
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CompanyCreditDecDays"
      >
        <Controller
          name="CompanyProfile.credit_decision_expiry_in_days"
          control={control}
          rules={{
            required: ValidationMessages.CREDIT_DECISION_REQUIRED,
            pattern: {
              value: /^[0-9]+$/,
              message: ValidationMessages.CREDIT_DECISION_REQUIRED,
            },
          }}
          render={({ field: { onChange, value } }) =>{
            value= (value?.toString()?.trim().length > 0)?parseInt(value?.toString().split('.')[0]):value;
            return (
              <Input
                formControlClass="string"
                id="credit_decision_expiry_in_days"
                inputClass="string"
                label="Credit Decision Expiry Days"
                onChange={(e) => {
                  e= (e?.toString()?.trim().length > 0)?parseInt(e?.toString().split('.')[0]):e;
                  onChange(e);
                  setCompanyFieldState(true);
                }}
                placeholder="90"
                theme={theme}
                type="number"
                wrapperClass="string"
                value={value}
                fullWidth={true}
                error={errors?.CompanyProfile?.credit_decision_expiry_in_days?.message}
              />
            )
          } 

        }
        />
      </Grid>
    </Grid>
  );
};

export default CompanyProfile;
