import { FC, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button, PersistentDrawer } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";

import DealerFeeDrawer from "./dealer-fee-drawer";
import DealerFeesTable from "./dealer-fee-table";
import { useForm } from "react-hook-form";

interface IFormInputs {
  id?: number;
  fee_name: string;
  states: number[];
  applicable_finance_type: string[];
  default_amount: number;
  taxable: boolean;
  is_active: boolean;
  dealer_code: string;
}
const DealerFees: FC<{
  form: any;
  onPopupStateChange: (popUpState: any) => unknown;
  dealer_code: string;
}> = ({ onPopupStateChange, dealer_code }) => {
  const [popUpState, setPopUpState] = useState(false);
  const [actionTitle, setActionTitle] = useState<string>("");
  const [formData, setFormData] = useState<IFormInputs>();
  const [formDefaultData, setFormDefaultData] = useState<IFormInputs>();
  const [isEditMode, setIsEditMode] = useState(false);
  onPopupStateChange(popUpState);
  const onSubmit = (formValues: IFormInputs): void => {
    setFormData(formValues);
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
    setIsEditMode(false);
    form.reset({} as IFormInputs);
  };
  const handleshow = () => {
    setFormDefaultData({} as IFormInputs);
    form.reset({} as IFormInputs);
    setActionTitle("Add Dealer Fees");
    setPopUpState(true);
    setIsEditMode(false);
  };
  const form: {
    handleSubmit;
    control;
    setValue;
    reset;
    getValues;
    resetField;
    watch;
    setError;
    clearErrors;
    formState: { errors; isDirty };
  } = useForm<IFormInputs>();

  const theme = useTheme();

  const defaultValues: IFormInputs = useMemo(() => {
    return formDefaultData;
  }, [formDefaultData]) as unknown as IFormInputs;

  const onEdit = (formData: IFormInputs) => {
    // feeForm.setValue("fee_name", formData.fee_name)
    setFormDefaultData(formData);
    setActionTitle("Edit Dealer Fees");
    setIsEditMode(true);
    setPopUpState(true);
  };
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);
  useEffect(() => {
    if (!popUpState) {
      setIsEditMode(false);
    }
  }, [popUpState]);

  return (
    <>
      <DataTableWrap theme={theme} className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DealerFeesTable
          onEdit={(data) => onEdit(data)}
          dealer_code={dealer_code}
        />
        <Button
          theme={theme}
          secondary
          fullWidth
          text={"Add New Fees"}
          onClick={handleshow}
        />
      </DataTableWrap>
      <PersistentDrawer
        title={actionTitle}
        openPopUp={popUpState}
        setPopUpState={setPopUpState}
        enabledPin={true}
        theme={theme}
        children={
          <>
            <form onSubmit={(e) => e.preventDefault()}>
              <DealerFeeDrawer
                form={form}
                formData={formData}
                isEditMode={isEditMode}
                dealer_code={dealer_code}
              ></DealerFeeDrawer>
            </form>
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            type="submit"
            text="Save"
            fullWidth
            onClick={form.handleSubmit(onSubmit)}
          />
        }
      />
      {/* {popUpState && ( */}

      {/* )} */}
      {/* <DealerFeeDrawer></DealerFeeDrawer> */}
    </>
  );
};

export default DealerFees;
