import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { AddNewTermsSetup } from "components";
import { ActionOptions } from "Enums";
import { ITerms } from "Interface";
import TermSetupTable from "./term-setup-table";
import { ITermsSetupProps } from "./term-setup.type";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

const TermsSetup: FC<ITermsSetupProps> = () => {
  const [termsSetupState, setTermsSetupState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Term");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<ITerms>(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (termsSetupState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [termsSetupState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Term");
        setActionData(value);
        setTermsSetupState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: ITerms) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Terms");
    setActionData(formData);
    setTermsSetupState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <TermSetupTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Term"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        ></Button>
        {termsSetupState && (
          <AddNewTermsSetup
            openPopUp={termsSetupState}
            setPopUpState={setTermsSetupState}
            title={actionTitle}
            actionType={actionType}
            termsSetupData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default TermsSetup;
