import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { DataTableWrap } from "@sharedComponents";
import { Box, Button } from "@ntpkunity/controls";
import { AddNewOtherCost } from "../../components";
import { IOtherCosts } from "../../Interface";
import OtherCostTable from "./other-cost-table";
import { IOtherCostProps } from "./other-cost";
import { setOpenDrawer } from "../../Stores/SetupStore/Actions";
import { useSetupsStore } from "../../Stores/SetupStore/Store";
import { ActionOptions } from "Enums";

const OtherCost = () => {
  const [otherCostState, setOtherCostState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Other Cost");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<IOtherCosts>();
  const theme = useTheme();

  useEffect(() => {
    if (otherCostState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [otherCostState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Other Cost");
        setActionData(value);
        setOtherCostState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };

  const onEdit = (formData: IOtherCosts) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Other Cost");
    setActionData(formData);
    setOtherCostState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <OtherCostTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Other Cost"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        ></Button>
        {otherCostState && (
          <AddNewOtherCost
            openPopUp={otherCostState}
            setPopUpState={setOtherCostState}
            title={actionTitle}
            actionType={actionType}
            otherCostData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default OtherCost;
