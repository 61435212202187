import axios, { AxiosInstance } from "axios";
import { ACCESS_TOKEN_KEY } from "shared/config/constants";
import { LocalStorage } from "shared/helper/browserStorage";
import AppError, { ApiError } from "./errors";
import { logout, handleRefreshToken } from "@ntpkunity/controls-ums";
interface Setting {
  baseUrl?: string;
  withAuthentication?: boolean;
  _axiosInstance?: AxiosInstance;
}
class Http {
  _localStorageService: LocalStorage;
  _baseUrl?: string;
  _token: string | null;
  companyId: string | null;
  token: string | null;
  _axiosInstance?: AxiosInstance;
  constructor(settings?: Setting) {
    this._localStorageService = new LocalStorage();
    this._baseUrl = settings?.baseUrl
      ? settings?.baseUrl
      : process.env.REACT_APP_BASE_URL;
    this._token = settings?.withAuthentication
      ? this._localStorageService.getItemByKey(ACCESS_TOKEN_KEY)
      : null;
    this.companyId = localStorage.getItem("company_id");
    this.token = localStorage.getItem("api-key");

    this._axiosInstance = axios.create({
      baseURL: this._baseUrl,
      headers: {
        Authorization:
          `Bearer ${
            this._localStorageService.getItemByKey(ACCESS_TOKEN_KEY) as string
          }` ?? "",
      },
    });

    this._axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        await handleRefreshToken(error, this._axiosInstance, logout);
      }
    );
  }

  static createConnection(settings?: Setting) {
    return new Http(settings);
  }
  public async get<T>(url: string, params?: any, options?: any): Promise<T> {
    const headers = {
      ...options?.headers,
      company_id: this.companyId,
      Authorization: `Bearer ${this._localStorageService.getItemByKey(
        ACCESS_TOKEN_KEY
      )}`,
    };

    try {
      const response = await axios.get<T>(`${this._baseUrl}/${url}`, {
        ...options,
        auth: this._token,
        params,
        headers,
      });

      return response.data;
    } catch (error: any) {
      throw new ApiError(
        `Http.get(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { params, options }
      );
    }
  }
  public getPaginated(url: string, params?: any, options?: any): any {
    try {
      throw new AppError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        "NOT_IMPLEMENTED"
      );
    } catch (error: any) {
      throw new ApiError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { params, options }
      );
    }
  }
  public async post<T>(url: string, body?: any, options?: any): Promise<T> {
    const headers = {
      ...options?.headers,
      company_id: this.companyId,
      Authorization: `Bearer ${this._localStorageService.getItemByKey(
        ACCESS_TOKEN_KEY
      )}`,
    };
    try {
      const response = await axios.post<T>(`${this._baseUrl}/${url}`, body, {
        ...options,
        auth: this._token,
        headers,
      });

      return response.data;
    } catch (error: any) {
      throw new ApiError(
        `Http.post(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { body, options }
      );
    }
  }
  public async put<T>(url: string, body?: any, options?: any): Promise<T> {
    const headers = {
      ...options?.headers,
      company_id: this.companyId,
      Authorization: `Bearer ${this._localStorageService.getItemByKey(
        ACCESS_TOKEN_KEY
      )}`,
    };
    try {
      const response = await axios.put<T>(`${this._baseUrl}/${url}`, body, {
        ...options,
        auth: this._token,
        headers,
      });

      return response.data;
    } catch (error: any) {
      throw new ApiError(
        `Http.put(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { body, options }
      );
    }
  }
  public async patch<T>(url: string, body?: any, options?: any): Promise<T> {
    const headers = {
      ...options?.headers,
      company_id: this.companyId,
      Authorization: `Bearer ${this._localStorageService.getItemByKey(
        ACCESS_TOKEN_KEY
      )}`,
    };
    try {
      const response = await axios.patch<T>(`${this._baseUrl}/${url}`, body, {
        ...options,
        auth: this._token,
        headers,
      });

      return response.data;
    } catch (error: any) {
      throw new ApiError(
        `Http.put(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { body, options }
      );
    }
  }
  public async delete<T>(url: string, params?: any, options?: any): Promise<T> {
    const headers = {
      ...options?.headers,
      company_id: this.companyId,
      Authorization: `Bearer ${this._localStorageService.getItemByKey(
        ACCESS_TOKEN_KEY
      )}`,
    };
    try {
      const response = await axios.delete<T>(`${this._baseUrl}/${url}`, {
        ...options,
        params,
        auth: this._token,
        headers,
      });

      return response.data;
    } catch (error: any) {
      throw new ApiError(
        `Http.delete(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { params, options }
      );
    }
  }
}

export default Http;
