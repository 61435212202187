import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { FAVICON, THEME_BUILDER_CSS } from "shared/config/constants";
import { LayoutWithSideNav } from "../layout";
import {
  Icon,
  Grid,
  DuoTab,
  Box,
  Button,
  Typography,
  Dialog,
} from "@ntpkunity/controls";
import {
  PageHeader,
  ConfirmationDialog,
  ThemeBuilder,
  DrawerWrap,
} from "@sharedComponents";
import Logo from "shared/assets/images/index-logo.svg";
import { IAddress, ICountry, ISelectItemList } from "Interface";
import { useForm } from "react-hook-form";
import {
  UseMutation_UpdateCompanyProfile,
  UseMutation_AddCompanyProfile,
  UseQuery_GetAll_CURRENCIES,
  UseQuery_GetAll_USA_States,
  UseQuery_GetAll_Countries,
  UseQuery_GetAll_Radius,
  useMutation_ThemeBuilderByEmail,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { CompanyProfile, CompanyAddress, Language } from "modules";
import { usePrompt } from "utilities/usePrompt";
import { CompanyModel } from "models";
import {
  AddressType,
  QueryKeys,
  DialogMessages,
  ValidationMessages,
} from "Enums";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
import { setStyleSheet, setFavicon, setLogo } from "favicon";

const Company: FC = () => {
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  const { openDrawerSt } = state;
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [failurePopupState, setFailurePopupState] = useState<boolean>(false);
  const [companyFieldState, setCompanyFieldState] = useState<boolean>(false);
  const [applyInvoiceValidation, setApplyInvoiceValidation] =
    useState<boolean>(false);
  const [applyOfficeAddressValidation, setApplyOfficeAddressValidation] =
    useState<boolean>(false);
  const [applyGaragingAddressValidation, setApplyGaragingAddressValidation] =
    useState<boolean>(false);
  const [defaultCurrency, setDefaultCurrency] =
    useState<Array<ISelectItemList>>();
  const [companyCountries, setCompanyCountries] = useState<Array<ICountry>>([]);
  const [userEmail, setUserEmail] = useState<string>(null);
  const form = useForm();
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setValue,
    reset,
    setError,
  } = form;
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );

  useEffect(() => {
    if (localStorage.getItem("settings") != null) {
      var access_token_local = JSON.parse(localStorage.getItem("settings"));
      localStorage.setItem("api-key", access_token_local?.access_token);
      if (access_token_local?.role?.name == "Index Dealer Admin User") {
        setUserEmail(access_token_local?.email);
      }
    }
  }, []);

  const { mutate: companyThemeData } = useMutation_ThemeBuilderByEmail();
  const [fontFiles, setFontFiles] = useState(
    companyThemeData.theme?.font_file?.files || []
  );
  useEffect(() => {
    const fetchData = async () => {
      if (userEmail) {
        companyThemeData(
          { user_email: userEmail },
          {
            onSuccess: async (response) => {
              if (response?.data) {
                const colors = await handleButtonClick(
                  response?.data?.stylesheet
                );
                if (colors) {
                  setValue("theme.primary_color", colors.primaryColor);
                  setValue("theme.secondary_color", colors.secondaryColor);
                }
                setValue("theme.favicon", response?.data?.favicon);
                setValue("theme.logo", response?.data?.logo);
                setFavicon(response?.data?.favicon?.location);
                setLogo(response?.data?.logo?.location);
                if (Array.isArray(response?.data?.font_file?.files)) {
                  const fontFilesFromDB = response?.data?.font_file?.files.map(
                    (file) => ({
                      name: file.key,
                      key: file.key,
                    })
                  );
                  setFontFiles(fontFilesFromDB);
                } else {
                  setFontFiles([]);
                }
              } else {
                setThemeDefaultValues();
              }
            },
          }
        );
      }
    };

    fetchData();
  }, [userEmail]);

  useEffect(() => {
    if (companyProfile) {
      setUserEmail(companyProfile.email);
    }
  }, [companyProfile]);

  UseQuery_GetAll_CURRENCIES();
  UseQuery_GetAll_USA_States();
  UseQuery_GetAll_Countries();
  UseQuery_GetAll_Radius();

  const setThemeDefaultValues: any = () => {
    setValue("theme.favicon", defaultThemeValues?.favicon);
    setValue("theme.logo", defaultThemeValues?.logo);
    setValue("theme.primary_color", defaultThemeValues?.primary_color);
    setValue("theme.secondary_color", defaultThemeValues?.secondary_color);
    setFavicon(defaultThemeValues.favicon?.location);
    setLogo(defaultThemeValues.logo?.location);
  };
  // const ThemeBuilderData: any = queryClient.getQueryData([
  //   QueryKeys.GET_THEME_BUILDER,
  //   companyProfile?.email,
  // ]);
  const addressStates: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_USA_STATES
  );
  const allCountries: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_COUNTRIES
  );
  const [selectedDefaultCurrencyId, setSelectedDefaultCurrencyId] =
    useState<number>(companyProfile?.default_currency);

  const defaultThemeValues = {
    primary_color: "3952F5",
    secondary_color: "D8DAE5",
    favicon: {
      location: `${process.env.DMS_BUCKET_BASE_URL + FAVICON}`,
    },
    logo: { location: Logo },
  };

  useEffect(() => {
    if (
      formState.dirtyFields?.MailingAddress ||
      formState.dirtyFields?.OfficeAddress ||
      formState.dirtyFields?.GaragingAddress ||
      formState.dirtyFields?.InvoicingAddress ||
      formState.dirtyFields?.theme
    ) {
      setCompanyFieldState(true);
    }
  }, [formState]);

  function setFormValue(name: string, address: IAddress) {
    setValue(
      name,
      {
        id: address?.id ? address?.id : undefined,
        address_line_1: address?.address_line_1 ? address?.address_line_1 : "",
        address_line_2: address?.address_line_2 ? address?.address_line_2 : "",
        city: address?.city ? address?.city : "",
        contact_number: address?.contact_number ? address?.contact_number : "",
        county: address?.county ? address?.county : "",
        zip_code: address?.zip_code ? address?.zip_code : "",
        state_id: address?.state_id ? address?.state_id : "",
        state_name: address?.state_name ? address?.state_name : "",
        field5: address?.field5 ? address?.field5 : "",
        field6: address?.field6 ? address?.field6 : "",
        verified: address?.verified ? address?.verified : false,
        address_type: address?.address_type ? address?.address_type : "",
      },
      { shouldValidate: false, shouldDirty: false }
    );
  }
  useEffect(() => {
    setSelectedDefaultCurrencyId(companyProfile?.default_currency);
    let company: CompanyModel = new CompanyModel();
    company.company_profile = companyProfile;
    company.company_addresses = companyProfile?.addresses;
    company.theme = companyProfile?.theme;
    if (companyProfile) {
      setUserEmail(companyProfile?.email);
    }
    if (companyProfile?.theme) {
      setValue("theme.favicon", companyProfile?.theme?.favicon);
      setValue("theme.logo", companyProfile?.theme?.logo);
      setValue("theme.font_file", companyProfile?.theme?.font_file);
      setValue("theme", companyProfile?.theme);
    } else {
      setThemeDefaultValues();
    }
    setFormValue(
      "MailingAddress",
      company.company_addresses?.find(
        (x) => x.address_type == AddressType.MAILING
      )
    );
    setFormValue(
      "OfficeAddress",
      company.company_addresses?.find(
        (x) => x.address_type == AddressType.OFFICE
      )
    );
    setFormValue(
      "InvoicingAddress",
      company.company_addresses?.find(
        (x) => x.address_type == AddressType.INVOICE
      )
    );
    setFormValue(
      "GaragingAddress",
      company.company_addresses?.find(
        (x) => x.address_type == AddressType.GARAGING
      )
    );

    let val = company?.company_profile?.preferred_currency_ids
      ?.split(",")
      ?.map(Number);

    setValue("CompanyProfile", company?.company_profile, {
      shouldDirty: false,
      shouldTouch: false,
    });
    setValue("CompanyProfile.preferred_currency_ids", val);

    setValue(
      "CompanyProfile.default_currency",
      company?.company_profile?.default_currency
    );
    setValue(
      "CompanyProfile.credit_decision_expiry_in_days",
      company?.company_profile?.credit_decision_expiry_in_days || 90
    );
    setValue("MailingAddress.address_type", AddressType.MAILING);
    setValue("InvoicingAddress.address_type", AddressType.INVOICE);
    setValue("OfficeAddress.address_type", AddressType.OFFICE);
    setValue("GaragingAddress.address_type", AddressType.GARAGING);

    if (companyProfile?.theme == null) {
      setThemeDefaultValues();
    } else {
      setValue("theme", companyProfile?.theme);
    }
  }, [companyProfile]);
  const handleButtonClick = async (stylesheetlink: any) => {
    const s3FileLink = stylesheetlink;

    const response = await fetch(s3FileLink, {
      mode: "cors",
      method: "GET",
    });

    const cssContent = await response.text();
    const primaryColorRegex = /--primary:\s*#?([a-fA-F0-9]+)\b/;
    const secondaryColorRegex = /--secondary:\s*#?([a-fA-F0-9]+)\b/;

    // Extract primary and secondary color values from the CSS string
    const primaryColorMatch = cssContent.match(primaryColorRegex);
    const secondaryColorMatch = cssContent.match(secondaryColorRegex);
    // const matches = cssContent.match(colorRegex);
    const primaryColor = primaryColorMatch ? primaryColorMatch[1].trim() : null;
    const secondaryColor = secondaryColorMatch
      ? secondaryColorMatch[1].trim()
      : null;

    setValue("theme.primary_color", primaryColor);
    setValue("theme.secondary_color", secondaryColor);
    if (!primaryColor && !secondaryColor) {
      setValue("theme.primary_color", defaultThemeValues.primary_color);
      setValue("theme.secondary_color", defaultThemeValues.secondary_color);
    }
    return { primaryColor: primaryColor, secondaryColor: secondaryColor };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (companyProfile?.theme) {
        const colors = await handleButtonClick(
          companyProfile?.theme?.stylesheet
        );
        if (colors) {
          setValue("theme.primary_color", colors.primaryColor);
          setValue("theme.secondary_color", colors.secondaryColor);
        }
      } else {
        setThemeDefaultValues();
      }
    };

    fetchData();
  }, [companyProfile?.theme]);
  async function convertCssToBase64(
    primaryColor: string,
    secondaryColor: string
  ) {
    try {
      const response = await fetch(
        `${process.env.DMS_BUCKET_BASE_URL + THEME_BUILDER_CSS}`,
        {
          mode: "cors",
          method: "GET",
        }
      );

      const cssContent = await response.text();
      const modifiedCss = modifyCssContent(
        cssContent,
        primaryColor,
        secondaryColor
      );

      const blob = new Blob([modifiedCss], { type: "text/css" });

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const base64Css = btoa(event.target.result as string);
            resolve(base64Css);
          } else {
            reject(new Error("Error converting CSS to base64."));
          }
        };
        reader.readAsBinaryString(blob);
      });
    } catch (error: any) {
      throw new Error("Error fetching or updating CSS file: " + error.message);
    }
  }

  function modifyCssContent(
    cssContent: any,
    primaryColor: string,
    secondaryColor: string
  ) {
    const modifiedCss = cssContent
      .replace(/--primary:\s*(.*?);/, `--primary: #${primaryColor};`)
      .replace(/--secondary:\s*(.*?);/, `--secondary: #${secondaryColor};`);

    return modifiedCss;
  }
  const { mutate: UpdateCompanyProfile, isLoading: updateLoader } =
    UseMutation_UpdateCompanyProfile();
  const { mutate: addCompanyProfile } = UseMutation_AddCompanyProfile();
  async function onSave(data) {
    let errorStates = false;
    if (data.theme) {
      data.theme.tenant_id = JSON.parse(
        localStorage.getItem("settings")
      )?.tenant_id;
      const base64Css = await convertCssToBase64(
        data.theme.primary_color,
        data.theme.secondary_color
      );
      data.theme.stylesheet = base64Css;

      if (data.theme.favicon_url) {
        data.theme.favicon = {
          url: data.theme.favicon_url.url,
          key: data.theme.favicon_url.key,
        };
        delete data.theme.favicon_url;
      }
      if (data.theme.logo_url) {
        data.theme.logo = {
          url: data.theme.logo_url.url,
          key: data.theme.logo_url.key,
        };
        delete data.theme.logo_url;
      }
      if (Array.isArray(fontFiles)) {
        data.theme.font_file = {
          files: fontFiles.map((file) => ({
            key: file.key,
          })),
        };
      }
    }
    if (
      !data.MailingAddress.address_line_1 ||
      !data.MailingAddress.city ||
      data.MailingAddress.country_id
    ) {
      if (!data.MailingAddress.address_line_1) {
        setError("MailingAddress.address_line_1", {
          message: ValidationMessages.ADDRESS_LINE_1,
        });
        errorStates = true;
      }
      if (!data.MailingAddress.city) {
        setError("MailingAddress.city", {
          message: ValidationMessages.CITY_REQUIRED,
        });
        errorStates = true;
      }
      if (data.MailingAddress.country_id) {
        const countryCode = companyCountries.find(
          (x) => x.id == data.MailingAddress.country_id.id
        ).country_code;
        if (
          (countryCode == "US" || countryCode == "CA") &&
          (!data.MailingAddress.state_id || !data.MailingAddress.zip_code)
        ) {
          if (!data.MailingAddress.state_id) {
            setError("MailingAddress.state_id", {
              message: ValidationMessages.STATE_REQUIRED,
            });
            errorStates = true;
          }
          if (!data.MailingAddress.zip_code) {
            setError("MailingAddress.zip_code", {
              message: ValidationMessages.ZIP_CODE_REQUIRED,
            });
            errorStates = true;
          }
        }
      }
    }
    if (data.theme) {
      if (
        !data.theme.primary_color ||
        !data.theme.secondary_color ||
        !data.theme.logo ||
        !data.theme.favicon
      ) {
        if (!data.theme.logo) {
          setError("theme.logo", {
            message: "Logo is Required",
          });
          errorStates = true;
        }
        if (!data.theme.favicon) {
          setError("theme.favicon", {
            message: "Favicon is Required",
          });
          errorStates = true;
        }
        if (!data.theme.favicon) {
          setError("theme.favicon", {
            message: "Favicon is Required",
          });
          errorStates = true;
        }
        if (!data.theme.primary_color) {
          setError("theme.primary_color", {
            message: "Primary Color is Required",
          });
          errorStates = true;
        }
        if (!data.theme.secondary_color) {
          setError("theme.secondary_color", {
            message: "Secondary Color is Required",
          });
          errorStates = true;
        }
      }
    }
    if (data.theme == null) {
      setThemeDefaultValues();
    }
    if (errorStates) {
      return;
    }
    let addrArr = [];
    let company: CompanyModel = new CompanyModel();
    company.company_profile = data?.CompanyProfile;
    company.company_profile.preferred_currency_ids =
      company.company_profile?.preferred_currency_ids?.toString();
    company.company_profile.minimum_financed_amount =
      +company.company_profile.minimum_financed_amount;
    company.theme = data?.theme;
    company.theme.id = company.theme?.id ?? 0;
    company.theme.user_email = company.company_profile?.email;

    if (
      data?.MailingAddress?.address_line_1 != undefined &&
      data?.MailingAddress?.address_line_1 != ""
    ) {
      data.MailingAddress.company_id = company?.company_profile?.id;
      data.MailingAddress.address_type = AddressType.MAILING;
      data.MailingAddress.verified = data.MailingAddress.verified
        ? data.MailingAddress.verified
        : false;
      data.MailingAddress.country_id = data.MailingAddress.country_id?.id;
      data.MailingAddress.state_id = data.MailingAddress.state_id
        ? data.MailingAddress.state_id
        : null;
      if (!data.MailingAddress.state_name) {
        data.MailingAddress.state_name = data.MailingAddress.state_id
          ? addressStates?.find((x) => x.id == data.MailingAddress.state_id)
              ?.name
          : "";
      }
      addrArr?.push(data.MailingAddress);
    }
    if (
      data?.GaragingAddress?.address_line_1 != undefined &&
      data?.GaragingAddress?.address_line_1 != ""
    ) {
      data.GaragingAddress.company_id = company?.company_profile?.id;
      data.GaragingAddress.address_type = AddressType.GARAGING;
      data.GaragingAddress.verified = data.GaragingAddress.verified
        ? data.GaragingAddress.verified
        : false;
      data.GaragingAddress.country_id = data.GaragingAddress?.country_id?.id;
      data.GaragingAddress.state_id = data.GaragingAddress.state_id
        ? data.GaragingAddress.state_id
        : null;
      if (!data.GaragingAddress.state_name) {
        data.GaragingAddress.state_name = data.GaragingAddress.state_id
          ? addressStates?.find((x) => x.id == data.GaragingAddress.state_id)
              ?.name
          : "";
      }
      addrArr.push(data.GaragingAddress);
    }
    if (
      data?.InvoicingAddress?.address_line_1 != undefined &&
      data?.InvoicingAddress?.address_line_1 != ""
    ) {
      data.InvoicingAddress.company_id = company?.company_profile?.id;
      data.InvoicingAddress.address_type = AddressType.INVOICE;
      data.InvoicingAddress.verified = data.InvoicingAddress.verified
        ? data.InvoicingAddress.verified
        : false;
      data.InvoicingAddress.country_id = data.InvoicingAddress?.country_id?.id;
      data.InvoicingAddress.state_id = data.InvoicingAddress.state_id
        ? data.InvoicingAddress.state_id
        : null;
      if (!data.InvoicingAddress.state_name) {
        data.InvoicingAddress.state_name = data.InvoicingAddress.state_id
          ? addressStates?.find((x) => x.id == data.InvoicingAddress.state_id)
              ?.name
          : "";
      }
      addrArr.push(data.InvoicingAddress);
    }
    if (
      data?.OfficeAddress?.address_line_1 != undefined &&
      data?.OfficeAddress?.address_line_1 != ""
    ) {
      data.OfficeAddress.company_id = company?.company_profile?.id;
      data.OfficeAddress.address_type = AddressType.OFFICE;
      data.OfficeAddress.verified = data.OfficeAddress.verified
        ? data.OfficeAddress.verified
        : false;
      data.OfficeAddress.country_id = data.OfficeAddress?.country_id?.id;
      data.OfficeAddress.state_id = data.OfficeAddress.state_id
        ? data.OfficeAddress.state_id
        : null;
      if (!data.OfficeAddress.state_name) {
        data.OfficeAddress.state_name = data.OfficeAddress.state_id
          ? addressStates?.find((x) => x.id == data.OfficeAddress.state_id)
              ?.name
          : "";
      }
      addrArr.push(data.OfficeAddress);
    }
    if (addrArr?.length > 0) {
      company.company_addresses = addrArr;
    }
    if (company?.company_addresses?.length > 0) {
      company.company_profile.id > 0
        ? UpdateCompanyProfile(company, {
            onSuccess: (response: any) => {
              if (response.theme) {
                setStyleSheet(response?.data?.theme?.stylesheet);
                setFavicon(response?.data.theme?.favicon?.location);
                setLogo(response?.data.theme?.logo?.location);
              }
              if (response.theme == null) {
                setFavicon(defaultThemeValues.favicon?.location);
                setLogo(defaultThemeValues.logo?.location);
              }
              queryClient.invalidateQueries([
                QueryKeys.GET_THEME_BUILDER,
                response?.data?.theme.user_email,
              ]);
            },
          })
        : addCompanyProfile(company);
      delete formState.dirtyFields?.MailingAddress;
      delete formState.dirtyFields?.OfficeAddress;
      delete formState.dirtyFields?.GaragingAddress;
      delete formState.dirtyFields?.InvoicingAddress;
      delete formState.dirtyFields?.theme;
      setCompanyFieldState(false);
    }
  }
  const resetMailingAddress = () => {
    setValue("MailingAddress.address_line_1", "");
    setValue("MailingAddress.address_line_2", "");
    setValue("MailingAddress.city", "");
    setValue("MailingAddress.contact_number", "");
    setValue("MailingAddress.county", "");
    setValue("MailingAddress.zip_code", "");
    setValue("MailingAddress.state_id", "");
    setValue("MailingAddress.verified", false);
  };

  const tabs = [
    {
      title: "Profile",
      content: (
        <CompanyProfile
          control={control}
          setValue={setValue}
          errors={formState.errors}
          setCompanyFieldState={setCompanyFieldState}
          defaultCurrency={defaultCurrency}
          setDefaultCurrency={setDefaultCurrency}
          selectedDefaultCurrencyId={selectedDefaultCurrencyId}
          setSelectedDefaultCurrencyId={setSelectedDefaultCurrencyId}
          getValues={getValues}
          setCompanyCountries={setCompanyCountries}
          resetMailingAddress={() => resetMailingAddress()}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(formState.errors).includes("MailingAddress")
              ? "error"
              : ""
          }
          theme={theme}
        >
          Address
        </Box>
      ),

      content: (
        <CompanyAddress
          resetMailingAddress={() => resetMailingAddress()}
          control={control}
          errors={formState.errors}
          setValue={setValue}
          getValues={getValues}
          applyInvoiceValidation={applyInvoiceValidation}
          applyOfficeAddressValidation={applyOfficeAddressValidation}
          setApplyInvoiceValidation={setApplyInvoiceValidation}
          setApplyOfficeAddressValidation={setApplyOfficeAddressValidation}
          applyGaragingAddressValidation={applyGaragingAddressValidation}
          setApplyGaragingAddressValidation={setApplyGaragingAddressValidation}
          companyCountries={companyCountries}
          setFormValue={setFormValue}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(formState.errors).includes("theme") ? "error" : ""
          }
          theme={theme}
        >
          Personalisation
        </Box>
      ),
      content: (
        <ThemeBuilder
          form={form}
          theme={theme}
          fontFiles={fontFiles}
          setFontFiles={setFontFiles}
          value={defaultThemeValues}
          showFontFileControl={true}
        />
      ),
    },
    {
      title: <Box theme={theme}>Language</Box>,
      content: <Language />,
    },
  ];

  const onClose = () => {
    setFailurePopupState(false);
  };
  useEffect(() => {
    if (companyProfile?.countries) {
      let arr: Array<ICountry> = [];
      for (let i = 0; i < companyProfile?.countries.length; i++) {
        if (allCountries)
          arr.push(
            allCountries?.find((x) => x.id == companyProfile?.countries[i])
          );
      }
      setCompanyCountries(arr);
      setValue("CompanyProfile.countries", companyProfile?.countries);
    }
  }, [allCountries, companyProfile]);

  const onSaveConfirm = (e: any) => {
    handleSubmit(onSave)();
    setLeavePageState(false);
    setCompanyFieldState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };

  usePrompt(
    "Leave screen?",
    companyFieldState,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      setCompanyFieldState(false);
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <DrawerWrap open={openDrawerSt}>
        <LayoutWithSideNav theme={theme}>
          <Grid theme={theme} container rowSpacing={2}>
            <Grid theme={theme} item xs={12} />
            <PageHeader
              theme={theme}
              className="main-page-header border-bottom"
            >
              <Grid theme={theme} container spacing={2}>
                <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{ fontSize: 36 }}
                    rowGap={5}
                    mt={1}
                    data-testid="heading"
                    theme={theme}
                  >
                    Company Profile
                  </Typography>
                </Grid>
                <Grid
                  theme={theme}
                  item
                  xs={12}
                  lg={5}
                  sm={12}
                  md={6}
                  textAlign="right"
                >
                  {form.formState.isDirty && companyFieldState ? (
                    <Button
                      theme={theme}
                      onClick={handleSubmit(onSave)}
                      primary
                      text="Save Changes"
                    />
                  ) : null}
                </Grid>
              </Grid>
            </PageHeader>

            <Grid theme={theme} item xs={12} my={-2} sx={{ mb: 5 }}>
              {/* <DuoTabWrap theme={theme} className="tab-form"> */}
              <DuoTab
                theme={theme}
                varient={"underline"}
                items={tabs}
                defaultTabIndex={0}
              />
              {/* </DuoTabWrap> */}
            </Grid>
          </Grid>
          <Grid theme={theme} item xs={12} />
          {failurePopupState && (
            <Dialog
              size="xs"
              variant="confirmation"
              open={failurePopupState}
              noFooter={false}
              onCloseDialog={onClose}
              theme={theme}
              customFooter={
                <Button
                  primary
                  text={"Close"}
                  fullWidth={true}
                  style={{ paddingRight: "82px", paddingLeft: "82px" }}
                  onClick={onClose}
                />
              }
            >
              <Box theme={theme} className="dialog-icon">
                <Icon name="DialogAlertIcon" />
              </Box>
              <Box theme={theme}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  An Error Ocurred
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  Something went wrong
                </Typography>
              </Box>
            </Dialog>
          )}
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default Company;
