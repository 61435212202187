import { FC, useEffect, useState, useMemo } from "react";
import { useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { LayoutWithSideNav } from "layout";
import {
  Button,
  Typography,
  Box,
  Icon,
  PersistentDrawer,
} from "@ntpkunity/controls";
import { DrawerWrap, PageHeader, ConfirmationDialog } from "@sharedComponents";
import AddNewDealer from "../components/AddNewDealer/AddNewDealer";
import ManageDealersTable from "../modules/DealerManagement/manage-dealers-table";
import { IDealer } from "Interface";
import { usePrompt } from "utilities/usePrompt";
import { DialogMessages } from "Enums";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";

const DealerManagement: FC = () => {
  const theme = useTheme();
  const [popUpState, setPopUpState] = useState(false);
  const [formData, setFormData] = useState<IDealer>();
  const [formDefaultData, setFormDefaultData] = useState<IDealer>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const onSubmit = (formValues: IDealer): void => {
    setFormData(formValues);
  };

  const onEdit = (formData: IDealer) => {
    setFormDefaultData(formData);
    setIsEditMode(true);
    setPopUpState(true);
  };

  const form: {
    handleSubmit;
    control;
    setValue;
    reset;
    getValues;
    resetField;
    watch;
    setError;
    clearErrors;
    formState: { errors; isDirty };
  } = useForm<IDealer>();

  const defaultValues: IDealer = useMemo(() => {
    return formDefaultData;
  }, [formDefaultData]) as unknown as IDealer;

  const handleshow = () => {
    setFormDefaultData({} as IDealer);
    form.reset({} as IDealer);
    setPopUpState(true);
    setIsEditMode(false);
  };

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!popUpState) {
      setIsEditMode(false);
      form.reset(form.getValues());
    }
  }, [popUpState]);
  const onSaveConfirm = (e: any) => {
    form.handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    form.formState.isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      form.reset(form.getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <DrawerWrap open={popUpState}>
        <LayoutWithSideNav theme={theme}>
          <PageHeader className="main-page-header">
            <Box theme={theme} flexGrow={"1"}>
              <Typography theme={theme} variant="h2" component="h2">
                Manage Dealers
              </Typography>
            </Box>
            <Box
              theme={theme}
              className="action-area"
              flexShrink={0}
              display={"flex"}
              alignItems={"center"}
            >
              <Button
                theme={theme}
                primary
                id="addNewDealerBtn"
                text={"Add New Dealer"}
                startIcon={<Icon name="AddIcon" />}
                onClick={handleshow}
              />
            </Box>
          </PageHeader>

          <PersistentDrawer
            title={isEditMode ? "Edit Dealer" : "Add New Dealer"}
            openPopUp={popUpState}
            setPopUpState={setPopUpState}
            theme={theme}
            enabledPin={true}
            customFooter={
              <Button
                theme={theme}
                primary
                id="saveDealerBtn"
                type="submit"
                text="Save"
                fullWidth
                onClick={form.handleSubmit(onSubmit)}
              />
            }
          >
            <form onSubmit={(e) => e.preventDefault()}>
              <AddNewDealer
                form={form}
                formData={formData}
                isEditMode={isEditMode}
                setPopUpState={setPopUpState}
                setIsEditMode={setIsEditMode}
                dealerCode={defaultValues?.dealer_code}
              ></AddNewDealer>
            </form>
          </PersistentDrawer>

          <ManageDealersTable
            onEdit={(data) => onEdit(data)}
          ></ManageDealersTable>
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default DealerManagement;
