export enum ValidationMessages {
  CREDIT_SCORE_FROM_REQUIRED = "Credit score from is required",
  CREDIT_SCORE_TO_REQUIRED = "Credit score to is required",
  TERM_FROM_REQUIRED = "Term from is required",
  TERM_TO_REQUIRED = "Term to is required",
  TERM_LESS_THEN_ZERO = "Term should be greater than zero",
  TERM_TO_GREATER_THAN_TERM_FROM = "Term To should be greater than Term From",
  CHART_NAME_REQUIRED = "Chart name is required",
  MARGIN_REQUIRED = "Margin is required",
  MARGIN_TYPE_REQUIRED = "Margin Type is required",
  MAX_MARGIN_REQUIRED = "Max. Margin is required",
  MIN_MARGIN_REQUIRED = "Min. Margin is required",
  MAX_VALUE_REQUIRED = "Max. Value is required",
  MIN_VALUE_REQUIRED = "Min. Value is required",
  MIN_SMALLER_THAN_DEFAULT = "Min. Value should be less than or equal to Default Value",
  Max_GREATER_THAN_DEFAULT = "Max. Value should be greater than or equal to Default Value",
  MIN_MARGIN_SMALLER_THAN_DEFAULT = "Min. Margin should be less than or equal to Default Margin",
  Max_MARGIN_GREATER_THAN_DEFAULT = "Max. Margin should be greater than or equal to Default Margin",
  MIN_DOWN_PAYMENT_SMALLER_THAN_DEFAULT = "Min. Down Payment should be less than or equal to Default Down Payment",
  MAX_DOWN_PAYMENT_GREATER_THAN_DEFAULT = "Max. Down Payment should be greater than or equal to Default Down Payment",
  MAX_DOWN_PAYMENT_VALUE_GREATER = "Max Down Payment should be greater than Min Down Payment",
  MIN_DOWN_PAYMENT_VALUE_SMALLER = "Min Down Payment should be smaller than Max Down Payment",
  MAX_VALUE_GREATER = "Max RV should be greater than Min RV",
  MIN_VALUE_SMALLER = "Min RV should be smaller than Max RV",
  MAX_MARGIN_GREATER = "Max Margin should be greater than Min Margin",
  RV_TYPE_REQUIRED = "RV/Balloon Type is required",
  DEFAULT_VALUE_REQUIRED = "Default Value is required",
  DISTANCE_FROM_REQUIRED = "Distance from is required",
  DISTANCE_TO_REQUIRED = "Distance to is required",
  NAME_REQUIRED = "Name is required",
  ASSET_MAKE_REQUIRED = "Make is required",
  TRADING_NAME_REQUIRED = "Trading Name is required",
  TRADING_NAME_FORMAT = "Lender Trading Name does not accept special characters",
  COMPANY_NAME_FORMAT = "Company Name does not accept special characters",
  CODE_REQUIRED = "Code is required",
  ORDER_STATUS_REQUIRED = "Order Status is required",
  CODE_UNIQUE = "Code should be unique",
  MODEL_NAME_REQUIRED = "Model is required",
  ASSET_MODEL_REQUIRED = "Model is required",
  TRIM_NAME_REQUIRED = "Trim is required",
  TRIM_CODE_REQUIRED = "Code is required",
  RESPONSE_CODE_REQUIRED = "Response Code is required",
  RESPONSE_DESCRIPTION_REQUIRED = "Response Description is required",
  INVALID_URL = "Provided URL is not valid",
  URL_REQUIRED = "URL is required",
  INITIALS_REQUIRED = "Initials are required",
  TAX_ID_REQUIRED = "TAX ID is required",
  MINIMUM_FINANCED_AMOUNT_REQUIRED = "Minimum Financed Amount is required",
  PREFERRED_CURRENCY_REQUIRED = "Preferred Currency is required",
  DEFAULT_CURRENCY_REQUIRED = "Default Currency is required",
  Existing_Customer_Check = "Existing Customer Check is required",
  CITY_REQUIRED = "City is required",
  ZIP_CODE_REQUIRED = "Zip code is required",
  COUNTY_REQUIRED = "County is required",
  CONTACT_NUMBER_REQUIRED = "Contact Number is required",
  INVALID_INITIAL = "Provided initials should be upto 2 alphabets",
  INVALID_CONTACT_NUMBER = "Format is not valid",
  STATE_REQUIRED = "State is required",
  INVALID_EMAIL = "Provided email is not valid",
  INVALID_FINANCED_AMOUNT = "Provided finance amount should be upto 2 decimal places",
  EMAIL_REQUIRED = "Email is required",
  ADDRESS_LINE_1 = "Address is required",
  INVALID_TAX_ID_FORMAT = "Tax format is not valid",
  MINIMUM_FINANCED_AMOUNT_FORMAT = "Financed Amount should be like 0.00 and upto two decimal places",
  FP_NAME = "Program Name is Required.",
  FP_ID = "Program ID is Required.",
  FP_FINANCE_TYPE = "Finance Type is Required.",
  VALIDITY_START_DATE = "Date From is Required",
  INTEREST_RATE_OVERRIDE = "Margin Rate Override is Required.",
  RENTAL_FREQUENCY_REQUIRED = "Rental Frequency is Required.",
  INTEREST_TYPE_REQUIRED = "Margin Rate Type is Required.",
  CALCULATION_METHOD = "Calculation Method is Required.",
  RENTAL_MODE = "Rental Mode is Required.",
  RATE_CONVERSION_METHOD = "Margin Rate Conversion Method is Required.",
  RV_BALLON_APPLICABLE = "RV/Balloon Applicable is Required.",
  RV_BALLON_TYPE = "RV/Balloon Type is Required.",
  RV_CALCULATION_METHOD = "RV/Balloon Calculation Method is Required.",
  RV_BALLON_OVERRIDE = "RV/Balloon Override is Required.",
  INTEREST_CHART = "Margin Rate is Required.",
  RV_CHART = "RV/Balloon is Required.",
  STIPULATION_CODE_MAX_LENGTH = "Length should be less than or equal to 5",
  STIPULATION_CODE_REQUIRED = "Stipulation Code is required",
  STIPULATION_TYPE_REQUIRED = "Stipulation type is required ",
  STIPULATION_PARAMAETER_REQUIRED = "Parameter is required",
  DESCRIPTION_REQUIRED = "Description is required",
  STIPULATION_ASSIGNEE_REQUIRED = "Assignee is required",
  STIPULATION_DOCUMENT_TYPE_REQUIRED = "Document Type is required",
  UNIQUE_NAME = "Program Name should be unique",
  UNIQUE_CODE = "Program Code should be unique",
  CODE_MAX_LENGTH = "Length should be less than or equal to 5",
  VALUE_MAX_LENGTH = "Length should be less than or equal to 6",
  CONTRACT_TERM_VALUE_MAX_LENGTH = "Length should be less than or equal to 3",
  VALUE_REQUIRED = "Value is required",
  DOCUMENT_CODE_MAX_LENGTH = "Length should be less than or equal to 5",
  BUSINESS_CODE_MAX_LENGTH = "Length should be less than or equal to 5",
  DOCUMENT_CODE_REQUIRED = "Document Code is required",
  BUSINESS_CODE_REQUIRED = "Business Code is required",
  DOCUMENT_DESCRIPTION_MAX_LENGTH = "Length should be less than or equal to 150",
  BUSINESS_DESCRIPTION_MAX_LENGTH = "Length should be less than or equal to 150",
  DOCUMENT_DESCRIPTION_REQUIRED = "Description is required",
  BUSINESS_DESCRIPTION_REQUIRED = "Description is required",
  FEE_CODE_MAX_LENGTH = "Length should be less than or equal to 5",
  FEE_CODE_REQUIRED = "Fee Code is required",
  FEE_NAME_REQUIRED = "Fee Name is required ",
  ASSET_CODE_REQUIRED = "Asset Condition Code is required",
  ASSET_DESCRIPTION_REQUIRED = "Asset Condition Description is required",
  ASSET_CONDITION_CODE_MAX_LENGTH = "Length should be less than or equal to 5",
  INTEGRATION_TYPE_REQUIRED = "Integration Type Required",
  PROVIDER_REQUIRED = "Provider Required",
  PROVIDER_CODE_REQUIRED = "Provider Code is required",
  DEALER_CODE_MAX_LENGTH = "Length should be less than or equal to 10",
  DEALER_CODE_REQUIRED = "Code is required",
  DEALER_NAME_MAX_LENGTH = "Length should be less than or equal to 50",
  DEALER_NAME_REQUIRED = "Dealer name is required",
  FEEDBACK_REQUIRED = "Feedback is required",
  FEEDBACK_MAX_LENGTH = "Length should be less than or equal to 1000",
  PRODUCT_NAME_REQUIRED = "Product Name is required",
  PRODUCT_NAME_LENGTH = "Length should be less than or equal to 25",
  COUNTRY_REQUIRED = "Country is required",
  DEFAULT_RADIUS = "Default search radius is required",
  ANNUAL_MILEAGE_FROM_REQUIRED = "Annual Mileage From is required",
  FINANCED_AMOUNT_FROM_REQUIRED = "Financed Amount From is required",
  ANNUAL_MILEAGE_TO_REQUIRED = "Annual Mileage To is required",
  FINANCED_AMOUNT_TO_REQUIRED = "Financed Amount To is required",
  ANNUAL_MILEAGE_FROM_LESS_THAN_ANNUAL_MILEAGE_TO = "Annual Mileage From should be less than Annual Mileage To",
  FINANCED_AMOUNT_FROM_LESS_THAN_FINANCED_AMOUNT_TO = "Financed Amount From should be less than Financed Amount To",
  ANNUAL_MILEAGE_TO_GREATER_THAN_ANNUAL_MILEAGE_FROM = "Annual Mileage To should be greater than Annual Mileage From",
  FINANCED_AMOUNT_TO_GREATER_THAN_FINANCED_AMOUNT_FROM = "Financed Amount To should be greater than Financed Amount From",
  MINIMUM_FINANCED_AMOUNT_IS_REQUIRED = "Minimum Financed Amount is required",
  NAME_UNIQUE = "Name should be unique",
  COMMISSION_TYPE_REQUIRED = "Commission type is required",
  AMORTIZATION_METHOD_REQUIRED = "Amortization method is required",
  COMMISSION_OVERRIDE_REQUIRED = "Commission override is required",
  CALCULATION_METHOD_REQUIRED = "Calculation method is required",
  TAX_AMORTIZATION_REQUIRED = "Tax Amortization is required",
  SUBSIDY_OVERRIDE_REQUIRED = "Subsidy override is required",
  APPLICABLE_FINANCE_TYPE_REQUIRED = "Applicable finance type is required",
  SUM_SHOULD_NO_BE_GREATER_THAN_100 = "Sum of Commission Split should not be greater than 100%",
  SUM_SHOULD_NO_BE_LESS_THAN_100 = "Sum of Commission Split should not be less than 100%",
  SUM_SHOULD_BE_100 = "Sum of Commission Split should be 100%",
  COMMISSION_SPLIT_MESSAGE = "Please provide a value against at least one role",
  PURCHASE_OPTION_APPLICABLE_REQUIRED = "Purchase Option Applicable is required",
  PURCHASE_OPTION_TYPE_REQUIRED = "Purchase Option Type is required",
  DATE_TO_SHOULD_BE_GREATER_THAN_DATE_FROM = "Date to should be greater then date from",
  PLACEHOLDER_REQUIRED = "Placeholder is required",
  LANGUAGE_REQUIRED = "Language is required",
  STATIONERY_NAME = "Name is required",
  STATIONERY_EVENT = "Event is required",
  STATIONERY_TYPE = "Type is required",
  STATIONERY_RECIPIENT = "Recipient is required",
  SIGNATURE_REQUIRED = "Signature is required",
  REQUIRED_SIGNATORIES = "Field is required",
  SIGNATURE_TYPE = "Signature type is required",
  SIGNING_ORDER = "Signing Order is required",
  SIGNING_ACTION = "signing Action is required",
  TEMPLATE_REQUIRED = "Template is required",
  VIN_REQUIRED = "VIN is reqiuired",
  YEAR_REQUIRED = "Year is reqiuired",
  STOCK_NUMBER_REQUIRED = "Stock Number is reqiuired",
  SELLING_PRICE_REQUIRED = "Selling Price is reqiuired",
  MSRP_REQUIRED = "MSRP is reqiuired",
  MILEAGE_REQUIRED = "Mileage is reqiuired",
  EXTERNAL_CODE_MAX_LENGTH = "Length should be less than or equal to 5",
  EXTERNAL_CODE_REQUIRED = "External Code is required",
  CUSTOM_LINK_TYPE_REQUIRED = "Custom link type is required",
  CUSTOM_LINK_NAME_REQUIRED = "Custom link name is required",
  CUSTOM_LINK_URL_REQUIRED = "Custom link url is required",
  CREDIT_DECISION_REQUIRED = "Credit Decision Expiry In Days is required",
  LENDER_NAME = "Lender Name is required",
  MILEAGE_UNIT = "Mileage unit is required"
}
