import { Theme } from "@mui/material";
import {
  Button,
  Grid,
  Input,
  Box,
  Icon,
  FileDragDrop,
  IBoxProps,
  Tooltip,
  CircleLoader,
  FilePreview,
} from "@ntpkunity/controls";
import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useStoreContext } from "Stores/SetupStore/Store";
import {
  UseMutation_uploadS3Image,
  UseQuery_getThemeBuilderbyEmail,
  useMutation_CheckS3Credentials,
  UseMutation_deleteS3Image,
  useMutation_ThemeBuilderByEmail,
} from "services";
import { useQueryClient } from "react-query";
import { ActionTypes, QueryKeys } from "Enums";
import { Switch } from "@sharedComponents";
import { DEFAULT_FAVICON } from "shared/config/constants";
const UserProfileWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  // display: "table",
  // margin: "10vh auto",
  // maxWidth: 464,
  width: "100%",
  display: "inline-block",
  marginBottom: 32,
  ".left-section": {
    borderRight: "1px solid" + theme.palette.grey[100],
    paddingRight: 32,
    ".profile": {
      maxWidth: 464,
      margin: "auto",
      marginTop: 80,
      ".section": {
        marginBottom: 40,
        paddingBottom: 32,
        borderBottom: "1px solid" + theme.palette.divider,
        display: "inline-block",
      },
    },
  },

  ".u-custom-file-upload": {
    marginBottom: 0,
    ".root-drag-file": {
      height: 261,
    },
  },
  ".u-form-group": {
    ".MuiInputBase-root": {
      ".startAdornment": {
        span: {
          opacity: "60%",
        },
      },
      ".color-picker": {
        cursor: "pointer",
        ".color": {
          display: "block",
          width: 16,
          height: 16,
          borderRadius: 4,
        },
      },
    },
  },

  ".toggle-bar": {
    backgroundColor: theme.customVariables.tableStripedBg,
    borderRadius: theme.shape.borderRadius,
    padding: 16,
    width: "100%",
    display: "inline-block",
  },

  "@media screen and (max-width: 960px)": {
    ".left-section": {
      paddingLeft: 0,
      borderRight: "none",
      borderBottom: "1px solid" + theme.palette.grey[100],
      ".profile": {
        marginBottom: 32,
        maxWidth: "100%",
      },
    },
    ".u-custom-file-upload": {
      marginBottom: 0,
      ".root-drag-file": {
        height: 261,
      },
    },
  },
}));

const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "absolute !important",
      transform: "initial !important",
      top: "50%",
      left: "50%",
    },
  },
}));

const CustomImageUploader = styled("div")(({ theme }) => ({
  marginBottom: 24,
  position: "relative",
  ".img-wrap": {
    width: "100%",
    height: 262,
    display: "flex",
    verticalAlign: "middle",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    border: "2px dashed" + theme.palette.grey[100],
    overflow: "hidden",

    img: {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "auto",
      height: "auto",
      display: "block",
      margin: "auto",
    },

    ".btn-close": {
      backgroundColor: theme.palette.common.white,
      padding: "5px !important",
      position: "absolute",
      top: 10,
      right: 10,
      height: 26,
      width: 26,
      border: "1px solid" + theme.palette.grey[100],
      borderRadius: "100%",
      "svg path": {
        stroke: theme.palette.grey[300],
      },
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
}));

interface IThemeProps {
  theme: Theme;
  value?: any;
  form: any;
  fontFiles?: any[];
  setFontFiles?: any;
  defaultSwitch?: boolean;
  setDealerThemeBuilder?: any;
  showFontFileControl: boolean;
}
const ThemeBuilderLayout: React.FC<IThemeProps> = ({
  theme,
  value,
  form,
  fontFiles,
  setFontFiles,
  defaultSwitch,
  setDealerThemeBuilder,
  showFontFileControl,
}) => {
  const {
    control,
    formState: { isDirty, errors },
    setValue,
    trigger,
    watch,
    getValues,
  } = form;
  const queryClient = useQueryClient();
  const LenderProfileData: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const { data } = UseQuery_getThemeBuilderbyEmail(LenderProfileData?.email);
  const { mutate: getLenderTheme } = useMutation_ThemeBuilderByEmail();
  const { actions } = useStoreContext();
  const [primaryColor, setPrimaryColor] = useState(value.primary_color);
  const [secondaryColor, setSecondaryColor] = useState(value.primary_color);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const { mutate: deleteImage } = UseMutation_deleteS3Image();
  const { mutate: uploadImage } = UseMutation_uploadS3Image();
  const settings: any = localStorage?.getItem("settings");
  const parsedSettingsObject = JSON.parse(settings);
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  useEffect(() => {
    checkS3Credentials(parsedSettingsObject?.tenant_id);
  }, []);

  const onimageUpload = (e: any, name: any) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const obj: any = {
        tenant_id: parsedSettingsObject?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response: any) {
          setValue(name, { location: response.data[0].presigned_url });
          setValue(name + "_url", {
            url: response.data[0].presigned_url,
            key: response.data[0].key,
          });
        },
      });
    }
  };

  const onimageDrop = (e: any, name: any) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const obj: any = {
        tenant_id: parsedSettingsObject?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response: any) {
          setValue(name, response.data[0].presigned_url);
          setValue(name + "_url", {
            url: response.data[0].presigned_url,
            key: response.data[0].key,
          });
        },
      });
    }
  };

  const onFileChange = (
    event: any,
    type: "theme.favicon" | "theme.logo" | "theme.font_file"
  ) => {
    const files: File[] = Array.from(
      (event?.dataTransfer ?? event?.target).files
    );
    if (type === "theme.font_file") {
      const allowedFormats = [".woff", ".woff2", ".eot", ".ttf"];
      const validFiles = files.filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        return allowedFormats.includes(`.${fileExtension}`);
      });
      if (validFiles.length < files.length) {
        actions.setToast({
          toastMessage:
            "Please upload files with valid formats: .woff, .woff2, .eot, .ttf",
          toastState: true,
          variant: "error",
          showIcon: true,
        });
        return;
      }
      const currentFileCount = fontFiles?.length || 0;
      const newFileCount = validFiles.length;
      if (currentFileCount + newFileCount > 4) {
        actions.setToast({
          toastMessage:
            "Please remove existing font files before uploading new ones.",
          toastState: true,
          variant: "error",
          showIcon: true,
        });
        return;
      }
      const updatedFiles = validFiles.map((file) => ({
        name: file.name,
        key: file.name,
      }));
      setFontFiles((prevFiles) => [...(prevFiles || []), ...updatedFiles]);
      setValue("theme.font_file", [...(fontFiles || []), ...updatedFiles], {
        shouldDirty: true,
      });
    } else {
      setValue(type, files[0], { shouldDirty: true });
    }
  };

  const isValidUrl = (url: any) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return urlPattern.test(url);
  };

  const logoImage = (image: any) => {
    let image_url;
    const validUrl = isValidUrl(image);
    if (!validUrl) {
      image_url = URL.createObjectURL(image as unknown as File);
    }
    const url = validUrl ? image : image_url ?? "";
    return url;
  };
  const hasFavicon = watch("theme.favicon");
  const hasLogo = watch("theme.logo");

  const handleButtonClick = async (stylesheetlink: any) => {
    const s3FileLink = stylesheetlink;

    const response = await fetch(s3FileLink, {
      mode: "cors",
      method: "GET",
    });

    const cssContent = await response.text();
    const primaryColorRegex = /--primary:\s*#?([a-fA-F0-9]+)\b/;
    const secondaryColorRegex = /--secondary:\s*#?([a-fA-F0-9]+)\b/;

    // Extract primary and secondary color values from the CSS string
    const primaryColorMatch = cssContent.match(primaryColorRegex);
    const secondaryColorMatch = cssContent.match(secondaryColorRegex);
    // const matches = cssContent.match(colorRegex);
    const primaryColor = primaryColorMatch ? primaryColorMatch[1].trim() : null;
    const secondaryColor = secondaryColorMatch
      ? secondaryColorMatch[1].trim()
      : null;

    setValue("theme.primary_color", primaryColor);
    setValue("theme.secondary_color", secondaryColor);
    return { primaryColor: primaryColor, secondaryColor: secondaryColor };
  };
  const lenderTheme = (event) => {
    if (event.target.checked == true && data) {
      setValue("theme.is_company_theme_active", true, {
        shouldDirty: true,
      });
      getLenderTheme(
        { user_email: LenderProfileData?.email },
        {
          onSuccess(response: any) {
            updateFormValues(response?.data);
          },
        }
      );
    } else {
      setValue("theme.is_company_theme_active", false, {
        shouldDirty: true,
      });
    }
  };
  const updateFormValues = (response: any) => {
    setValue("theme.stylesheet", response.stylesheet);
    setValue("theme.favicon", response.favicon);
    setValue("theme.logo", response.logo);
    setValue("theme.font_file", response.font_file);
    setValue("theme.user_email", response.user_email);
    handleButtonClick(data.stylesheet);
  };

  const onImageUpload = (e: any, name: any) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      const currentFileCount = fontFiles?.length || 0;
      const newFileCount = e.target.files.length;
      if (currentFileCount + newFileCount > 4) {
        actions.setToast({
          toastMessage:
            "Please remove existing font files before uploading new ones.",
          toastState: true,
          variant: "error",
          showIcon: true,
        });
        return;
      }
      const obj: any = {
        tenant_id: parsedSettingsObject?.tenant_id,
      };
      const body = new FormData();
      const allowedFormats = [".woff", ".woff2", ".eot", ".ttf"];
      const invalidFiles = Array.from(e.target.files).filter((file: File) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        return !allowedFormats.includes(`.${fileExtension}`);
      });
      if (invalidFiles.length > 0) {
        actions.setToast({
          toastMessage:
            "Please upload files with valid formats: .woff, .woff2, .eot, .ttf",
          toastState: true,
          variant: "error",
        });
        return;
      }
      Array.from(e.target.files).forEach((file: File) => {
        body.append("files", file);
      });
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response: any) {
          const uploadedFiles = response.data.map((fileData: any) => ({
            url: fileData.presigned_url,
            key: fileData.key,
            name: fileData.name,
          }));
          const updatedFiles = [...fontFiles, ...uploadedFiles];
          setFontFiles(updatedFiles);
          setValue(name + "_url", updatedFiles, { shouldDirty: true });
        },
      });
    }
  };

  const onRemoveFile = (fileKey: string, index: number) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
      };
      setImageLoader(true);
      deleteImage(obj, {
        onSuccess(response) {
          const updatedFontFiles = fontFiles.filter((_, i) => i !== index);
          setFontFiles(updatedFontFiles);
          setValue("theme.font_file", updatedFontFiles, { shouldDirty: true });
          setImageLoader(false);
        },
        onError(error) {
          setImageLoader(false);
        },
      });
    }
  };

  const handleTooltipText = () => {
    return (
      <span>
        To change fonts, upload at least one file for each of the following
        formats:
        <br />
        - TrueType Fonts (TTF)
        <br />
        - Web Open Font Format (WOFF)
        <br />
        - Web Open Font Format (WOFF 2.0)
        <br />- Embedded OpenType Fonts (EOT)
      </span>
    );
  };

  return (
    <>
      <form>
        <UserProfileWrap theme={theme}>
          {defaultSwitch && (
            <Box theme={theme} className="toggle-bar" sx={{ mb: 3 }}>
              <Controller
                name="theme.is_company_theme_active"
                control={control}
                defaultValue={true}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    varient="basic"
                    theme={theme}
                    label="Use company personalisation"
                    switchEnabled={value}
                    onChange={(event) => {
                      lenderTheme(event);
                    }}
                  />
                )}
              />
            </Box>
          )}
          <Grid theme={theme} container item spacing={3} mb={2}>
            <Grid theme={theme} item xs>
              <Controller
                name="theme.primary_color"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Primary Color is Required",
                  minLength: {
                    value: 3,
                    message: "Please provide valid hex code",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    theme={theme}
                    fullWidth={true}
                    label={"Primary Color"}
                    type="text"
                    placeholder="00249C"
                    value={value == null ? primaryColor : value?.trimStart()}
                    onChange={onChange}
                    disabled={watch("theme.is_company_theme_active")}
                    startAdornment={
                      <>
                        <Box theme={theme} className="startAdornment">
                          <span>#</span>
                        </Box>
                      </>
                    }
                    endAdornment={
                      <>
                        <Box theme={theme} className="color-picker">
                          <Box
                            theme={theme}
                            className="color"
                            sx={{
                              backgroundColor: `#${watch(
                                "theme.primary_color"
                              )}`,
                            }}
                          ></Box>
                        </Box>
                      </>
                    }
                    error={errors?.theme?.primary_color?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs>
              <Controller
                name="theme.secondary_color"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Secondary Color is Required",
                  minLength: {
                    value: 3,
                    message: "Please provide valid hex code",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    theme={theme}
                    fullWidth={true}
                    label={"Secondary Color"}
                    type="text"
                    value={value == null ? secondaryColor : value?.trimStart()}
                    onChange={onChange}
                    placeholder="5F5F5F"
                    disabled={watch("theme.is_company_theme_active")}
                    startAdornment={
                      <>
                        <Box theme={theme} className="startAdornment">
                          <span>#</span>
                        </Box>
                      </>
                    }
                    endAdornment={
                      <>
                        <Box theme={theme} className="color-picker">
                          <Box
                            theme={theme}
                            className="color"
                            sx={{
                              backgroundColor: `#${watch(
                                "theme.secondary_color"
                              )}`,
                            }}
                          ></Box>
                        </Box>
                      </>
                    }
                    error={errors?.theme?.secondary_color?.message?.toString()}
                  />
                )}
              />
            </Grid>
            {showFontFileControl && <Grid theme={theme} item xs />}
          </Grid>
          <Grid theme={theme} container item spacing={3}>
            <Grid theme={theme} item xs>
              {hasFavicon ? (
                <CustomImageUploader>
                  <Box theme={theme} className="img-wrap">
                    <Button
                      onClick={() =>
                        setValue("theme.favicon", "", {
                          shouldDirty: true,
                        })
                      }
                      disabled={
                        watch("theme.is_company_theme_active") ||
                        !s3Response?.data
                      }
                      theme={theme}
                      className="btn-close"
                      iconText={<Icon name="CloseBlack" />}
                    />
                    {typeof hasFavicon == "object" ||
                    typeof hasFavicon == "string" ? (
                      <img
                        src={
                          typeof hasFavicon == "object"
                            ? (getValues("theme.favicon").location as any)
                            : getValues("theme.favicon")
                        }
                        alt="FaviconImage"
                      />
                    ) : (
                      <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                        <CircleLoader theme={theme} size="xs" />
                      </ImgLoader>
                    )}
                  </Box>
                </CustomImageUploader>
              ) : (
                <>
                  <Controller
                    name="theme.favicon"
                    control={control}
                    defaultValue={`${
                      process.env.DMS_BUCKET_BASE_URL + DEFAULT_FAVICON
                    }`}
                    rules={{
                      validate: (_value) => {
                        if (
                          !watch("theme.favicon") ||
                          watch("theme.favicon") == ""
                        ) {
                          return "Favicon is required";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <Tooltip
                        theme={theme}
                        title={
                          !s3Response?.data
                            ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                            : ""
                        }
                      >
                        <FileDragDrop
                          {...field}
                          theme={theme}
                          id="favicon_id"
                          hoverLabel="Drag and drop favicon image file, or browse"
                          onChange={(e) => {
                            onFileChange(e, "theme.favicon");
                            onimageUpload(e, "theme.favicon");
                          }}
                          onDrop={(e) => {
                            onFileChange(e, "theme.favicon");
                            onimageDrop(e, "theme.favicon");
                          }}
                          error={errors?.theme?.favicon?.message?.toString()}
                          disabled={
                            watch("theme.is_company_theme_active") ||
                            !s3Response?.data
                          }
                        />
                      </Tooltip>
                    )}
                  />
                </>
              )}
            </Grid>
            <Grid theme={theme} item xs>
              {hasLogo ? (
                <CustomImageUploader>
                  <Box theme={theme} className="img-wrap">
                    <Button
                      onClick={() =>
                        setValue("theme.logo", "", {
                          shouldDirty: true,
                        })
                      }
                      theme={theme}
                      className="btn-close"
                      disabled={
                        watch("theme.is_company_theme_active") ||
                        !s3Response?.data
                      }
                      iconText={<Icon name="CloseBlack" />}
                    />
                    {typeof hasLogo == "object" ||
                    typeof hasLogo == "string" ? (
                      <img
                        src={
                          typeof hasLogo == "object"
                            ? (getValues("theme.logo").location as any)
                            : getValues("theme.logo")
                        }
                        alt="CloseIcon"
                      />
                    ) : (
                      <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                        <CircleLoader theme={theme} size="xs" />
                      </ImgLoader>
                    )}
                  </Box>
                </CustomImageUploader>
              ) : (
                <>
                  <Controller
                    name="theme.logo"
                    control={control}
                    rules={{
                      validate: (_value) => {
                        if (!watch("theme.logo") || watch("theme.logo") == "") {
                          return "Logo is required";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <Tooltip
                        theme={theme}
                        title={
                          !s3Response?.data
                            ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                            : ""
                        }
                      >
                        <FileDragDrop
                          {...field}
                          theme={theme}
                          id="logo_id"
                          hoverLabel="Drag and drop logo image file, or browse"
                          onChange={(e) => {
                            onFileChange(e, "theme.logo");
                            onimageUpload(e, "theme.logo");
                          }}
                          onDrop={(e) => {
                            onFileChange(e, "theme.logo");
                            onimageDrop(e, "theme.logo");
                          }}
                          error={errors?.theme?.logo?.message}
                          disabled={
                            watch("theme.is_company_theme_active") ||
                            !s3Response?.data
                          }
                        />
                      </Tooltip>
                    )}
                  />
                </>
              )}
            </Grid>
            {showFontFileControl && (
              <Grid theme={theme} item xs>
                <Controller
                  name="theme.font_file"
                  control={control}
                  render={({ field }) => (
                    <Tooltip
                      theme={theme}
                      title={
                        !s3Response?.data
                          ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                          : handleTooltipText()
                      }
                    >
                      <FileDragDrop
                        {...field}
                        theme={theme}
                        id="font_id"
                        accept=".woff,.woff2,.eot,.ttf"
                        hoverLabel="Drag and drop font file, or browse"
                        onChange={(e) => {
                          onFileChange(e, "theme.font_file");
                          onImageUpload(e, "theme.font_file");
                        }}
                        onDrop={(e) => {
                          onFileChange(e, "theme.font_file");
                          onimageDrop(e, "theme.font_file");
                        }}
                        allowMultiple={true}
                        disabled={
                          !s3Response?.data
                        }
                      />
                    </Tooltip>
                  )}
                />

                {fontFiles?.length > 0 && (
                  <>
                    {fontFiles.map((file, index) =>
                      file.key ? (
                        <React.Fragment key={file.key}>
                          <FilePreview
                            theme={theme}
                            files={[{ ...file, name: file.name || file.key }]}
                            onRemoveFile={() => onRemoveFile(file.key, index)}
                          />
                        </React.Fragment>
                      ) : null
                    )}
                  </>
                )}
              </Grid>
            )}
            {/* <Grid theme={theme} item xs={12}>
          <Button
            theme={theme}
            primary
            fullWidth
            text={'Save Changes'}
            disabled={!isDirty}
            onClick={handleSubmit(onSave)}
          />
        </Grid> */}
          </Grid>
        </UserProfileWrap>
      </form>
    </>
  );
};

export default ThemeBuilderLayout;
