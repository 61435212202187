import { useMutation, useQueryClient, useQuery } from "react-query";
import Http from "shared/helper/http-api";
import { IDealer, IDealerFilter } from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ConfigurationAPI, UmsAPI } from "services";
import { QueryKeys, ToastMessages } from "Enums";
import { EP_GET_DEALER_BY_EMAIL } from "shared/config/endpoints";
const access_token_local = JSON.parse(
  localStorage.getItem("settings")
)?.access_token;
const getAllDealersFilterFromServer = (columnFilters?: string) => {
  return ConfigurationAPI.get(`/dealers/filter?${columnFilters}`).then(
    (response) => {
      return response.data;
    }
  );
};

export const useQuery_GetAllDealers = () => {
  return useQuery(
    QueryKeys.GET_ALL_DEALERS,
    async () => {
      return ConfigurationAPI.get("/dealer").then((response) => {
        return response.data;
      });
    },
    { refetchOnMount: true }
  );
};

export const useGetDealersFilter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_DEALER_FILTER, columnFilters],
    async () => {
      return getAllDealersFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseQuery_GetActiveDealers = () => {
  return useQuery(QueryKeys.GET_ACTIVE_DEALERS, async () => {
    return ConfigurationAPI.get("/dealers/active").then((response) => {
      return response.data;
    });
  });
};

export const useGetActiveDealersFilter = (): any => {
  const queryClient = useQueryClient();
  return useMutation<any>(
    QueryKeys.GET_ACTIVE_DEALERS,
    (params: any) => {
      let queryString = "";
      if (params?.name && params.name != "") {
        queryString = queryString.concat(`name=${params.name}&`);
      }
      if (params?.code && params.code != "") {
        queryString = queryString.concat(`code=${params.code}`);
      }

      return ConfigurationAPI.get(`/dealers/active?${queryString}`).then(
        (response) => {
          return response.data;
        }
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.setQueryData([QueryKeys.GET_ACTIVE_DEALERS], response);
      },
    }
  );
};

export const useGetDealerById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      return ConfigurationAPI.get(`/dealers/${body.id}`);
    },
    {
      onSuccess() {},
    }
  );

  return { data, isLoading, mutate };
};

export const useGetDealerByEmail = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection();
      let params = body.data ? `${body.data}` : "";
      return apiService.get<any>(
        params ? `${EP_GET_DEALER_BY_EMAIL}?${params}` : EP_GET_DEALER_BY_EMAIL
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useSaveDealer = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IDealer>(
    (dealer) => {
      return ConfigurationAPI.post("/dealers", dealer);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ACTIVE_DEALERS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALERS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const useUpdateDealerById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();

  return useMutation<IDealerFilter>(
    (dealer: any) => {
      return ConfigurationAPI.put(`/dealers/${dealer.id}`, dealer);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ACTIVE_DEALERS);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALERS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const useDeleteDealerById = (): any => {
  const queryClient = useQueryClient();
  return useMutation<IDealerFilter>(
    (body: any) => {
      return ConfigurationAPI.delete(`/dealers/${body.id}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ACTIVE_DEALERS);
      },
    }
  );
};

export const useCreateUserService = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    async (body: any) => {
      let promiseResult: any;
      await UmsAPI.post<any>(`/ums/um/create-user`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token_local}`,
        },
      }).then((response) => {
        promiseResult = response;
      });
      return promiseResult;
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
export const useUpdateUserService = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    async (body: any) => {
      let promiseResult: any;
      await UmsAPI.patch<any>(
        `/ums/um/update-list-user/${body.user_id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token_local}`,
          },
        }
      ).then((response) => {
        promiseResult = response;
      });
      return promiseResult;
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetProductID = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    async (body: any) => {
      let promiseResult: any;
      await UmsAPI.get<any>(
        `/signup/get-products-by-name/${body.product_name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token_local}`,
          },
        }
      ).then((response) => {
        promiseResult = response;
      });
      return promiseResult;
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useGetRoleID = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    async (body: any) => {
      let promiseResult: any;
      await UmsAPI.get<any>(`/ums/roles/${body.role_name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token_local}`,
        },
      }).then((response) => {
        promiseResult = response;
      });
      return promiseResult;
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteUserById = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation<any>(
    async (body: any) => {
      let promiseResult: any;
      await UmsAPI.delete<any>(`/ums/um/ums_user/${body.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token_local}`,
        },
      }).then((response) => {
        promiseResult = response;
      });
      return promiseResult;
    },
    {
      onSuccess: async (response: any) => {
        // queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useGetUserById = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    async (body: any) => {
      let promiseResult: any;
      await UmsAPI.get<any>(`/ums/users/${body.user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token_local}`,
        },
      }).then((response) => {
        promiseResult = response;
      });
      return promiseResult;
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
