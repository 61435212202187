import { FC, useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme, TablePagination } from "@mui/material";
import { Icon, Menu, DataTable, Button, Input, Box } from "@ntpkunity/controls";
import { ConfirmationDialog, PaginationWrap, Switch } from "@sharedComponents";
import ChevronDown from "../../shared/assets/images/chevron-down";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "shared/config/links";
import {
  UseMutation_UpdateProgram,
  UseQuery_getAllPrograms,
  UseMutation_DeleteProgram,
  UseMutation_UpdateFpStatus,
  UseQuery_getAllPrograms_filter,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import {
  ActionOptions,
  DialogMessages,
  QueryKeys,
  ToastMessages,
  ProgramState,
} from "Enums";
import { IProgram, IProgramFilter } from "Interface";
import { useQueryClient } from "react-query";
import { useHasPermissions } from "@ntpkunity/controls-ums";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="IcView" />
        {ActionOptions.VIEW}
      </>
    ),
    optionkey: ActionOptions.VIEW,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.SAVE_AS}
      </>
    ),
    optionkey: ActionOptions.SAVE_AS,
    optionValue: {},
  },
];

const ProgramsTable: FC = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );
  const status_permission = useHasPermissions("program-status-column");
  const { data } = UseQuery_getAllPrograms_filter(columnFilters);
  const [state, dispatch] = useSetupsStore();
  const [productNameFilter, setProductNameFilter] = useState("");
  const [productIDFilter, setProductIDFilter] = useState("");
  const [financeTypesFilter, setFinanceTypesFilter] = useState("");
  const [validityFilter, setValidityFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [programStateFilter, setProgramStateFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (
      productNameFilter.trimStart() != null &&
      productNameFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&name=${productNameFilter}`);
    }
    if (
      productIDFilter.trimStart() != null &&
      productIDFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&financial_product_code=${productIDFilter}`
      );
    }
    if (
      financeTypesFilter.trimStart() != null &&
      financeTypesFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&finance_type=${financeTypesFilter}`);
    }
    if (
      validityFilter.trimStart() != null &&
      validityFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&validity_start_date=${validityFilter}`
      );
    }
    if (
      validityFilter.trimStart() != null &&
      validityFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&validity_end_date=${validityFilter}`
      );
    }
    if (
      programStateFilter.trimStart() != null &&
      programStateFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&program_state=${programStateFilter.toLowerCase()}`
      );
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const navigate = useNavigate();
  const { Programs } = state;
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [financialProductsData, setFinancialProductsData] = useState(Programs);

  useEffect(() => {
    setFinancialProductsData(Programs);
  }, [Programs]);

  const queryClient = useQueryClient();
  const programsFilter: IProgramFilter = data;
  const { actions } = useStoreContext();
  const { mutate: UpdateFpStatus } = UseMutation_UpdateFpStatus();
  const [actionData, setActionData] = useState<IProgram>(undefined);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const { mutate: deleteFinancialProduct } = UseMutation_DeleteProgram();

  const onDeleteConfirm = (e: any) => {
    deleteFinancialProduct(actionData.financial_product_general?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
        //Show Message on Success
      },
    });
  };

  const handleEnableChange = (data, event) => {
    let arr: Array<number> = [];
    data?.dealers?.map((x) => {
      arr.push(x.id);
    });
    let updatedData = {
      ...data,
      dealers: arr,
      is_active: event.target.checked,
    };
    UpdateFpStatus(updatedData);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        let fp: IProgram;
        if (value?.is_draft) {
          key = ActionOptions.SAVE_AS_DRAFT;
        }
        return navigate(APP_ROUTES.Program + "/" + value?.id, {
          state: key,
        });
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
      case ActionOptions.VIEW:
        return navigate(APP_ROUTES.Program + "/" + value?.id, {
          state: key,
        });
      case ActionOptions.SAVE_AS:
        if (key == ActionOptions.SAVE_AS) {
          setActionType(ActionOptions.SAVE_AS);
          return navigate(APP_ROUTES.Program + "/" + value?.id, {
            state: key,
          });
        }
      default:
        return;
    }
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell>Program Name</TableCell>
              <TableCell>Program ID</TableCell>
              <TableCell>Finance Type</TableCell>
              <TableCell>Validity</TableCell>
              <TableCell>State</TableCell>
              {status_permission && <TableCell>Status</TableCell>}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="productName"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setProductNameFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={productNameFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="productId"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setProductIDFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={productIDFilter?.trimStart()}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="FinanceType"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setFinanceTypesFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={financeTypesFilter?.trimStart()}
                  ></Input>
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="validity"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setValidityFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={validityFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id={"tableProgramState"}
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setProgramStateFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={programStateFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              {status_permission && (
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="tablePromgramState"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setStatusFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={statusFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
              )}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          programsFilter?.result &&
          programsFilter.result?.map((financialProduct, index) => (
            <TableRow key={index} className="child-tr">
              <TableCell>{financialProduct.name}</TableCell>
              <TableCell>{financialProduct.financial_product_code}</TableCell>
              <TableCell>{financialProduct.finance_type}</TableCell>
              <TableCell>
                {financialProduct.validity_start_date ? (
                  <>
                    {financialProduct.validity_start_date
                      ?.toString()
                      .slice(5, 7)}
                    /
                    {financialProduct.validity_start_date
                      ?.toString()
                      .slice(8, 10)}
                    /
                    {financialProduct.validity_start_date
                      ?.toString()
                      .slice(0, 4)}{" "}
                    {financialProduct.validity_end_date ? (
                      <>
                        -{" "}
                        {financialProduct.validity_end_date
                          ?.toString()
                          .slice(5, 7)}
                        /
                        {financialProduct.validity_end_date
                          ?.toString()
                          .slice(8, 10)}
                        /
                        {financialProduct.validity_end_date
                          ?.toString()
                          .slice(0, 4)}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell>
                {financialProduct.program_state === "ReadytoPublish" ? (
                  <>
                    <span className="status-indicator ind-info"></span>
                    Ready to Publish
                  </>
                ) : financialProduct.program_state === "Draft" ? (
                  <>
                    <span className="status-indicator ind-danger"></span>
                    Draft
                  </>
                ) : (
                  <>
                    <span className="status-indicator ind-success"></span>
                    Published
                  </>
                )}
              </TableCell>
              {status_permission && (
                <TableCell>
                  <Switch
                    id={"switch" + financialProduct.name}
                    theme={theme}
                    varient={"basic"}
                    label={
                      financialProduct?.program_state ===
                        ProgramState.Draft.toString() ||
                      financialProduct?.program_state ===
                        ProgramState.ReadytoPublish.toString()
                        ? "Disabled"
                        : financialProduct.is_active
                        ? "Enabled"
                        : "Disabled"
                    }
                    switchEnabled={
                      financialProduct?.program_state ===
                        ProgramState.Draft.toString() ||
                      financialProduct?.program_state ===
                        ProgramState.ReadytoPublish.toString()
                        ? false
                        : financialProduct.is_active
                    }
                    onChange={(event) => {
                      if (
                        financialProduct?.program_state ==
                        ProgramState.Draft.toString()
                      ) {
                        actions.setToast({
                          toastMessage: ToastMessages.DISABLE_TOGGLE,
                          toastState: true,
                        });
                      } else {
                        handleEnableChange(financialProduct, event);
                      }
                    }}
                  />
                </TableCell>
              )}
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={
                    financialProduct.is_draft
                      ? tableOptions
                          .filter((x) => x.optionkey !== ActionOptions.SAVE_AS)
                          .map((option) => {
                            return {
                              optionText: option.optionText,
                              optionkey: option.optionkey,
                              optionValue: financialProduct,
                            };
                          })
                      : tableOptions.map((option) => {
                          return {
                            optionText: option.optionText,
                            optionkey: option.optionkey,
                            optionValue: financialProduct,
                          };
                        })
                  }
                  handleOptionClick={handleSelection}
                  render={(onMenuSelection) => (
                    <Button
                      id={Math.random().toString(36).slice(2) + "Ellipses"}
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </TableCell>
            </TableRow>
          ))
        }
      />
      <PaginationWrap>
        <TablePagination
          id="pagination"
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={programsFilter ? programsFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default ProgramsTable;
