import { FC } from "react";
import { useTheme } from "@mui/material";
import { Grid, Select } from "@ntpkunity/controls";
import { CustomGrid } from "@sharedComponents";

import { IProgramCommission } from "./program-commission.type";
import { Controller } from "react-hook-form";
import { AmortizationMethod, CommissionType, CommissionOverride } from "Enums";

const ProgramCommission: FC<IProgramCommission> = ({ form, isDisabled }) => {
  const {
    control,
    formState: { errors },
  } = form;
  const theme = useTheme();
  return (
    <>
      <CustomGrid
        className="main-content-section"
        theme={theme}
        container
        item
        spacing={3}
      >
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_commission.commission_type"
            control={control}
            defaultValue={CommissionType.CRITERIA}
            render={({ field: { value, onChange } }) => (
              <Select
                id="CommissionType"
                theme={theme}
                label={"Commission Type"}
                placeholder={"Select"}
                items={[
                  {
                    text: CommissionType.CRITERIA,
                    value: CommissionType.CRITERIA,
                  },
                ]}
                value={CommissionType.CRITERIA}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.program_commission?.commission_type?.message
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_commission.amortization_method"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <Select
                id="AmortizationMethod"
                theme={theme}
                label={"Amortization Method"}
                placeholder={"Select"}
                items={[
                  {
                    text: AmortizationMethod.ANNUITY,
                    value: AmortizationMethod.ANNUITY,
                  },
                  {
                    text: AmortizationMethod.ANNUITY_ACTUAL_NUMBER_OF_DAYS,
                    value: AmortizationMethod.ANNUITY_ACTUAL_NUMBER_OF_DAYS,
                  },
                  {
                    text: AmortizationMethod.STRAIGHT_LINE,
                    value: AmortizationMethod.STRAIGHT_LINE,
                  },
                  {
                    text: AmortizationMethod.STRAIGHT_LINE_PRO_RATA,
                    value: AmortizationMethod.STRAIGHT_LINE_PRO_RATA,
                  },
                  {
                    text: AmortizationMethod.STRAIGHT_LINE_WITH_ACTUAL_NUMBER_OF_DAYS,
                    value:
                      AmortizationMethod.STRAIGHT_LINE_WITH_ACTUAL_NUMBER_OF_DAYS,
                  },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.program_commission?.amortization_method?.message
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_commission.commission_override"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <Select
                id="CommissionOverride"
                theme={theme}
                label={"Commission Override"}
                placeholder={"Select"}
                items={[
                  {
                    text: CommissionOverride.YES,
                    value: CommissionOverride.YES,
                  },
                  { text: CommissionOverride.NO, value: CommissionOverride.NO },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.program_commission?.commission_override?.message
                }
              />
            )}
          />
        </Grid>
      </CustomGrid>
    </>
  );
};
export default ProgramCommission;
