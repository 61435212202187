import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IStationeryFilter, IStationery } from "Interface";

export const UseQuery_getAllStationeryFilter = (columnFilters: string) => {
  return useQuery<IStationeryFilter>(
    [QueryKeys.GET_ALL_STATIONERY_RECORDS, columnFilters],
    async () => {
      return ConfigurationAPI.get(
        `/stationery/filter/?`.concat(columnFilters)
      ).then((response) => {
        return response.data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useMutation_getTenantDocuments = () => {
  return useMutation<any>(() => {
    return ConfigurationAPI.get(`/stationery/get_tenant_documents/`);
  });
};

export const UseMutation_AddStationery = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IStationery>(
    (stationery) => {
      return ConfigurationAPI.post("/stationery", stationery);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STATIONERY_RECORDS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: (error: any) => {
        actions.setToast({
          toastMessage:
            error?.response?.data?.detail?.message ?? "Internal Server Error",
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_UpdateStationery = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IStationery>(
    (stationery: any) => {
      return ConfigurationAPI.patch(`/stationery/${stationery.id}`, stationery);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STATIONERY_RECORDS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteStationery = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IStationery>(
    (stationeryId) => {
      return ConfigurationAPI.delete(`/stationery/${stationeryId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STATIONERY_RECORDS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_GetStationeryById = (): any => {
  return useMutation<IStationery>((id) => {
    return ConfigurationAPI.get(`/stationery/${id}`);
  });
};
