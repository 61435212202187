import { FC, useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Checkbox } from "@ntpkunity/controls";
import { Box } from "@sharedComponents";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  IFpAssetGroup,
  IImage,
} from "Interface";
import AssetTrimTable from "./asset-trim-table";
import { addFpAssetGroup, removeFpAssetGroup } from "Stores/SetupStore/Actions";
import { ActionOptions, QueryKeys } from "Enums";
import { useQueryClient } from "react-query";
import { useTheme } from "@mui/material";

interface AssetModelProps {
  assetModel: IAssetModel;
  actionType?: ActionOptions;
  setValue?: any;
  assetMake: IAssetMake;
  handleImage?: (data: IImage[]) => unknown;
}

const AssetModelTable: FC<AssetModelProps> = ({
  assetModel,
  actionType,
  setValue,
  assetMake,
  handleImage,
}) => {
  const [open2, setOpen2] = useState(false);
  const [state, dispatch] = useSetupsStore();
  const { fpAssetGroup } = state;
  const queryClient = useQueryClient();
  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const found = fpAssetGroup?.find(
      (element) =>
        element.asset_make_id == assetModel.make_id &&
        element.asset_model_id == assetModel.id
    );
    if (found) {
      setIsChecked(true);
      setOpen2(true);
    } else {
      setIsChecked(false);
    }
  }, [fpAssetGroup, isChecked]);

  useEffect(() => {
    if (actionType == ActionOptions.VIEW) {
      setIsDisabled(true);
    } else {
      assetModel?.is_active ? setIsDisabled(false) : setIsDisabled(true);
    }
  }, [actionType, isDisabled, assetModel]);

  const onCheckChange = (e: any) => {
    setValue("assetGroup", "undefined", { shouldDirty: true });
    if (e.target.checked == true) {
      const attachedMake = assetMakes?.find(
        (row) => row.id == assetModel.make_id && row.is_active
      );
      const obj: IFpAssetGroup = {
        asset_make_id: attachedMake.id,
        asset_model_id: assetModel.id,
      };

      const attachedModelTrims = assetTrims
        ?.filter((row) => row.model_id == assetModel.id && row.is_active)
        ?.map((trim) => {
          const trimobj: IFpAssetGroup = {
            asset_make_id: attachedMake.id,
            asset_model_id: assetModel.id,
            asset_trim_id: trim.id,
          };
          dispatch(addFpAssetGroup(trimobj));
        });
      if (attachedModelTrims?.length == 0) {
        dispatch(addFpAssetGroup(obj));
      }
    } else {
      let dataArr = fpAssetGroup;
      dataArr = dataArr.filter((x) => x.asset_model_id !== assetModel.id);
      dispatch(removeFpAssetGroup(dataArr));
    }
  };

  const theme = useTheme();

  return (
    <>
      <TableRow className="child-tr">
        <TableCell className="checkbox-cell fixed-cell">
          <Checkbox
            theme={theme}
            checkBoxDisabled={isDisabled}
            onChange={onCheckChange}
            checkBoxChecked={isChecked}
            label={""}
          />
        </TableCell>

        <TableCell className="indent-cell second-level-cell">
          <Box theme={theme} className="group-title">
            {open2 ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen2(!open2)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn "
                onClick={() => setOpen2(!open2)}
              />
            )}
            {assetModel.model_name}
          </Box>
        </TableCell>
        <TableCell className="img-cell"></TableCell>
        <TableCell>{assetModel.description}</TableCell>
        <TableCell>{assetModel.model_code}</TableCell>
      </TableRow>
      {open2 &&
        assetModel.asset_trims?.length > 0 &&
        assetModel.asset_trims
          .filter((x) => x.model_id === assetModel.id)
          .map((item, index) => (
            <AssetTrimTable
              setValue={setValue}
              key={index}
              assetTrim={item}
              assetModel={assetModel}
              assetMake={assetMake}
              actionType={actionType}
              handleImage={handleImage}
            />
          ))}
    </>
  );
};

export default AssetModelTable;
