import { FC, useEffect, useState } from "react";
import { useTheme, TablePagination } from "@mui/material";
import ChevronDown from "../../shared/assets/images/chevron-down";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  Menu,
  DataTable,
  Button,
  Switch,
  Box,
  Input,
} from "@ntpkunity/controls";
import { ConfirmationDialog, PaginationWrap } from "@sharedComponents";
import {
  UseMutation_DeleteDecisionStatus,
  UseMutation_UpdateDecisionStatus,
  UseQuery_getAllDecisionStatus_filter,
} from "services";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import {
  IDecisionStatus,
  IDecisionStatusData,
  IStipulation,
  IStipulationFilter,
} from "Interface";
import { useQueryClient } from "react-query";
import StipulationTable from "../Stipulation/stipulation-table";
import { styled } from "@mui/material/styles";
import { setOpenDrawer, setToast } from "Stores/SetupStore/Actions";
import { useStoreContext, useSetupsStore } from "Stores/SetupStore/Store";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="AddIcon" />
        {"Add New Stipulation"}
      </>
    ),
    optionkey: ActionOptions.ADD,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];
const tableMenu: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

interface IDecisionStatusTableProps {
  showCheckBox?: boolean;
  onEditDecisionStatus?: (data: IDecisionStatus) => unknown;
  onEditStipulation?: (data: IStipulation) => unknown;
}
const DecisionStatusTable: FC<IDecisionStatusTableProps> = ({
  onEditDecisionStatus,
  onEditStipulation,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const [popUpState, setPopUpState] = useState(false);
  useEffect(() => {}, [popUpState]);
  const { data } = UseQuery_getAllDecisionStatus_filter(columnFilters);
  const queryClient = useQueryClient();
  const DecisionStatusValue: any = data;
  const [actionData, setActionData] = useState<IDecisionStatus>(undefined);
  const { mutate: deleteDecisionStatus } = UseMutation_DeleteDecisionStatus();
  const { mutate: UpdateDecisionStatus } =
    UseMutation_UpdateDecisionStatus(undefined);
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [codeFilter, setCodeFilter] = useState("");
  const [orderStatusFilter, setOrderStatusFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (
      orderStatusFilter.trimStart() != null &&
      orderStatusFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&order_status=${orderStatusFilter}`);
    }
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&code=${codeFilter}`);
    }
    if (
      descriptionFilter.trimStart() != null &&
      descriptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&description=${descriptionFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEditDecisionStatus(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
    }
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };
  const onDeleteConfirm = (e: any) => {
    deleteDecisionStatus(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  const theme = useTheme();
  //let navigate = useNavigate();
  const handleEnableChange = (data: IDecisionStatus, event) => {
    let updatedData = {
      ...data,
      is_active: event.target.checked,
    };
    UpdateDecisionStatus(updatedData);
  };

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow className="thead-items-cell">
              {/* <TableCell className="checkbox-cell fixed-cell">
                  <Checkbox label="" theme={theme} />
                </TableCell> */}
              <TableCell id={"headerCode"}>Response Code</TableCell>
              <TableCell id={"headerDescription"}>
                Response Description
              </TableCell>
              <TableCell id={"headerOrderStatus"}>Order Status</TableCell>
              <TableCell id={"headerStatus"}>Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              {/* <TableCell className="checkbox-cell fixed-cell"></TableCell> */}
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="searchCode"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setCodeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={codeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="searchDescription"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setDescriptionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={descriptionFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="searchOrderStatus"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setOrderStatusFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={orderStatusFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="searchStatus"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={DecisionStatusValue?.result?.map((decision) => (
          <TableRow key={decision?.id} className="child-tr">
            {/* <TableCell className="checkbox-cell fixed-cell">
                  <Checkbox label="" theme={theme} />
                </TableCell> */}
            <TableCell id={"listDataCode" + decision.code}>
              {" "}
              {decision.code}{" "}
            </TableCell>
            <TableCell id={"listDataDescription" + decision.code}>
              {decision.description}
            </TableCell>
            <TableCell id={"listDataOrderStatus" + decision.code}>
              {decision.order_status}
            </TableCell>
            <TableCell>
              <Switch
                id={"listDataSwitch" + decision.code}
                theme={theme}
                varient={"basic"}
                switchEnabled={decision.is_active}
                onChange={(event) => handleEnableChange(decision, event)}
                label={decision.is_active ? "Enabled" : "Disabled"}
              />
            </TableCell>
            <TableCell className="action-cell fixed-cell">
              <Menu
                theme={theme}
                options={tableMenu.map((option) => {
                  return {
                    optionText: option.optionText,
                    optionkey: option.optionkey,
                    optionValue: decision,
                  };
                })}
                handleOptionClick={handleSelection}
                render={(onMenuSelection) => (
                  <Button
                    id={"listDataEllipses" + decision.code}
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={onMenuSelection}
                  ></Button>
                )}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={DecisionStatusValue ? DecisionStatusValue?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default DecisionStatusTable;
