import { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TablePagination, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  Menu,
  DataTable,
  Button,
  Box,
  Grid,
  Typography,
  PersistentDrawer,
  Input,
  Switch,
} from "@ntpkunity/controls";
import { LayoutWithSideNav } from "layout";
import { DataTableWrap, DrawerWrap, PageHeader, PaginationWrap } from "@sharedComponents";
import ChevronDown from "../shared/assets/images/chevron-down";
import { AddNewLender } from "../components/AddNewLender/AddNewLender";
import {
  UseQuery_getAllLender_filter,
  UseMutation_UpdateLender,
  UseMutation_AddLender,
} from "services";
import { ILender, ILenderFilter } from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ActionOptions } from "../Enums/ActionOptions";

interface IFormInputs {
  id?: number;
  name: string;
  external_code: string;
  finance_type: string[];
  program: string;
  evaluation: string;
  e_contracting: boolean;
  minimum_finance_amount: number;
  default_lender: boolean;
  status: boolean;
}

const LenderProfileList: FC = () => {
  const [popUpState, setPopUpState] = useState(false);
  const [popUpOpenState, setPopUpOpenState] = useState("");
  const handleshow = () => {
    setPopUpState(true);
    form.reset({} as IFormInputs);
    setPopUpOpenState(ActionOptions.ADD);
  };
  useEffect(() => { }, [popUpState]);
  const { actions } = useStoreContext();

  const theme = useTheme();
  const form = useForm<IFormInputs>({ mode: "all" });
  const { getValues, reset, setValue, handleSubmit, setError } = form;
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data } = UseQuery_getAllLender_filter(columnFilters);
  const [lenderName, setLenderNameFilter] = useState("");
  const [externalCode, setExternalCodeFilter] = useState("");
  const [financeType, setFinanceTypeFilter] = useState("");
  const [program, setProgramFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (lenderName.trimStart() != null && lenderName.trimStart() != "") {
      query_string = query_string.concat(`&name=${lenderName}`);
    }
    if (externalCode.trimStart() != null && externalCode.trimStart() != "") {
      query_string = query_string.concat(`&external_code=${externalCode}`);
    }
    if (financeType.trimStart() != null && financeType.trimStart() != "") {
      query_string = query_string.concat(`&finance_type=${financeType}`);
    }
    if (program.trimStart() != null && program.trimStart() != "") {
      query_string = query_string.concat(`&program=${program}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled"
          .toLowerCase()
          .startsWith(statusFilter.slice(0, Math.max("enabled".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled"
          .toLowerCase()
          .startsWith(statusFilter.slice(0, Math.max("disabled".length - 1, 1)))
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const lenderFilter: ILenderFilter = data;
  const { mutate: updateLenderData } = UseMutation_UpdateLender();
  const { mutate: addLender } = UseMutation_AddLender();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleEnableChange = (data: ILender, event) => {
    updateLenderData({
      id: data?.id,
      is_active: event.target.checked,
      external_code: data?.external_code,
    });
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const handleError = (error) => {
    if (
      error?.response?.data?.detail?.message ===
      "Lender name and External code should be unique"
    ) {
      setError("name", { message: "Lender name should be unique" });
      setError("external_code", {
        message: "External code should be unique",
      });
      return;
    } else if (
      error?.response?.data?.detail?.message === "Lender name should be unique"
    ) {
      setError("name", { message: "Lender name should be unique" });
      return;
    } else if (
      error?.response?.data?.detail?.message ===
      "External code should be unique"
    ) {
      setError("external_code", {
        message: "External code should be unique",
      });
      return;
    } else if (
      error?.response?.data?.detail?.message === "Default lender already exists"
    ) {
      actions.setToast({
        toastMessage: "Only 1 lender can be marked as default at a time",
        toastState: true,
        variant: "error",
      });
    } else {
      onClose();
      actions.setToast({
        toastMessage: error?.response?.data?.detail?.message,
        toastState: true,
        variant: "error",
      });
    }
  };

  const handleSaveLender = () => {
    const data = {
      name: getValues("name"),
      external_code: getValues("external_code"),
      finance_type: getValues("finance_type"),
      program: getValues("program"),
      evaluation: getValues("evaluation"),
      e_contracting: getValues("e_contracting"),
      minimum_financed_amount: getValues("minimum_finance_amount"),
      default_lender: getValues("default_lender"),
      is_active: getValues("status"),
      company_id: localStorage.getItem("company_id"),
    };
    if (popUpOpenState === ActionOptions.ADD) {
      addLender(data, {
        onSuccess() {
          form.reset({} as IFormInputs);
          //  setPopUpState(false)
          onClose();
          actions.setToast({
            toastMessage: "Record Added Successfully",
            toastState: true,
          });
        },
        onError: (error: any) => {
          handleError(error);
        },
      });
    } else {
      updateLenderData(
        {
          name: getValues("name"),
          external_code: getValues("external_code"),
          finance_type: getValues("finance_type"),
          program: getValues("program"),
          evaluation: getValues("evaluation"),
          e_contracting: getValues("e_contracting"),
          minimum_financed_amount: getValues("minimum_finance_amount"),
          default_lender: getValues("default_lender"),
          is_active: getValues("status"),
          id: getValues("id"),
          company_id: localStorage.getItem("company_id"),
        },
        {
          onSuccess() {
            form.reset({} as IFormInputs);
            setPopUpState(false);
            actions.setToast({
              toastMessage: "Record Updated Successfully",
              toastState: true,
            });
          },
          onError: (error: any) => {
            handleError(error);
          },
        }
      );
    }
  };
  const handleEditLenderProfile = (item) => {
    setValue("id", item?.id);
    reset(item);
    setPopUpState(true);
    setPopUpOpenState(ActionOptions.EDIT);
  };
  // Function to capitalize the first letter of each word
  const capitalizeFirstLetter = (word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  };
  return (
    <>
      <DrawerWrap open={popUpState}>
        <LayoutWithSideNav theme={theme}>
          <PageHeader
            className="main-page-header"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ fontSize: 36 }}
                  rowGap={5}
                  mt={1}
                  theme={theme}
                  children="Lender Profile"
                />
              </Grid>
              <Grid
                theme={theme}
                item
                xs={12}
                lg={5}
                sm={12}
                md={6}
                textAlign="right"
              >
                <Button
                  theme={theme}
                  primary
                  text="Add New Lender"
                  onClick={handleshow}
                  disabled={lenderFilter?.result?.length > 0}
                  sx={{ mb: 5 }}
                />
              </Grid>
            </Grid>
          </PageHeader>

          <DataTableWrap theme={theme} className="table-pagination">
            <Box theme={theme} className="scroll">
              <Box className="scroll-hide spr-border" theme={theme} />
            </Box>
            <DataTable
              theme={theme}
              variant="basic"
              theadChildren={
                <>
                  <TableRow>
                    {/* <TableCell className="checkbox-cell fixed-cell">
                      <Checkbox label="" theme={theme} />
                    </TableCell> */}
                    <TableCell>Lender Name</TableCell>
                    <TableCell>External Code</TableCell>
                    <TableCell>Finance Type(s)</TableCell>
                    <TableCell>Program</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell className="action-cell fixed-cell" />
                  </TableRow>
                  <TableRow className="filters-row">
                    {/* <TableCell className="checkbox-cell fixed-cell"></TableCell> */}
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"lenderName"}
                          onChange={(value) => {
                            setLenderNameFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={lenderName?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"externalCode"}
                          onChange={(value) => {
                            setExternalCodeFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={externalCode?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"financeType"}
                          onChange={(value) => {
                            setFinanceTypeFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={financeType?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"financeType"}
                          onChange={(value) => {
                            setProgramFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={program?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          id={"status"}
                          onChange={(value) => {
                            setStatusFilter(value);
                          }}
                          onBlur={() => {
                            handleFilter();
                          }}
                          value={statusFilter?.trimStart()}
                        />
                        <Button
                          defaultBtn
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell className="action-cell fixed-cell" />
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {lenderFilter?.result &&
                    lenderFilter?.result?.map((item, index) => (
                      <TableRow className="child-tr" key={index}>
                        {/* <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox label="" theme={theme} />
                  </TableCell> */}
                        <TableCell>
                          {item?.default_lender
                            ? item?.name + "(Default)"
                            : item?.name}
                        </TableCell>
                        <TableCell>{item.external_code}</TableCell>
                        <TableCell>
                          {item &&
                            item?.finance_type
                              ?.map((type) => capitalizeFirstLetter(type))
                              ?.join(", ")}
                        </TableCell>
                        <TableCell>{item && item?.program}</TableCell>
                        <TableCell>
                          <Switch
                            id={"ListDataStatus" + item?.id}
                            theme={theme}
                            varient={"basic"}
                            switchEnabled={item.is_active}
                            onChange={(event) =>
                              handleEnableChange(item, event)
                            }
                            label={item?.is_active ? "Enabled" : "Disabled"}
                          />
                        </TableCell>
                        <TableCell className="action-cell fixed-cell">
                          <Menu
                            theme={theme}
                            options={[
                              {
                                optionText: (
                                  <>
                                    <Icon
                                      className="menu-icon"
                                      name="EditIcon"
                                    />{" "}
                                    Edit
                                  </>
                                ),
                                optionValue: "edit",
                              },
                            ]}
                            handleOptionClick={(_event, _key, value) => {
                              switch (value) {
                                case "edit":
                                  handleEditLenderProfile(item);
                                  break;
                              }
                            }}
                            render={(onMenuSelection) => (
                              <Button
                                defaultBtn
                                iconText={<Icon name="MoreIcon" />}
                                onClick={onMenuSelection}
                              ></Button>
                            )}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              }
            />
            <PaginationWrap>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={lenderFilter ? lenderFilter?.total_results : -1}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  IconComponent: ChevronDown,
                  MenuProps: { disablePortal: true },
                }}
              />
            </PaginationWrap>
          </DataTableWrap>

          <PersistentDrawer
            title={
              popUpOpenState === ActionOptions.ADD
                ? "Add New Lender"
                : "Edit Lender"
            }
            openPopUp={popUpState}
            setPopUpState={setPopUpState}
            theme={theme}
            enabledPin={true}
            customFooter={
              <Button
                theme={theme}
                primary
                type="submit"
                text="Save Lender"
                fullWidth
                onClick={handleSubmit(handleSaveLender)}
              />
            }
          >
            <AddNewLender form={form} popUpOpenState={popUpOpenState} />
          </PersistentDrawer>
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default LenderProfileList;
