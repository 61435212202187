import {
  IntegrationType,
  Providers,
} from "../Enums/IntegrationTypesAndProviders";

const data = {
  integration_types: [
    {
      integration_type: IntegrationType.PAYMENT_PROCESSING,
      subscription: "company",
      provider_name: [
        {
          name: "Stripe Connect",
          value: Providers.STRIPE,
          user_input: [
            {
              name: "api_secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "api_version",
              value: "",
              data_type: "text",
              label: "API Version",
            },
          ],
        },
        {
          name: "Plaid",
          value: Providers.PLAID,
          subscription: "company",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "app_name",
              value: "",
              data_type: "text",
              label: "APP Name",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "api_version",
              value: "",
              data_type: "text",
              label: "API Version",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.CUSTOMER_MANAGEMENT,
      subscription: "dealer",
      provider_name: [
        {
          name: "Dealer Track",
          value: Providers.DEALERTRACK,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },

            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "enterprise_code",
              value: "",
              data_type: "text",
              label: "Enterprise Code",
            },
            {
              name: "company_number",
              value: "",
              data_type: "text",
              label: "Company Number",
            },
            {
              name: "host",
              value: "",
              data_type: "text",
              label: "Host",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.INVENTORY_MANAGEMENT,
      subscription: "dealer",
      provider_name: [
        {
          name: "Dealer Track",
          value: Providers.DEALERTRACK,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "enterprise_code",
              value: "",
              data_type: "text",
              label: "Enterprise Code",
            },
            {
              name: "company_number",
              value: "",
              data_type: "text",
              label: "Company Number",
            },
            {
              name: "host",
              value: "",
              data_type: "text",
              label: "Host",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.FNI_PRODUCTS,
      subscription: "company",
      provider_name: [
        {
          name: "Provider Exchange Network",
          value: Providers.PEN,
          is_active: "true",
          subscription: "company",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "user_name",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "token",
              value: "",
              data_type: "text",
              label: "Token",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API  Base Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.FRAUD_IDENTIFICATION,
      subscription: "company",
      provider_name: [
        {
          name: "Socure",
          value: Providers.SOCURE,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.IDENTITY_VERIFICATION,
      subscription: "both",
      provider_name: [
        {
          name: "Mitek",
          value: Providers.MITEK,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Identity ​Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Identity Client Secret",
            },
            {
              name: "manual_client_id",
              value: "",
              data_type: "text",
              label: "​Manual Client ID",
            },
            {
              name: "manual_client_secret",
              value: "",
              data_type: "text",
              label: "Manual Client Secret",
            },
            {
              name: "manual_api_base_url",
              value: "",
              data_type: "text",
              label: "Manual Base Url",
            },
            {
              name: "identity_api_base_url",
              value: "",
              data_type: "text",
              label: "Identity Base Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.LOCATION_SERVICE,
      subscription: "both",
      provider_name: [
        {
          name: "Google",
          value: Providers.GOOGLE,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.TRADEIN_VALUATION,
      subscription: "dealer",
      provider_name: [
        {
          name: "Kelly Blue Book",
          value: Providers.KBB,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "base_url",
              value: "",
              data_type: "text",
              label: "Api Base Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.DEAL_PUSH,
      subscription: "dealer",
      provider_name: [
        {
          name: "Dealer Track",
          value: "DealerTrack",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "enterprise_code",
              value: "",
              data_type: "text",
              label: "Enterprise Code",
            },
            {
              name: "company_number",
              value: "",
              data_type: "text",
              label: "Company Number",
            },
            {
              name: "host",
              value: "",
              data_type: "text",
              label: "Host",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.CALCULATION_AND_TAXATION,
      subscription: "company",
      provider_name: [
        {
          name: "Market Scan",
          value: Providers.MARKETSCAN,
          subscription: "company",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "partner_id",
              value: "",
              dataType: "String",
              label: "Partner ID",
            },
            {
              name: "api_url",
              value: "",
              dataType: "String",
              label: "API base url",
            },
            {
              name: "account_number",
              value: "",
              dataType: "String",
              label: "Account Number",
            },
          ],
        },
      ],
    },
  ],
};

export default data;
