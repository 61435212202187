import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IMenuProps } from "./Menu.types";
import Button from "../Button";
import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 48;

const StyledMenu = styled(Menu, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IMenuProps>>(({ theme }) => ({
  "&.u-dropdown-menu": {
    ".MuiMenu-paper": {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: theme.palette.grey[50],
      boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
      minWidth: 145,
      padding: 8,
      color: theme.palette.grey[600],
      ".MuiMenu-list": {
        padding: 0,
      },
      "@supports (-moz-appearance:none)": {
        scrollbarWidth: "thin",
      },
      "&::-webkit-scrollbar": {
        width: 7,
        height: 7,
      },
      "&::-webkit-scrollbar-track": {
        padding: "0 1px",
        backgroundColor: "#f0f0f0",
      },
      "&::-webkit-scrollbar-thumb": {
        transition: "all 0.3s",
        backgroundColor: "#cdcdcd",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#a6a6a6",
      },
    },
    ".u-dropdown-item": {
      padding: "12px 24px 12px 16px",
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: "#F8F8F8",
        color: theme.palette.grey[900],
      },
    },
  },
}));

const LongMenu: React.FC<Partial<IMenuProps>> = ({
  options,
  render,
  theme,
  handleOptionClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleOption = (e, key, value) => {
    handleOptionClick(e, key, value);
    handleClick(e);
  };

  return (
    <>
      {render(handleClick)}
      <StyledMenu
        className="u-dropdown-menu"
        id="long-menu"
        theme={theme}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            className="u-dropdown-item"
            key={option.optionkey}
            onClick={(e) => {
              handleOption(e, option.optionkey, option.optionValue);
            }}
          >
            {option.optionText}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default LongMenu;
