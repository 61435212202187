import { FC, useEffect, useState } from "react";
import { TableCell, TablePagination, TableRow, useTheme } from "@mui/material";
import ChevronDown from "shared/assets/images/chevron-down";
import {
  Box,
  DataTable,
  Icon,
  Checkbox,
  Input,
  Button,
  Menu,
} from "@ntpkunity/controls";
import { ActionOptions, DialogMessages } from "Enums";
import {
  ILanguageTranslationModel,
  IPlaceholderTranslationModel,
  ITenantLanguagesModel,
} from "Interface";
import { ConfirmationDialog, PaginationWrap } from "@sharedComponents";
import { UseMutation_DeletePlaceholder } from "services";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const LanguageTable: FC<{
  tenantLanguages: ITenantLanguagesModel;
  languagesAndTranslation: ILanguageTranslationModel;
  onEditPlaceholder?: (data?: IPlaceholderTranslationModel) => unknown;
  pageNumber: number;
  setPageNumber: any;
  pageSize: number;
  setPageSize: any;
  columnFilters: string;
  setColumnFilters: any;
}> = ({
  tenantLanguages,
  languagesAndTranslation,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  columnFilters,
  setColumnFilters,
  onEditPlaceholder,
}) => {
  const theme = useTheme();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [placeholderFilter, setPlaceholderFilter] = useState("");
  const [translationsFilter, setTranslationsFilter] = useState({});

  const [placeholderId, setPlaceholderId] = useState();

  const { mutate: deletePlaceholder } = UseMutation_DeletePlaceholder();

  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    let params = [];

    if (
      placeholderFilter?.trimStart() &&
      placeholderFilter?.trimStart() !== ""
    ) {
      const placeholderParam = {
        column: "placeholder",
        keyword: placeholderFilter.trimStart(),
      };

      params.push(placeholderParam);
    }

    if (translationsFilter) {
      for (const [languageCode, value] of Object.entries<string>(
        translationsFilter
      )) {
        if (value?.trimStart() && value?.trimStart() !== "") {
          const translationParam = {
            column: "translation",
            languageCode,
            keyword: value?.trimStart(),
          };
          params.push(translationParam);
        }
      }
    }

    if (params.length > 0) {
      const queryParamsJSON = JSON.stringify(params);
      const encodedQueryParams = encodeURIComponent(queryParamsJSON);
      query_string += `&query_params=${encodedQueryParams}`;
    }

    return query_string;
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEditPlaceholder(value);
        return;
      case ActionOptions.DELETE:
        setPlaceholderId(value?.id);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deletePlaceholder(placeholderId);
  };

  useEffect(() => {
    if (Array.isArray(tenantLanguages)) {
      const initialFilters = {};
      tenantLanguages?.forEach((language) => {
        initialFilters[language?.Language?.code] =
          translationsFilter[language?.Language?.code] || "";
      });
      setTranslationsFilter(initialFilters);
    }
  }, [tenantLanguages]);

  return (
    <>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              {/* <TableCell className="checkbox-cell fixed-cell">
                <Checkbox label="" theme={theme} />
              </TableCell> */}
              <TableCell>Placeholder</TableCell>
              {Array.isArray(tenantLanguages) &&
                tenantLanguages?.map((lan, index) => (
                  <TableCell key={index}>
                    {lan?.Language?.name}
                    {lan?.is_default ? "(Default)" : ""}
                  </TableCell>
                ))}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>

            <TableRow className="filters-row">
              {/* <TableCell className="checkbox-cell fixed-cell" /> */}
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    id="key"
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setPlaceholderFilter(value);
                    }}
                    value={placeholderFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                </Box>
              </TableCell>
              {Array.isArray(tenantLanguages) &&
                tenantLanguages?.map((language, index) => (
                  <TableCell key={index}>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="key"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        value={translationsFilter[
                          language?.Language?.code
                        ]?.trimStart()}
                        onChange={(value) => {
                          setTranslationsFilter((prevFilters) => ({
                            ...prevFilters,
                            [language?.Language?.code]: value,
                          }));
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                ))}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {languagesAndTranslation?.placeholders?.result?.map(
              (placeholder, index) => (
                <TableRow className="child-tr" key={index}>
                  {/* <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox label="" />
                  </TableCell> */}
                  <TableCell className="indent-cell">
                    {placeholder.placeholder}
                  </TableCell>
                  {languagesAndTranslation?.languages?.map(
                    (language, index) => (
                      <TableCell key={index}>
                        {
                          placeholder?.translations?.find(
                            (f) => f?.languages?.code == language?.code
                          )?.translation
                        }
                      </TableCell>
                    )
                  )}
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={tableOptions.map((option) => {
                        return {
                          optionText: option.optionText,
                          optionkey: option.optionkey,
                          optionValue: placeholder,
                        };
                      })}
                      handleOptionClick={handleSelection}
                      render={(onMenuSelection) => (
                        <Button
                          id={"makeEllipses"}
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        />
                      )}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={
            languagesAndTranslation
              ? languagesAndTranslation?.placeholders?.totalResults
              : -1
          }
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default LanguageTable;
