import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  unityTheme,
  Select,
  IBoxProps,
  MultiSelect,
  PhoneInput,
  Icon,
} from "@ntpkunity/controls";
import {
  Grid,
  Input,
  Box,
  Typography,
  Button,
  AddressLayout,
  MultiSelectWrap,
} from "@sharedComponents";
import { styled } from "@mui/material/styles";
import { validation } from "shared/helper/methods";
import { useTheme } from "@mui/material";
import { usePenSetDealer } from "services/integration-api.service";
import {
  useUpdateDealerProfilebyId,
  useUpdatePenDealerId,
  useGetStates,
  UseQuery_getAllStates,
} from "services/dealer-configurations.service";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
import { ICountry } from "Interface";
const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.tab-wrap": {
    width: "100%",
    display: "inline-block",

    hr: {
      opacity: 0.4,
    },

    ".title-icon": {
      width: "100%",
      display: "inline-block",
      "span, Icon": {
        float: "left",
      },
      span: {
        marginRight: 10,
      },
    },
    ".buttons-row": {
      display: "inline-block",
      textAlign: "center",
      width: "100%",

      Button: {
        margin: "0 20px 20px 0",

        "&:last-of-type": {
          marginRight: 0,
        },
      },
    },
    ".label-shrink": {
      ".u-form-group": {
        ".MuiFormControl-root": {
          ".MuiInputLabel-root": {
            transform: "translate(14px, -9px) scale(0.85)",

            color: "rgba(0, 0, 0, 0.6)",

            padding: "0 5px 0 5px",

            left: -4,

            top: 0,

            zIndex: 0,
          },
        },
      },
    },
  },
}));

const Available_Finance = [
  { text: "Finance", value: "Finance" },
  { text: "Lease", value: "Lease" },
];
const Schedule_option = [
  { text: "Pickup", value: "pickup" },
  { text: "Delivery", value: "delivery" },
];
const day_light = [
  { text: "Yes", value: true },
  { text: "No", value: false },
];
const timeZone = [
  {
    text: "(GMT-04:00) Atlantic Time (Canada)",
    value: "Atlantic",
  },
  {
    text: "(GMT-05:00) Eastern Time (US and Canada)",
    value: "Eastern",
  },
  {
    text: "(GMT-06:00) Central Time (US and Canada)",
    value: "Central",
  },
  {
    text: "(GMT-07:00) Mountain Time (US and Canada)",
    value: "Mountain",
  },
  {
    text: "(GMT-08:00) Pacific Time (US and Canada)",
    value: "Pacific",
  },
  { text: "(GMT-09:00) Alaska", value: "Alaska" },
  { text: "(GMT-10:00) Hawaii", value: "Hawaii" },
  { text: "(GMT-11:00) Midway Island, Samoa", value: "Samoa" },
  {
    text: "(GMT+10:00) Chamorro Time",
    value: "Chamorro",
  },
];
const General: FC<{
  form: any;
  dealer_code: string;
  companyCountries: any;
  setIsAddressDirty?: any;
  isAddressDirty?: boolean;
  isAddressValidationActive?: boolean;
  providerByCompany?:boolean;
}> = ({
  form,
  dealer_code,
  companyCountries,
  setIsAddressDirty,
  isAddressDirty,
  isAddressValidationActive,
  providerByCompany,
}) => {
  const { mutate: updateDealerProfilebyId } = useUpdateDealerProfilebyId();
  const { mutate: updatePenDealerId } = useUpdatePenDealerId();

  const theme = useTheme();
  UseQuery_getAllStates();
  const queryClient = useQueryClient();
  const StatesData: any = queryClient.getQueryData(QueryKeys.GET_ALL_STATES);
  const { mutate: setDealer } = usePenSetDealer();
  const { actions } = useStoreContext();
  const {
    control,
    formState: { isDirty, errors },
    setValue,
    trigger,
    watch,
    getValues,
  } = form;
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    id: null,
    code: "",
    name: "",
    country_code: "",
    address_template: null,
  });
  const country = watch("profile.dealer_address.country_id");
  useEffect(() => {
    if (country && companyCountries) {
      let foundCountry: ICountry = companyCountries?.find(
        (x) => x?.id == country?.id
      );
      if (foundCountry) {
        setSelectedCountry(foundCountry);
        setValue("profile.country_code", foundCountry?.country_code);
        setValue(
          "profile.dealer_address.apply_zip_code_validation",
          foundCountry?.address_template?.field3_visible
        );
      }
    }
  }, [country]);
  function handleCountryChange() {
    setValue("profile.contact_number", "");
    setValue("profile.customer_support_number", "");
    setValue("profile.fax_number", "");
    setValue("profile.dealer_address.verified", false);
  }
  return (
    <TabWrap className="tab-wrap">
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mb: 2.7 }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MultiSelectWrap>
            <Controller
              name="profile.available_finance_types"
              control={control}
              rules={validation("Available Finance Type", true)}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  disablePortal
                  id={"available_finance_type"}
                  theme={theme}
                  label={"Available Finance Type"}
                  placeholder={""}
                  items={Available_Finance.map((type) => {
                    return { text: type.text, value: type.value };
                  })}
                  value={value ?? []}
                  onChange={(e: any) => {
                    onChange(e);
                  }}
                  selectError={
                    errors?.profile?.available_finance_types?.message
                  }
                  sxProps={""}
                />
              )}
            />
          </MultiSelectWrap>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MultiSelectWrap>
            <Controller
              name="profile.schedule_option"
              control={control}
              rules={validation("Schedule Option", true)}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  disablePortal
                  id={"scheduled_options"}
                  theme={theme}
                  label={"Schedule Options"}
                  placeholder={""}
                  items={Schedule_option.map((option) => {
                    return { value: option.value, text: option.text };
                  })}
                  value={value ?? []}
                  onChange={(e: any) => {
                    onChange(e);
                  }}
                  selectError={errors?.profile?.schedule_option?.message}
                  sxProps={""}
                />
              )}
            />
          </MultiSelectWrap>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
          <Controller
            name="profile.contact_number"
            control={control}
            rules={validation("Contact Number", true)}
            defaultValue={watch("profile.contact_number")}
            render={({ field }) => (
              <>
                <PhoneInput
                  theme={unityTheme}
                  type={"text"}
                  {...field}
                  label={"Contact Number"}
                  fullWidth
                  phoneCountryCode={selectedCountry?.country_code ?? "US"}
                  error={errors?.profile?.contact_number?.message}
                />
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
          <Controller
            name="profile.customer_support_number"
            control={control}
            rules={validation("Customer Support Number", true)}
            defaultValue={watch("profile.customer_support_number")}
            render={({ field }) => (
              <PhoneInput
                theme={unityTheme}
                type={"text"}
                {...field}
                label={"Customer Support Number"}
                fullWidth
                phoneCountryCode={selectedCountry?.country_code ?? "US"}
                error={errors?.profile?.customer_support_number?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
          <Controller
            name="profile.fax_number"
            control={control}
            defaultValue={watch("profile.fax_number")}
            render={({ field }) => (
              <PhoneInput
                theme={unityTheme}
                type={"text"}
                {...field}
                label={"Fax Number (Optional)"}
                fullWidth
                phoneCountryCode={selectedCountry?.country_code ?? "US"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.time_zone"
            control={control}
            defaultValue={""}
            rules={validation("Time Zone", true)}
            render={({ field }) => (
              <Select
                disablePortal
                theme={theme}
                label={"Time Zone"}
                items={timeZone}
                {...field}
                value={field.value ?? ""}
                sxProps={""}
                selectError={errors?.profile?.time_zone?.message}
                onChange={field.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.email"
            control={control}
            rules={validation("Email", true)}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"sample@sample.com"}
                label={"Email Address"}
                type="email"
                {...field}
                value={field.value ?? ""}
                error={errors?.profile?.email?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.website"
            control={control}
            rules={validation("Website", true)}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"www.sample.com"}
                label={"Website"}
                type="text"
                {...field}
                value={field.value?.trimStart()}
                error={errors?.profile?.website?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.contact_person_name"
            control={control}
            rules={validation("Contact Person Name", true)}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here.."}
                label={"Contact Person Name"}
                type="text"
                {...field}
                value={field.value?.trimStart()}
                error={errors?.profile?.contact_person_name?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.day_light_saving"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                disablePortal
                theme={theme}
                label={"Daylight Saving Applicable"}
                items={day_light}
                {...field}
                value={field.value ?? ""}
                sxProps={""}
                onChange={(e) => {
                  field.onChange(e);
                }}
                selectError={errors?.profile?.day_light_saving?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.initials"
            control={control}
            rules={validation("Initials", true)}
            defaultValue={""}
            render={({ field: { onChange, value } }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here.."}
                label={"Initials"}
                type="text"
                value={value?.trimStart()}
                error={errors?.profile?.initials?.message}
                onChange={(e) => {
                  onChange(
                    e
                      .replace(/[^a-z]/gi, "")
                      .toUpperCase()
                      .slice(0, 2)
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ mb: 3 }}>
        <hr />
      </Box>
      <Typography
        className="title-icon"
        variant="h4"
        component="h4"
        theme={theme}
      >
        <span>Dealer Address</span>
        {getValues("profile.dealer_address.verified") &&
          companyCountries?.length > 0 && <Icon name="GreenCheckIcon" />}
      </Typography>
      <Box sx={{ mt: 3, mb: 30 }}>
        <Controller
          name="profile.dealer_address"
          control={control}
          defaultValue={watch("profile.dealer_address")}
          render={({ field }) => (
            <AddressLayout
              theme={theme}
              states={StatesData}
              {...field}
              countries={companyCountries}
              isContactRequired={false}
              value={watch("profile.dealer_address")}
              hasAddressTooltip={true}
              onChange={(e: any) => {
                setValue("profile.dealer_address", e);
                trigger("profile.dealer_address");
              }}
              handleCountryChange={handleCountryChange}
              setIsAddressDirty={setIsAddressDirty}
              validationProps={errors?.profile?.dealer_address}
              disableCounty={isAddressValidationActive}
              dealer_code={dealer_code}
              addressVerificationRequired={isAddressValidationActive}
              providerByCompany={providerByCompany}
            />
          )}
        />
      </Box>

      {/* <Box sx={{ mb: 3 }}>
        <hr />
      </Box> */}

      {/* {watch("profile.id") ? (
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mb: 2.7 }}
        >
          <Grid
            item
            xs={
              watch("profile.id") &&
              watch("profile.pen_dealer_code") &&
              watch("profile.pen_dealer_code") !== "null"
                ? 6
                : 12
            }
            textAlign={
              watch("profile.id") &&
              watch("profile.pen_dealer_code") &&
              watch("profile.pen_dealer_code") !== "null"
                ? "right"
                : "center"
            }
          >
            {watch("profile.pen_dealer_code") &&
            watch("profile.pen_dealer_code") !== "null" ? (
              <Button
                secondary
                text={"Unregister with F&I Product Provider"}
                onClick={() => {
                  setDealer(
                    {
                      action: "UNREGISTER",
                      address1: watch(
                        "profile.dealer_address.address_line_1"
                      ),
                      address2: watch(
                        "profile.dealer_address.address_line_2"
                      ),
                      city: watch("profile.dealer_address.city"),
                      contactName: watch("profile.contact_person_name"),
                      penDealerId: watch("profile.pen_dealer_code"),
                      dealershipName: dealer_code,
                      email: watch("profile.email"),
                      fax: watch("profile.fax_number"),
                      isTestDealer: true,
                      observesDayLightSaving: watch("profile.day_light_saving"),
                      phone: watch("profile.contact_number"),
                      state: watch("profile.dealer_address.state_id"),
                      timeZone: watch("profile.time_zone"),
                      webSite: watch("profile.website"),
                      zipCode: watch("profile.dealer_address.zip_code"),
                    },
                    {
                      onSuccess(response: any) {
                        if (
                          response.Envelope.Body.SetDealerResponse
                            .SetDealerResult.Success.__text === "true"
                        ) {
                          setValue("profile.pen_dealer_code", null);
                          updatePenDealerId({
                            id: watch("profile.id"),
                            pen_dealer_code: watch("profile.pen_dealer_code"),
                          });
                          actions.setToast({
                            toastMessage: "Your account has been unregistered",
                            toastState: true,
                          });
                        }
                      },
                    }
                  );
                }}
              ></Button>
            ) : (
              <Button
                secondary
                text={"Register with F&I Product Provider"}
                onClick={() => {
                  trigger("profile");
                  if (!Object.keys(errors).includes("profile")) {
                    setDealer(
                      {
                        action: "REGISTER",
                        address1: watch(
                          "profile.dealer_address.address_line_1"
                        ),
                        address2: watch(
                          "profile.dealer_address.address_line_2"
                        ),
                        city: watch("profile.dealer_address.city"),
                        contactName: watch("profile.contact_person_name"),
                        penDealerId: 0,
                        dealershipName: dealer_code,
                        email: watch("profile.email"),
                        fax: watch("profile.fax_number"),
                        isTestDealer: true,
                        observesDayLightSaving: watch(
                          "profile.day_light_saving"
                        ),
                        phone: watch("profile.contact_number"),
                        state: watch("profile.dealer_address.state_id"),
                        timeZone: watch("profile.time_zone"),
                        webSite: watch("profile.website"),
                        zipCode: watch("profile.dealer_address.zip_code"),
                      },
                      {
                        onSuccess(response: any) {
                          if (
                            response.Envelope.Body.SetDealerResponse
                              .SetDealerResult.Success.__text === "true"
                          ) {
                            setValue(
                              "profile.pen_dealer_code",
                              response.Envelope.Body.SetDealerResponse
                                .SetDealerResult.DealerID.__text
                            );
                            if (isDirty) {
                              updateDealerProfilebyId(watch("profile"), {
                                onSuccess() {
                                  actions.setToast({
                                    toastMessage:
                                      "The account has been registered successfully",
                                    toastState: true,
                                  });
                                },
                              });
                            } else {
                              updatePenDealerId(
                                {
                                  id: watch("profile.id"),
                                  pen_dealer_code: watch("profile.pen_dealer_code"),
                                },
                                {
                                  onSuccess() {
                                    actions.setToast({
                                      toastMessage:
                                        "The account has been registered successfully",
                                      toastState: true,
                                    });
                                  },
                                }
                              );
                            }
                          }
                        },
                      }
                    );
                  }
                }}
              ></Button>
            )}
          </Grid>
          <Grid item xs={6}>
            {watch("profile.pen_dealer_code") &&
            watch("profile.pen_dealer_code") !== "null" ? (
              <Button
                secondary
                text={"Update Info With F&I Product Provider"}
                onClick={() => {
                  trigger("profile");
                  if (!Object.keys(errors).includes("profile")) {
                    setDealer(
                      {
                        action: "UPDATE",
                        address1: watch(
                          "profile.dealer_address.address_line_1"
                        ),
                        address2: watch(
                          "profile.dealer_address.address_line_2"
                        ),
                        city: watch("profile.dealer_address.city"),
                        contactName: watch("profile.contact_person_name"),
                        penDealerId: watch("profile.pen_dealer_code"),
                        dealershipName: dealer_code,
                        email: watch("profile.email"),
                        fax: watch("profile.fax_number"),
                        isTestDealer: true,
                        observesDayLightSaving: watch(
                          "profile.day_light_saving"
                        ),
                        phone: watch("profile.contact_number"),
                        state: watch("profile.dealer_address.state_id"),
                        timeZone: watch("profile.time_zone"),
                        webSite: watch("profile.website"),
                        zipCode: watch("profile.dealer_address.zip_code"),
                      },
                      {
                        onSuccess(response: any) {
                          if (
                            response.Envelope.Body.SetDealerResponse
                              .SetDealerResult.Success.__text === "true"
                          ) {
                            if (isDirty) {
                              updateDealerProfilebyId(watch("profile"), {
                                onSuccess() {
                                  actions.setToast({
                                    toastMessage:
                                      "The changes have been saved successfully",
                                    toastState: true,
                                  });
                                },
                              });
                            } else {
                              actions.setToast({
                                toastMessage:
                                  "The changes have been saved successfully",
                                toastState: true,
                              });
                            }
                          }
                        },
                      }
                    );
                  }
                }}
              ></Button>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : (
        ""
      )} */}
    </TabWrap>
  );
};
export default General;
