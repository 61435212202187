import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AccordionWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".MuiAccordion-root": {
    color:
      theme.palette.mode === "light"
        ? [theme.palette.common.black]
        : [theme.palette.common.white],
    border: "none",
    marginBottom: 24,
    backgroundColor:
      theme.palette.mode === "light"
        ? [theme.palette.common.white]
        : [theme.palette.common.black],
    "&::before": {
      display: "none",
    },
  },
  ".MuiAccordionSummary-root": {
    backgroundColor: "transparent",
    "&.filter-accordion": {
      padding: 0,
      minHeight: 0,
      ".MuiAccordionSummary-content": {
        margin: 0,
      },
    },
    ".MuiAccordionSummary-content": {
      justifyContent: "space-between",
    },
    ".MuiAccordionSummary-expandIconWrapper": {
      marginLeft: 8,
    },
    "&.Mui-expanded": {
      ".MuiAccordionSummary-expandIconWrapper": {
        "&.Mui-expanded": {
          transform: "rotate(180deg)",
        },
      },
    },
    ".sub-title": {
      color: theme.palette.grey[900],
      maxWidth: 160,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  ".MuiAccordionDetails-root": {
    borderTop: "none",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[100],
    "&.filter-accordion": {
      padding: "0px 0 24px",
      maxHeight: 144,
      overflowX: "hidden",
      overflowY: "auto",
      paddingTop: 24,
      "@supports (-moz-appearance:none)": {
        scrollbarWidth: "thin",
      },
      "&::-webkit-scrollbar": {
        width: 7,
        height: 7,
      },
      "&::-webkit-scrollbar-track": {
        padding: "0 1px",
        backgroundColor: "#f0f0f0",
      },
      "&::-webkit-scrollbar-thumb": {
        transition: "all 0.3s",
        backgroundColor: "#cdcdcd",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#a6a6a6",
      },
    },
  },
  "&.u-accordion-group": {
    marginBottom: 0,
    borderRadius: theme.shape.borderRadius,
    border: "1px solid" + theme.palette.grey[100],
    "&:not(:last-of-type)": {
      borderBottom: "0px none",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 0,
    },
    "&:last-of-type": {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    ".MuiAccordionSummary-root": {
      padding: "4px 16px 4px 16px",
      ".MuiAccordionSummary-content": {
        flexWrap: "wrap",
        margin: 0,
      },
      ".main-title": {
        color:
          theme.palette.mode === "light"
            ? [theme.palette.common.black]
            : [theme.palette.common.white],
        fontWeight: theme.customVariables.fontWeightSemiBold,
        flex: "0 0 30%",
        maxWidth: "30%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        ".title-with-icon": {
          "span, svg": {
            float: "left",
          },
          span: {
            maxWidth: "90%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
          svg: {
            marginLeft: 10,
            height: 20,
          },
        },
      },
      ".sub-title": {
        color: theme.palette.grey[600],
        flex: "0 0 60%",
        maxWidth: "60%",
      },
      ".action-btn": {
        ".btn": {
          padding: "0 10px",
        },
      },
    },
    ".MuiAccordionDetails-root": {
      padding: 40,
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "none",
    },
  },
}));
