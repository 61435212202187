import { FC, useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@ntpkunity/controls";
import { Box } from "@sharedComponents";
import {
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  IFpAssetGroup,
  IImage,
} from "Interface";
import { ActionOptions } from "Enums";
import { addFpAssetGroup, removeFpAssetGroup } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { useTheme, Link } from "@mui/material";

interface AssetTrimProps {
  assetTrim: IAssetTrim;
  actionType?: ActionOptions;
  setValue?: any;
  assetMake: IAssetMake;
  assetModel: IAssetModel;
  handleImage?: (data: IImage[]) => unknown;
}

const AssetTrimTable: FC<AssetTrimProps> = ({
  assetTrim,
  actionType,
  setValue,
  assetMake,
  assetModel,
  handleImage,
}) => {
  const [state, dispatch] = useSetupsStore();
  const { fpAssetGroup } = state;
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    const found = fpAssetGroup?.find(
      (element) =>
        element.asset_model_id == assetTrim.model_id &&
        element.asset_trim_id == assetTrim.id
    );
    if (found) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [fpAssetGroup, isChecked]);

  const onCheckChange = (e: any) => {
    setValue("assetGroup", "undefined", { shouldDirty: true });
    if (e.target.checked == true) {
      const obj: IFpAssetGroup = {
        asset_make_id: assetMake.id,
        asset_model_id: assetModel.id,
        asset_trim_id: assetTrim.id,
      };
      dispatch(addFpAssetGroup(obj));
    } else {
      let dataArr = fpAssetGroup;
      dataArr = dataArr?.filter((x) => x.asset_trim_id !== assetTrim.id);
      dispatch(removeFpAssetGroup(dataArr));
    }
  };

  useEffect(() => {
    if (actionType == ActionOptions.VIEW) {
      setIsDisabled(true);
    } else {
      assetTrim?.is_active ? setIsDisabled(false) : setIsDisabled(true);
    }
  }, [actionType, isDisabled, assetTrim]);

  const theme = useTheme();

  return (
    <>
      <TableRow className="child-tr">
        <TableCell className="checkbox-cell fixed-cell">
          <Checkbox
            theme={theme}
            checkBoxDisabled={isDisabled}
            onChange={onCheckChange}
            checkBoxChecked={isChecked}
            label={""}
          />
        </TableCell>
        <TableCell className="indent-cell third-level-cell">
          <Box theme={theme} className="group-title">
            {assetTrim.trim_name}
          </Box>
        </TableCell>
        <TableCell className="img-cell">
          {assetTrim?.trim_image?.length > 0 && (
            <Link
              onClick={() => {
                handleImage(assetTrim.trim_image);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                className="prd-img"
                alt={assetTrim.trim_name}
                src={assetTrim.trim_image?.[0]?.location}
              />
            </Link>
          )}
        </TableCell>
        <TableCell>{assetTrim.trim_description}</TableCell>
        <TableCell>{assetTrim.trim_code}</TableCell>
      </TableRow>
    </>
  );
};

export default AssetTrimTable;
