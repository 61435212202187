import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Icon,
  Button,
  Input,
  Checkbox,
  DataTable,
  Grid,
  IGridProps,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { styled } from "@mui/material/styles";
import {
  useGetInventoryOptions,
  useGetAllInventoryOptionFilter,
} from "services/inventory-management.service";
import { IOptions, IVehicleDetail } from "Interface";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
import { ContactlessOutlined } from "@mui/icons-material";
// const DataTableWrap = styled(
//   Grid,
//   {}
// )<Partial<IGridProps>>(({ theme }) => ({
//   ".u-table-wrap": {
//     ".u-table-container": {
//       maxHeight: "48vh !important",
//       ".u-table": {
//         "th, td": {
//           "&.img-cell": {
//             width: 72,
//             minWidth: "72px !important",
//             textAlign: "center",
//             verticalAlign: "middle",
//             img: {
//               height: 40,
//             },
//             svg: {
//               height: 20,
//               marginTop: 3,
//               path: {
//                 stroke: theme.palette.grey[300],
//               },
//             },
//           },
//           "&.text-right": {
//             textAlign: "right",
//           },
//         },
//       },
//     },
//   },
// }));

const InventoryOptionsTable: FC<{
  setDeleteData: any;
  vehicleData: IVehicleDetail;
  addMoreOptionPopupState: boolean;
  setlistChanged: any;
}> = ({
  setDeleteData,
  vehicleData,
  addMoreOptionPopupState,
  setlistChanged,
}) => {
  const theme = useTheme();
  const [deleteState, setDeleteState] = useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState(
    vehicleData?.dealer_options
      .map((option) => `dealer_options=${option}`)
      .join("&")
  );
  const [inventoryState, setInventoryState] = useState<IOptions[]>([]);
  const [product_nameFilter, setProduct_nameFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [supplierFilter, setSupplierFilter] = useState("");
  const [installation_modeFilter, setInstallation_modelFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const { data } = useGetAllInventoryOptionFilter(columnFilters);
  const inventoryOptionFilterData = data;
  const [deleteOption, setDeleteOption] = useState<any[]>([
    inventoryOptionFilterData,
  ]);
  const setQueryString = () => {
    let query_string: string = vehicleData?.dealer_options
      .map((option) => `dealer_options=${option}`)
      .join("&");
    if (
      product_nameFilter.trimStart() != null &&
      product_nameFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&product_name=${product_nameFilter}`);
    }
    if (
      descriptionFilter.trimStart() != null &&
      descriptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&description=${descriptionFilter}`);
    }
    if (
      categoryFilter.trimStart() != null &&
      categoryFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&category=${categoryFilter}`);
    }
    if (priceFilter) {
      query_string = query_string.concat(`&price=${priceFilter}`);
    }
    if (
      installation_modeFilter.trimStart() != null &&
      installation_modeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&installation_mode=${installation_modeFilter}`
      );
    }
    if (
      supplierFilter.trimStart() != null &&
      supplierFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&supplier=${supplierFilter}`);
    }
    return query_string;
  };
  const deleteItem = (index: any) => {
    setDeleteOption(inventoryState.filter((item: any) => item.id !== index));
    setInventoryState(inventoryState.filter((item: any) => item.id !== index));
  };
  useEffect(() => {
    setDeleteData(deleteOption);
    if (deleteOption?.length < inventoryState?.length) {
      setInventoryState(deleteOption);
    }
  }, [deleteOption]);
  useEffect(() => {
    if (vehicleData?.dealer_options) {
      setColumnFilters(
        vehicleData?.dealer_options
          .map((option) => `dealer_options=${option}`)
          .join("&")
      );
    }
  }, [vehicleData?.dealer_options]);
  useEffect(() => {
    if (!addMoreOptionPopupState && !deleteState) {
      setInventoryState(inventoryOptionFilterData);
    }
  }, [inventoryOptionFilterData, inventoryState, addMoreOptionPopupState]);
  const queryClient = useQueryClient();

  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);
  const handleFilter = () => {
    setColumnFilters(setQueryString());
  };
  return (
    <>
      <DataTableWrap theme={theme}>
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                {/* <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox label="" theme={theme} />
                  </TableCell> */}
                <TableCell id="productNameHead">Product Name</TableCell>
                <TableCell id="descriptionHead">Description</TableCell>
                <TableCell id="categoryHead">Category</TableCell>
                <TableCell id="priceHead">Price</TableCell>
                <TableCell id="installationModeHead">
                  Installation Mode
                </TableCell>
                <TableCell id="supplierHead">Supplier</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                {/* <TableCell className="checkbox-cell fixed-cell" /> */}
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="productNameSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setProduct_nameFilter(value);
                      }}
                      value={product_nameFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="productFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="descriptionSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setDescriptionFilter(value);
                      }}
                      value={descriptionFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="descriptionFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="categorySearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setCategoryFilter(value);
                      }}
                      value={categoryFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="categoryFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="priceSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="number"
                      onChange={(value) => {
                        setPriceFilter(value);
                      }}
                      value={priceFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="priceFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="installationModesearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setInstallation_modelFilter(value);
                      }}
                      value={installation_modeFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="installationModeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      id="supplierSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setSupplierFilter(value);
                      }}
                      value={supplierFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {inventoryState &&
                inventoryState.map((inventoryOptions: any, index: number) => (
                  <TableRow key={index} className="child-tr">
                    {/* <TableCell className="checkbox-cell fixed-cell">
                        <Checkbox label="" theme={theme} />
                      </TableCell> */}
                    <TableCell id={"product" + inventoryOptions.product_name}>
                      {inventoryOptions.product_name}
                    </TableCell>
                    <TableCell
                      id={"description" + inventoryOptions.product_name}
                    >
                      {inventoryOptions.description}
                    </TableCell>
                    <TableCell id={"category" + inventoryOptions.product_name}>
                      {inventoryOptions.category_id}
                    </TableCell>
                    <TableCell
                      id={"price" + inventoryOptions.product_name}
                      className="text-right"
                    >
                      <Box theme={theme} sx={{ mr: 4 }}>
                        {defaultCurrency} {inventoryOptions.price}{" "}
                      </Box>
                    </TableCell>
                    <TableCell>{inventoryOptions.installation_mode}</TableCell>
                    <TableCell id={"supplier" + inventoryOptions.product_name}>
                      {inventoryOptions.supplier}
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Button
                        id={"deleteIcon" + inventoryOptions.product_name}
                        iconText={<Icon name="DeleteIcon" />}
                        onClick={(_e: any) => {
                          deleteItem(inventoryOptions.id);
                          setDeleteState(true);
                          setlistChanged(true);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </>
          }
        />
      </DataTableWrap>
    </>
  );
};

export default InventoryOptionsTable;
