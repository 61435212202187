import { FC } from "react";
import { useTheme } from "@mui/material";
import { Grid, Select } from "@ntpkunity/controls";
import { CustomGrid } from "@sharedComponents";

import { IProgramSubsidy } from "./program-subsidy.type";
import { Controller } from "react-hook-form";
import {
  CalculationMethod,
  SubsidyAmortizationMethod,
  TaxAmortization,
  SubsidyOverride,
} from "Enums";

const ProgramGeneral: FC<IProgramSubsidy> = ({ form, isDisabled }) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const theme = useTheme();

  return (
    <>
      <CustomGrid
        className="main-content-section"
        theme={theme}
        container
        item
        spacing={3}
      >
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_subsidy.calculation_method"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <Select
                id="CalculationMethod"
                theme={theme}
                label={"Calculation Method"}
                placeholder={"Select"}
                items={[
                  {
                    text: CalculationMethod.TARGET_BASE_SUBSIDY,
                    value: CalculationMethod.TARGET_BASE_SUBSIDY,
                  },
                  {
                    text: CalculationMethod.PERCENTAGE_FROM_DEALER_AND_MANUFACTURER,
                    value:
                      CalculationMethod.PERCENTAGE_FROM_DEALER_AND_MANUFACTURER,
                  },
                  {
                    text: CalculationMethod.ADJUSTER_CUSTOMER_RATE,
                    value: CalculationMethod.ADJUSTER_CUSTOMER_RATE,
                  },
                ]}
                value={value ?? ""}
                onChange={(e) => {
                  onChange(e);
                  setValue(
                    "financial_product_charts_association.program_subsidy",
                    ""
                  );
                }}
                disabled={isDisabled}
                selectError={
                  errors?.program_subsidy?.calculation_method?.message
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_subsidy.amortization_method"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <Select
                id="AmortizationMethod"
                theme={theme}
                label={"Amortization Method"}
                placeholder={"Select"}
                items={[
                  {
                    text: SubsidyAmortizationMethod.ANNUITY_ACTUAL_NUMBER_OF_DAYS,
                    value:
                      SubsidyAmortizationMethod.ANNUITY_ACTUAL_NUMBER_OF_DAYS,
                  },
                  {
                    text: SubsidyAmortizationMethod.STRAIGHT_LINE,
                    value: SubsidyAmortizationMethod.STRAIGHT_LINE,
                  },
                  {
                    text: SubsidyAmortizationMethod.STRAIGHT_LINE_PRO_RATA,
                    value: SubsidyAmortizationMethod.STRAIGHT_LINE_PRO_RATA,
                  },
                  {
                    text: SubsidyAmortizationMethod.STRAIGHT_LINE_WITH_ACTUAL_NUMBER_OF_DAYS,
                    value:
                      SubsidyAmortizationMethod.STRAIGHT_LINE_WITH_ACTUAL_NUMBER_OF_DAYS,
                  },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.program_subsidy?.amortization_method?.message
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_subsidy.tax_amortization"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <Select
                id="TaxAmortization"
                theme={theme}
                label={"Tax Amortization"}
                placeholder={"Select"}
                items={[
                  { text: TaxAmortization.YES, value: TaxAmortization.YES },
                  { text: TaxAmortization.NO, value: TaxAmortization.NO },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={errors?.program_subsidy?.tax_amortization?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="program_subsidy.subsidy_override"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <Select
                id="SubsidyOverride"
                theme={theme}
                label={"Subsidy Override"}
                placeholder={"Select"}
                items={[
                  { text: SubsidyOverride.YES, value: SubsidyOverride.YES },
                  { text: SubsidyOverride.NO, value: SubsidyOverride.NO },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={errors?.program_subsidy?.subsidy_override?.message}
              />
            )}
          />
        </Grid>
      </CustomGrid>
    </>
  );
};
export default ProgramGeneral;
