import { FC } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material";
import { Textarea } from "@ntpkunity/controls";
import { Input, Select, TextAreaWrap } from "@sharedComponents";
import { validation } from "shared/helper/methods";
import { CUSTOM_LINK_TYPES } from "shared/constants";
import { ValidationMessages } from "Enums";
const CustomLinkDrawer: FC<{ form: any }> = ({ form }) => {
  const theme = useTheme();

  const {
    control,
    formState: { errors },
  } = form;
  const customLinkTypes = CUSTOM_LINK_TYPES.map((e) => ({ text: e, value: e }));
  return (
    <>
      <Controller
        name="menu_position"
        control={control}
        defaultValue={""}
        rules={validation("Link Type", true)}
        render={({ field }) => (
          <Select
            theme={theme}
            label="Link Type"
            items={customLinkTypes}
            id="LinkTypeId"
            {...field}
            selectError={errors?.menu_position?.message}
            fullWidth
          />
        )}
      />
      <Controller
        name="menu_name"
        control={control}
        defaultValue={""}
        rules={validation("Link Name", true)}
        render={({ field }) => (
          <Input
            theme={theme}
            label={"Link Name"}
            type="text"
            id="LinkNameId"
            {...field}
            error={errors?.menu_name?.message}
            fullWidth
          />
        )}
      />
      <Controller
        name="menu_url"
        control={control}
        defaultValue={""}
        // rules={validation("Link URL", true)}
        rules={{
          required: ValidationMessages.URL_REQUIRED,
          pattern: {
            value:
              /(http:\/\/|https:\/\/|www\.|[a-zA-Z])+([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,6}/,
            message: ValidationMessages.INVALID_URL,
          },
        }}
        render={({ field }) => (
          <Input
            theme={theme}
            label={"Link URL"}
            type="text"
            id="LinkURLId"
            {...field}
            error={errors?.menu_url?.message}
            fullWidth
          />
        )}
      />

      <Controller
        name="is_active"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Status"}
            placeholder={"Select"}
            id="LinkStatusId"
            items={[
              { text: "Enabled", value: true },
              { text: "Disabled", value: false },
            ]}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  );
};

export default CustomLinkDrawer;
