import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  DataTable,
  Checkbox,
  Box,
  Input,
  Button,
  ImageViewer,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { UseQuery_getAllAssets_filter } from "services";
import { useSetupsStore } from "Stores/SetupStore/Store";
import AssetMakeTable from "./asset-make-table";
import { IAssetDataFilter, IFpAssetGroup } from "Interface";
import { addFpAssetGroup, removeFpAssetGroup } from "Stores/SetupStore/Actions";
import { ActionOptions } from "Enums";
import { Controller } from "react-hook-form";

interface IAssetGroupTableProps {
  actionType?: ActionOptions;
  setValue?: any;
  control?: any;
  deletedAssetGroup?: Array<IFpAssetGroup>;
  setDeletedAssetGroup?: any;
}

const AssetGroupTable: FC<IAssetGroupTableProps> = ({
  actionType,
  setValue,
  control,
  setDeletedAssetGroup,
  deletedAssetGroup,
}) => {
  const [state, dispatch] = useSetupsStore();
  const { fpAssetGroup } = state;
  const [filters, setFilters] = useState<{
    name: string;
    description: string;
    code: string;
  }>({ name: "", description: "", code: "" });
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${0}&page_size=${100}`
  );
  const { data: assetMakes }: { data: IAssetDataFilter } =
    UseQuery_getAllAssets_filter(columnFilters);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [allAssets, setAllAssets] = useState<Array<any>>([]);
  const [images, setImages] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [index, setIndex] = useState(0);
  function selectAllAssets(): Array<any> {
    const dataArr = [];
    assetMakes?.result
      ?.filter((make) => make.is_active)
      .map((make) => {
        const assetModelsLen = make?.asset_models
          ?.filter((model) => make.id == model.make_id && model.is_active)
          .map((model) => {
            const trimsLen = model?.asset_trims
              ?.filter((trim) => model.id == trim.model_id && trim.is_active)
              .map((trim) => {
                let b: IFpAssetGroup = {
                  asset_make_id: make.id,
                  asset_model_id: model.id,
                  asset_trim_id: trim.id,
                };
                dataArr.push(b);
              });
            if (trimsLen?.length == 0) {
              let b: IFpAssetGroup = {
                asset_make_id: make.id,
                asset_model_id: model.id,
              };
              dataArr.push(b);
            }
          });
        if (assetModelsLen?.length == 0) {
          let b: IFpAssetGroup = { asset_make_id: make.id };
          dataArr.push(b);
        }
      });
    return dataArr;
  }
  const onCheckChange = (e: any) => {
    setValue("assetGroup", "undefined", {
      shouldDirty: true,
    });
    dispatch(removeFpAssetGroup([]));
    if (e.target.checked == true) {
      const dataArr = selectAllAssets();
      dataArr.map((item) => dispatch(addFpAssetGroup(item)));
    }
  };

  const handleImage = (imagesData) => {
    const image_url = imagesData?.map((image) => {
      return image?.location;
    });

    setImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);
  useEffect(() => {
    if (actionType == ActionOptions.VIEW) {
      setIsDisabled(true);
    }
  }, [actionType]);

  const theme = useTheme();
  const setQueryString = () => {
    let query_string: string = `page_number=${0}&page_size=${100}`;
    if (filters.name.trimStart() != null && filters.name.trimStart() != "") {
      query_string = query_string.concat(`&make_child=${filters.name}`);
    }
    if (
      filters.description.trimStart() != null &&
      filters.description.trimStart() != ""
    ) {
      query_string = query_string.concat(`&description=${filters.description}`);
    }
    if (filters.code.trimStart() != null && filters.code.trimStart() != "") {
      query_string = query_string.concat(`&code=${filters.code}`);
    }
    return query_string;
  };
  return (
    <>
      <Box theme={theme} sx={{ mt: "24px" }}>
        <Controller
          name="assetGroup"
          control={control}
          render={({ field: { onChange, value } }) => <> </>}
        />

        <DataTableWrap className="table-tabs">
          <Box theme={theme} className="scroll">
            <Box
              className="scroll-hide spr-border sh-fixed-cell"
              theme={theme}
            />
          </Box>
          <DataTable
            theme={theme}
            theadChildren={
              <>
                <TableRow>
                  <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox
                      checkBoxId="assetSelect"
                      theme={theme}
                      checkBoxChecked={
                        allAssets.length === fpAssetGroup?.length &&
                        fpAssetGroup?.length != 0
                      }
                      checkBoxDisabled={isDisabled}
                      onChange={onCheckChange}
                      label={""}
                    />
                  </TableCell>
                  <TableCell className="">Make/Model/Trim</TableCell>
                  <TableCell className="img-cell">
                    <Icon name="ImageIcon" />
                  </TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Code</TableCell>
                </TableRow>
                <TableRow className="filters-row">
                  <TableCell className="checkbox-cell"></TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="makeModelTrim"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(e) => {
                          setFilters({ ...filters, name: e });
                        }}
                        value={filters.name}
                        onBlur={() => {
                          setColumnFilters(setQueryString());
                        }}
                      />
                      <Button
                        theme={theme}
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell className="img-cell"></TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="description"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        onChange={(e) => {
                          setFilters({ ...filters, description: e });
                        }}
                        value={filters.description}
                        onBlur={() => {
                          setColumnFilters(setQueryString());
                        }}
                      ></Input>
                      <Button
                        theme={theme}
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="code"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        onChange={(e) => {
                          setFilters({ ...filters, code: e });
                        }}
                        value={filters.code}
                        onBlur={() => {
                          setColumnFilters(setQueryString());
                        }}
                        type="text"
                      ></Input>
                      <Button
                        theme={theme}
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                {assetMakes?.result &&
                  assetMakes.result.map((item, index) => (
                    <AssetMakeTable
                      key={index}
                      setValue={setValue}
                      assetMake={item}
                      actionType={actionType}
                      deletedAssetGroup={deletedAssetGroup}
                      setDeletedAssetGroup={setDeletedAssetGroup}
                      handleImage={handleImage}
                    />
                  ))}
              </>
            }
          />
        </DataTableWrap>
      </Box>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={images}
        isLoading={false}
        visible={visibility}
        overlayCheck={true}
        onClose={handleClose}
        index={index}
        setIndex={setIndex}
      />
    </>
  );
};

export default AssetGroupTable;
