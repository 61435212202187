import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { IGridProps, Grid, MultiSelect, Select } from "@ntpkunity/controls";
import { IFinancialParameterProps } from "./financial-parameter.type";
import { Controller, useForm } from "react-hook-form";
import { ActionOptions } from "Enums";
import { MultiSelectWrap, CustomGrid } from "@sharedComponents";
import { UseQuery_GetAllBaseRateSource } from "services/charts.service";

const Payment_Frequency = [
  { text: "Monthly", value: "Monthly" },
  { text: "Quarterly", value: "Quarterly" },
  { text: "Semi Annual", value: "SemiAnnual" },
  { text: "Annual", value: "Annual" },
];

const FinancialParameter: FC<IFinancialParameterProps> = ({
  actionType,
  control,
  setValue,
  isDisabledRv,
  setIsDisabledRv,
  getValues,
  errors,
  clearErrors,
  reset,
}) => {
  const theme = useTheme();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [rentalFrequency, setRentalFrequency] = useState([]);
  const { data: sourceData } = UseQuery_GetAllBaseRateSource();

  useEffect(() => {
    if (actionType == ActionOptions.VIEW) {
      setIsDisabled(true);
    }
  }, [actionType]);

  const onChangeRvApplicable = (e) => {
    if (e.target.value == false) {
      setIsDisabledRv(true);
      setValue("financial_product_charts_association.rv_chart_id", null);
      setValue("financial_product_financial_parameter.rv_balloon_type", null);
      setValue(
        "financial_product_financial_parameter.rv_calculation_method",
        null
      );
      setValue(
        "financial_product_financial_parameter.rv_balloon_override",
        null
      );
      clearErrors("financial_product_charts_association.rv_chart_id");
      clearErrors("financial_product_financial_parameter.rv_balloon_type");
      clearErrors(
        "financial_product_financial_parameter.rv_calculation_method"
      );
      clearErrors("financial_product_financial_parameter.rv_balloon_override");
    } else {
      setIsDisabledRv(false);
    }
    setValue(
      "financial_product_financial_parameter.rv_balloon_applicable",
      e?.target?.value,
      {
        shouldDirty: true,
      }
    );
  };

  useEffect(() => {
    const rv_appl = getValues(
      "financial_product_financial_parameter.rv_balloon_applicable"
    );
    if (rv_appl === false) {
      setIsDisabledRv(true);
    } else {
      setIsDisabledRv(false);
    }
  }, []);

  const onConditionSelection = (e) => {
    setRentalFrequency(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  return (
    <>
      {/* <form onSubmit={handleSubmit(onSave)}> */}
      <CustomGrid
        className="main-content-section"
        theme={theme}
        container
        item
        spacing={3}
      >
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <MultiSelectWrap>
            <Controller
              name="financial_product_financial_parameter.rental_frequency"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  id="paymentFrequency"
                  theme={theme}
                  label={"Payment Frequency"}
                  placeholder={"Payment Frequency"}
                  items={Payment_Frequency?.map((type) => {
                    return { value: type.value, text: type.text };
                  })}
                  value={value ?? []}
                  onChange={onChange}
                  disabled={isDisabled}
                  selectError={
                    errors?.financial_product_financial_parameter
                      ?.rental_frequency?.message as never
                  }
                />
              )}
            />
          </MultiSelectWrap>
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.interest_type"
            control={control}
            defaultValue={1}
            render={({ field: { value, onChange } }) => (
              <Select
                id="interestType"
                theme={theme}
                label={"Margin Rate Type"}
                placeholder={"Select"}
                items={[{ text: "Fixed", value: 1 }]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.base_rate_source"
            control={control}
            render={({ field }) => (
              <Select
                id="baseRateSource"
                theme={theme}
                label={"Base Rate Source (Optional)"}
                placeholder={"Select"}
                {...field}
                value={field.value}
                items={sourceData?.map((source) => {
                  return {
                    text: source.title,
                    value: source.id,
                  };
                })}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.interest_rate_override"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="interestRateOverride"
                theme={theme}
                label={"Margin Rate Override"}
                placeholder={"Select"}
                items={[
                  { text: "Yes", value: true },
                  { text: "No", value: false },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.financial_product_financial_parameter
                    ?.interest_rate_override?.message as never
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.calculation_method"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="rentalCalculationMethod"
                theme={theme}
                label={"Rental Calculation Method"}
                placeholder={"Select"}
                items={[
                  { text: "Annuity", value: "annuity" },
                  { text: "Flat", value: "flat" },
                  { text: "Flat Plus", value: "flat plus" },
                  { text: "Equal Principal", value: "equal principal" },
                  {
                    text: "Annuity - Actual/360",
                    value: "annuity - actual/360",
                  },
                  {
                    text: "Annuity - Actual/365",
                    value: "annuity - actual/365",
                  },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.financial_product_financial_parameter
                    ?.calculation_method?.message as never
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.rental_mode"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="=rentalMode"
                theme={theme}
                label={"Rental Mode"}
                placeholder={"Select"}
                items={[
                  { text: "Advance", value: "Advance" },
                  { text: "Arrear", value: "Arrear" },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.financial_product_financial_parameter?.rental_mode
                    ?.message as never
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.rate_conversion_method"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="rateConversionMethod"
                theme={theme}
                label={"Margin Rate Conversion Method"}
                placeholder={"Select"}
                items={[
                  { text: "Simple", value: "simple" },
                  { text: "Compounded", value: "compounded" },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.financial_product_financial_parameter
                    ?.rate_conversion_method?.message as never
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}></Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.rv_balloon_applicable"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="rvBalloonApplicable"
                theme={theme}
                label={"RV/Balloon Applicable"}
                placeholder={"Select"}
                items={[
                  { text: "Yes", value: true },
                  { text: "No", value: false },
                ]}
                value={value ?? ""}
                onChange={(e) => {
                  onChangeRvApplicable(e);
                  onChange(e);
                }}
                disabled={isDisabled}
                selectError={
                  errors?.financial_product_financial_parameter
                    ?.rv_balloon_applicable?.message
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.rv_balloon_type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="rvBalloonType"
                theme={theme}
                label={"RV/Balloon Type"}
                placeholder={"Select"}
                items={[
                  { text: "RV", value: "rv" },
                  { text: "Balloon", value: "balloon" },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled || isDisabledRv}
                selectError={
                  errors?.financial_product_financial_parameter?.rv_balloon_type
                    ?.message as never
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.rv_calculation_method"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="rvBalloonCalculationMethod"
                theme={theme}
                label={"RV/Balloon Calculation Method"}
                placeholder={"Select"}
                items={[
                  { text: "Asset Cost inl. Tax", value: "inclusive" },
                  { text: "Asset Cost excl. Tax", value: "exclusive" },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled || isDisabledRv}
                selectError={
                  errors?.financial_product_financial_parameter
                    ?.rv_calculation_method?.message as never
                }
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} lg={3} md={6}>
          <Controller
            name="financial_product_financial_parameter.rv_balloon_override"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="rvBalloonOverride"
                theme={theme}
                label={"RV/Balloon Override"}
                placeholder={"Select"}
                items={[
                  { text: "Yes", value: true },
                  { text: "No", value: false },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled || isDisabledRv}
                selectError={
                  errors?.financial_product_financial_parameter
                    ?.rv_balloon_override?.message as never
                }
              />
            )}
          />
        </Grid>
      </CustomGrid>
      {/* </form> */}
    </>
  );
};
export default FinancialParameter;
