import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { ActionOptions, QueryKeys } from "Enums";
import {
  IAnnualMileage,
  IAssetCondition,
  IFinancedAmount,
  IRvChart,
  IRvChartCriteria,
  ITerms,
} from "Interface";
import ChartTable from "./chart-table";
import { AddNewRvCharts } from "components";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  UseQuery_GetAllAssetConditions,
  UseQuery_GetAllTerms,
  UseQuery_getAllMileage,
  UseQuery_getAllFinancedAmount,
} from "services";
import { useQueryClient } from "react-query";

const RvCharts: FC = () => {
  UseQuery_GetAllAssetConditions();
  UseQuery_GetAllTerms();
  UseQuery_getAllMileage();
  UseQuery_getAllFinancedAmount();

  const [rvChartState, setRvChartState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Annual Mileage"
  );
  const [state, dispatch] = useSetupsStore();
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD_NEW_CHART
  );
  const [actionData, setActionData] = useState<IRvChartCriteria>(undefined);
  const queryClient = useQueryClient();
  const AllAssetConditions: Array<IAssetCondition> = queryClient.getQueryData(
    QueryKeys.Get_All_Asset_Conditions
  );
  const AllTerms: Array<ITerms> = queryClient.getQueryData(
    QueryKeys.GET_ALL_TERMS
  );
  const AllAnnualMileage: Array<IAnnualMileage> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ANNUAL_MILEAGES
  );

  const AllFinancedAmounts: Array<IFinancedAmount> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCED_AMOUNTS
  );

  useEffect(() => {
    if (rvChartState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [rvChartState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New RV/Balloon Chart");
        setActionData(value);
        setRvChartState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onOptionsClick = (key, value) => {
    switch (key) {
      case ActionOptions.ADD_NEW_CHART:
        const criteriaData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Criteria");
        setActionData(criteriaData);
        dispatch(setOpenDrawer(true));
        setRvChartState(true);
        return;
      case ActionOptions.RENAME_CHART:
        const editData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.RENAME_CHART);
        setActionTitle("Edit Chart");
        setActionData(editData);
        dispatch(setOpenDrawer(true));
        setRvChartState(true);
        return;
      case ActionOptions.ADD_CRITERIA:
        const data = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_CRITERIA);
        setActionTitle("Add Criteria");
        setActionData(data);
        dispatch(setOpenDrawer(true));
        setRvChartState(true);
        return;
      default:
        return;
    }
  };
  const onEditCriteria = (data) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit RV/Balloon Criteria");
    setActionData(data);
    dispatch(setOpenDrawer(true));
    setRvChartState(true);
  };
  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
        </Box>
        <ChartTable
          onOptionsClick={(key, value) => onOptionsClick(key, value)}
          onEditCriteria={(data) => onEditCriteria(data)}
          AllAssetConditions={AllAssetConditions}
          AllTerms={AllTerms}
          AllAnnualMileage={AllAnnualMileage}
          // AllFinancedAmounts={AllFinancedAmounts}
        ></ChartTable>
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Chart"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {rvChartState && (
          <AddNewRvCharts
            openPopUp={rvChartState}
            setPopUpState={setRvChartState}
            title={actionTitle}
            actionType={actionType}
            rvChartData={actionData}
            AllAnnualMileage={AllAnnualMileage}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default RvCharts;
