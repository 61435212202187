import { useState, useEffect, useRef } from "react";
import {
  useTheme,
  Popper,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import {
  CREATE_TEMPLATE_ENDPOINT,
  EDIT_TEMPLATE_ENDPOINT,
} from "shared/config/constants";
import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  Typography,
  Tooltip,
} from "@ntpkunity/controls";
import { DataTableWrap, IFrame } from "@sharedComponents";
import { AddNewTemplate } from "components";
import { IStationery } from "Interface";
import { ActionOptions } from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import StationeryTable from "./template-table";
import { UseQuery_getAllStates } from "services/dealer-configurations.service";
import { useGetLenderintegrationByIntegrationType } from "services/dealer-integration.service";
import { styled } from "@mui/material/styles";

const Stationery = () => {
  UseQuery_getAllStates();
  const { mutate: getLenderIntegrations, data: lenderIntegrations } =
    useGetLenderintegrationByIntegrationType();
  const [stationeryState, setStationeryState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Template");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const anchorRef = useRef<HTMLDivElement>(null);
  const [esigantureintegration, setEsignatureIntegrations] = useState(false);
  const [actionData, setActionData] = useState<IStationery>(undefined);
  const [openGroupBtn, setOpenGroupBtn] = useState(false);
  const [openIFrame, setOpenIFrame] = useState(false);
  const [state, dispatch] = useSetupsStore();
  const [open, setOpen] = useState(false);
  const [IFrameLink, setIFrameLink] = useState("");

  const theme = useTheme();

  const MenuItemCenter = styled(
    MenuItem,
    {}
  )<any>(({ theme }) => ({
    color: theme.palette.common.black,
    ".MuiTooltip-popper": {
      width: 250,
      right: "10px !important",
      ".MuiTooltip-tooltip": {
        maxWidth: "100%",
        whiteSpace: "break-spaces",
        display: "inline-block",
      },
    },
  }));

  const CustomButton = styled(
    "div",
    {}
  )<any>(({ theme }) => ({
    "&.custom-buttons": {
      position: "relative",
      ".button-group": {
        ".group-btn": {
          width: "auto",
          padding: "12px 24px",
          cursor: "pointer",
          "&:not(:last-of-type)": {
            flexGrow: 1,
          },
          "&.only-icon": {
            padding: 12,
            "&.btn-secondary": {
              svg: {
                path: {
                  stroke: theme.palette.grey[900],
                },
              },
              "&:hover": {
                svg: {
                  path: {
                    stroke: theme.palette.common.white,
                  },
                },
                "&:focus": {
                  svg: {
                    path: {
                      stroke: theme.palette.common.white,
                    },
                  },
                },
              },
              "&:focus": {
                svg: {
                  path: {
                    stroke: theme.palette.common.black,
                  },
                },
              },
              "&:focus-visible": {
                svg: {
                  path: {
                    stroke: theme.palette.common.white,
                  },
                },
              },
              "&:disabled": {
                svg: {
                  path: {
                    stroke: theme.palette.grey[300],
                  },
                },
              },
            },
          },
          "&.btn-secondary": {
            backgroundColor: "transparent",
            borderColor: theme.palette.grey[300],
            color: theme.palette.grey[900],
            "&:hover": {
              backgroundColor: theme.palette.grey[900],
              borderColor: theme.palette.grey[900],
              color: theme.palette.common.white,
            },
            "&:disabled": {
              borderColor: theme.palette.grey[100],
              backgroundColor: "transparent",
              color: theme.palette.grey[300],
            },
            "&.disabled": {
              borderColor: theme.palette.grey[100],
              backgroundColor: "transparent",
              color: theme.palette.grey[300],
            },
          },
        },
      },
      ".u-dropdown-menu": {
        ".MuiPaper-root": {
          borderStyle: "solid",
          borderWidth: 1,
          borderColor:
            theme.palette.mode === "dark"
              ? [theme.palette.common.black]
              : theme.palette.grey[50],
          boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
          minWidth: 145,
          padding: 8,
          color: theme.palette.grey[600],
          scrollbarWidth: "thin",
          ".MuiList-root": {
            padding: 0,
          },
          "&::-webkit-scrollbar": {
            width: 7,
            height: 7,
          },
          "&::-webkit-scrollbar-track": {
            padding: "0 1px",
            backgroundColor: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            transition: "all 0.3s",
            backgroundColor: "#cdcdcd",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#a6a6a6",
          },
        },
        ".u-dropdown-item": {
          padding: "12px 24px 12px 16px",
          borderRadius: theme.shape.borderRadius,
          // backgroundColor: theme.palette.common.white,
          color: theme.palette.grey[600],

          "&:hover, &.Mui-selected": {
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[100]
                : "#F2F4FA",
            color:
              theme.palette.mode === "dark"
                ? theme.palette.common.white
                : theme.palette.grey[900],
          },

          "&.Mui-disabled": {
            opacity: 1,
            pointerEvents: "initial",
            cursor: "not-allowed",
            color: theme.palette.grey[300],

            "&:hover": {
              backgroundColor: "transparent",
              color: theme.palette.grey[300],
            },
          },

          ".MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
    },
  }));

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpenGroupBtn((prevOpen) => !prevOpen);
  };

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Template Configuration");
        setActionData(value);
        setStationeryState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const handleOpenCreateIFrame = () => {
    setOpenIFrame(true);
    setIFrameLink(process.env.DOCK_BASE_URL + CREATE_TEMPLATE_ENDPOINT);
  };

  const handleOpenEditIFrame = () => {
    setOpenIFrame(true);
    setIFrameLink(process.env.DOCK_BASE_URL + EDIT_TEMPLATE_ENDPOINT);
  };

  useEffect(() => {
    if (stationeryState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [stationeryState]);

  useEffect(() => {
    getLenderIntegrations();
  }, []);

  useEffect(() => {
    if (lenderIntegrations?.data) {
      if (lenderIntegrations?.data?.is_active) {
        setEsignatureIntegrations(lenderIntegrations?.data?.is_active);
      }
    }
  }, [lenderIntegrations]);

  const onEdit = (formData: IStationery) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Template Configuration");
    setActionData(formData);
    setStationeryState(true);
    dispatch(setOpenDrawer(true));
  };

  return (
    <>
      {openIFrame ? (
        <IFrame setOpenIFrame={setOpenIFrame} IFrameLink={IFrameLink} />
      ) : (
        <DataTableWrap className="table-tabs-pagination-button" key={1}>
          <Box theme={theme} className="scroll">
            <Box className="scroll-hide spr-border" theme={theme} />
          </Box>
          <StationeryTable onEdit={(data) => onEdit(data)} />
          <CustomButton
            theme={theme}
            className="custom-buttons"
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr" },
              gap: 1,
            }}
            onClose={handleClose}
          >
            <ButtonGroup
              theme={theme}
              className="button-group"
              variant="outlined"
              ref={anchorRef}
              aria-label="split button"
              fullWidth
            >
              <Button
                theme={theme}
                secondary
                className="group-btn btn-secondary"
                onClick={(e) =>
                  handleSelection(e, ActionOptions.ADD, undefined)
                }
                text="Add New Template Configuration"
              />
              <Button
                theme={theme}
                secondary
                className="group-btn btn-secondary only-icon"
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                iconText={<Icon name="ChevronDown" />}
              />
            </ButtonGroup>
            <Popper
              className="u-dropdown-menu"
              open={openGroupBtn}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{
                position: "absolute",
                top: "auto",
                left: "auto",
                right: 0,
                bottom: 53,
                zIndex: 1200,
              }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleToggle}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        <MenuItemCenter
                          className="u-dropdown-item"
                          onClick={handleOpenCreateIFrame}
                          sx={{ display: "flex", justifyContent: "left" }}
                        >
                          <Tooltip
                            disablePortal
                            theme={theme}
                            placement="left"
                            title=""
                          >
                            <div>
                              <Typography theme={theme} component={""}>
                                Add New Template
                              </Typography>
                            </div>
                          </Tooltip>
                        </MenuItemCenter>
                        <MenuItemCenter
                          className="u-dropdown-item"
                          onClick={handleOpenEditIFrame}
                          sx={{ display: "flex", justifyContent: "left" }}
                        >
                          <Tooltip
                            disablePortal
                            theme={theme}
                            placement="left"
                            title=""
                          >
                            <div>
                              <Typography theme={theme} component={""}>
                                Edit Template
                              </Typography>
                            </div>
                          </Tooltip>
                        </MenuItemCenter>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </CustomButton>
          {stationeryState && (
            <AddNewTemplate
              openPopUp={stationeryState}
              setPopUpState={setStationeryState}
              title={actionTitle}
              actionType={actionType}
              stationeryData={actionData}
              esigantureintegration={esigantureintegration}
            />
          )}
        </DataTableWrap>
      )}
    </>
  );
};

export default Stationery;
