import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { AddNewContractAllowedAnnumMileage } from "components";
import { ActionOptions } from "Enums";
import { IContractAllowedAnnumMileage } from "Interface";
import ContractAllowedAnnumMileageTable from "./contract-allowed-annum-mileage-table";
import { IContractAllowedAnnumMileageProps } from "./contract-allowed-annum-mileage.type";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

const ContractAllowedAnnumMileage: FC<
  IContractAllowedAnnumMileageProps
> = () => {
  const [
    contractAllowedAnnumMileageState,
    setContractAllowedAnnumMileageState,
  ] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Contract Allowed Annum Mileages"
  );
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] =
    useState<IContractAllowedAnnumMileage>(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (contractAllowedAnnumMileageState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [contractAllowedAnnumMileageState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Contract Annual Mileage");
        setActionData(value);
        setContractAllowedAnnumMileageState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IContractAllowedAnnumMileage) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Contract Annual Mileage");
    setActionData(formData);
    setContractAllowedAnnumMileageState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <ContractAllowedAnnumMileageTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Contract Annual Mileage"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {contractAllowedAnnumMileageState && (
          <AddNewContractAllowedAnnumMileage
            openPopUp={contractAllowedAnnumMileageState}
            setPopUpState={setContractAllowedAnnumMileageState}
            title={actionTitle}
            actionType={actionType}
            contractAllowedAnnumMileageData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default ContractAllowedAnnumMileage;
