import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { IBoxProps } from "@ntpkunity/controls";
import { Grid, Box, TextBox, Input } from "@sharedComponents";
import { styled } from "@mui/material/styles";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
import { useTheme } from "@mui/material";

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.tab-wrap": {
    width: "100%",
    display: "inline-block",

    hr: {
      opacity: 0.4,
    },

    ".title-icon": {
      width: "100%",
      display: "inline-block",
      "span, Icon": {
        float: "left",
      },
      span: {
        marginRight: 10,
      },
    },
    ".buttons-row": {
      display: "inline-block",
      textAlign: "center",
      width: "100%",

      Button: {
        margin: "0 20px 20px 0",

        "&:last-of-type": {
          marginRight: 0,
        },
      },
    },
  },
}));

const Payment: FC<{
  form: any;
}> = ({ form }) => {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = form;

  const queryClient = useQueryClient();
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  function validate() {
    if (getValues("payment.account_number").length > 20) {
      setError("payment.account_number", {
        message: "Account Number should be upto 20 digits",
      });
    } else if (getValues("payment.account_number").length <= 20) {
      clearErrors("payment.account_number");
    }
    if (getValues("payment.aba_number").length > 9) {
      setError("payment.aba_number", {
        message: "ABA Number should be upto 9 digits",
      });
    } else if (getValues("payment.aba_number").length <= 9) {
      clearErrors("payment.aba_number");
    }
  }

  return (
    <TabWrap className="tab-wrap">
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mb: 2.7 }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.account_number"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                label={"Account Number"}
                {...field}
                value={field.value ?? ""}
                type="number"
                inputMask="00000000000000000000"
                format="#####################"
                onBlur={validate}
                error={errors?.payment?.account_number?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.aba_number"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                label={"ABA Number"}
                {...field}
                value={field.value ?? ""}
                type="number"
                onBlur={validate}
                // inputMask="000000000"
                // maxLength="20"
                // pattern="[1-9]{0}[0-9]{20}"
                // max={100}
                // format="#########"
                error={errors?.payment?.aba_number?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.account_title"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                label={"Account Title"}
                {...field}
                value={field.value?.trimStart()}
                type="text"
                error={errors?.payment?.account_title?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.bank_name"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                label={"Bank Name"}
                {...field}
                value={field.value?.trimStart()}
                type="text"
                error={errors?.payment?.bank_name?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.bank_account_limit"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <TextBox
                theme={theme}
                label="Bank Account Limit"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                type="text"
                startAdornment={
                  <>
                    <span className="adornment-text">{defaultCurrency}</span>
                  </>
                }
                masking
                scale={2}
                numeric
                error={errors?.payment?.bank_account_limit?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.credit_card_limit"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <TextBox
                theme={theme}
                label="Credit Card Limit"
                type="text"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                startAdornment={
                  <>
                    <span className="adornment-text">{defaultCurrency}</span>
                  </>
                }
                masking
                scale={2}
                numeric
                error={errors?.payment?.credit_card_limit?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.debit_card_limit"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <TextBox
                theme={theme}
                label="Debit Card Limit"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                type="text"
                startAdornment={
                  <>
                    <span className="adornment-text">{defaultCurrency}</span>
                  </>
                }
                masking
                scale={2}
                numeric
                error={errors?.payment?.debit_card_limit?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </TabWrap>
  );
};
export default Payment;
