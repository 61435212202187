import { FC, useState, useEffect, useMemo, useContext } from "react";
import { useTheme } from "@mui/material";
import { LayoutWithSideNav } from "layout";
import { Typography, Grid, Button, Icon } from "@ntpkunity/controls";

import { DrawerWrap, PageHeader } from "@sharedComponents";
import {
  FinancialInsuranceTable,
  NewFinancialInsurance,
} from "modules/financial-insurance";
import { IFinancialInsurance, IImage } from "Interface";
import { ActionOptions } from "Enums";
import { useForm } from "react-hook-form";
import { DealerContext } from "context";

const FinancialInsurance: FC = () => {
  const theme = useTheme();
  const { dealer_code } = useContext(DealerContext);
  const [popUpState, setPopUpState] = useState(false);
  const [enabledPin, setEnabledPin] = useState(false);
  const [images, setImages] = useState<Array<IImage>>([]);

  const [actionTitle, setActionTitle] = useState<string>("Add New Product");

  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IFinancialInsurance>();

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setPopUpState(true);
        setActionTitle("Add New Product");
        setActionData(value);
        setImages([]);
        form.reset({} as IFinancialInsurance);
      default:
        return;
    }
  };

  const defaultValues: IFinancialInsurance = useMemo(() => {
    const updatedFormDefaultValues = actionData;
    setActionData(updatedFormDefaultValues);
    return actionData;
  }, [actionData]) as unknown as IFinancialInsurance;

  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      form.reset({ ...defaultValues });
    } else {
      form.reset();
    }
  }, [defaultValues]);

  useEffect(() => {
    if (popUpState == false) {
      form.reset();
    }
  }, [popUpState]);

  const form: {
    handleSubmit: any;
    reset: any;
  } = useForm<IFinancialInsurance>();

  return (
    <DrawerWrap open={popUpState}>
      <LayoutWithSideNav theme={theme}>
        <PageHeader
          className="main-page-header border-bottom"
          theme={theme}
          container
          item
          spacing={2}
        >
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
              <Typography variant="h2" component="h2" theme={theme}>
                F&amp;I Products
              </Typography>
            </Grid>
            <Grid
              theme={theme}
              item
              lg={5}
              md={6}
              sm={12}
              xs={12}
              textAlign="right"
            >
              <Button
                theme={theme}
                primary
                id="AddNewProduct"
                text={"Add New Product"}
                startIcon={<Icon name="AddIcon" />}
                onClick={(e) =>
                  handleSelection(e, ActionOptions.ADD, undefined)
                }
              />
            </Grid>
          </Grid>
        </PageHeader>

        <form onSubmit={(e) => e.preventDefault()}>
          {dealer_code && (
            <NewFinancialInsurance
              popUpState={popUpState}
              setPopUpState={setPopUpState}
              title={actionTitle}
              actionType={actionType}
              financialInsuranceData={actionData}
              form={form}
              enabledPin={enabledPin}
              dealer_code={dealer_code}
              setImages={setImages}
              images={images}
            ></NewFinancialInsurance>
          )}
        </form>

        {dealer_code && (
          <FinancialInsuranceTable
            setPopUpState={setPopUpState}
            setActionData={setActionData}
            setActionType={setActionType}
            setActionTitle={setActionTitle}
            actionData={actionData}
            dealer_code={dealer_code}
          ></FinancialInsuranceTable>
        )}
      </LayoutWithSideNav>
    </DrawerWrap>
  );
};

export default FinancialInsurance;
