import { FC, useEffect, useRef, useState } from "react";
import {
  useTheme,
  Paper,
  MenuItem,
  ClickAwayListener,
  MenuList,
  Grow,
  Popper,
} from "@mui/material";
import {
  Grid,
  Typography,
  ButtonGroup,
  Button,
  Dialog,
  Icon,
  Tooltip,
} from "@ntpkunity/controls";
import { AddNewAssetMake, NewAssetModel, NewAssetTrim } from "components";
import { styled } from "@mui/system";
import { IAssetMake, IAssetModel, IAssetTrim } from "Interface";
import { ActionOptions, ActionTypes, AssetButtonGroupOptions } from "Enums";
import AssetCatalogueTable from "./asset-catalogue-table";
import AssetCataloguePopup from "./asset-catalogue-popup";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import {
  setAssetMakeInitialData,
  setOpenDrawer,
} from "Stores/SetupStore/Actions";
import { TEMPLATE_EXCEL_FILE_ENDPOINT } from "shared/config/constants";
import { useMutation_BulkAddAssetCatalogue } from "services/asset-trim.service";
import { useMutation_CheckS3Credentials } from "services";
const MenuItemCenter = styled(
  MenuItem,
  {}
)<any>(({ theme }) => ({
  color: theme.palette.common.black,
  ".MuiTooltip-popper": {
    width: 250,
    right: "10px !important",
    ".MuiTooltip-tooltip": {
      maxWidth: "100%",
      whiteSpace: "break-spaces",
      display: "inline-block",
    },
  },
}));

const CustomButton = styled(
  "div",
  {}
)<any>(({ theme }) => ({
  "&.custom-buttons": {
    position: "relative",
    ".button-group": {
      ".group-btn": {
        width: "auto",
        padding: "12px 24px",
        cursor: "pointer",
        "&:not(:last-of-type)": {
          flexGrow: 1,
        },
        "&.only-icon": {
          padding: 12,
          "&.btn-secondary": {
            svg: {
              path: {
                stroke: theme.palette.grey[900],
              },
            },
            "&:hover": {
              svg: {
                path: {
                  stroke: theme.palette.common.white,
                },
              },
              "&:focus": {
                svg: {
                  path: {
                    stroke: theme.palette.common.white,
                  },
                },
              },
            },
            "&:focus": {
              svg: {
                path: {
                  stroke: theme.palette.common.white,
                },
              },
            },
            "&:focus-visible": {
              svg: {
                path: {
                  stroke: theme.palette.common.white,
                },
              },
            },
            "&:disabled": {
              svg: {
                path: {
                  stroke: theme.palette.grey[300],
                },
              },
            },
          },
        },
        "&.btn-secondary": {
          backgroundColor: "transparent",
          borderColor: theme.palette.grey[300],
          color: theme.palette.grey[900],
          "&:hover": {
            backgroundColor: theme.palette.grey[900],
            borderColor: theme.palette.grey[900],
            color: theme.palette.common.white,
          },
          "&:disabled": {
            borderColor: theme.palette.grey[100],
            backgroundColor: "transparent",
            color: theme.palette.grey[300],
          },
          "&.disabled": {
            borderColor: theme.palette.grey[100],
            backgroundColor: "transparent",
            color: theme.palette.grey[300],
          },
        },
      },
    },
    ".u-dropdown-menu": {
      ".MuiPaper-root": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor:
          theme.palette.mode === "dark"
            ? [theme.palette.common.black]
            : theme.palette.grey[50],
        boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
        minWidth: 145,
        padding: 8,
        color: theme.palette.grey[600],
        "@supports (-moz-appearance:none)": {
          scrollbarWidth: "thin",
        },
        ".MuiList-root": {
          padding: 0,
        },
        "&::-webkit-scrollbar": {
          width: 7,
          height: 7,
        },
        "&::-webkit-scrollbar-track": {
          padding: "0 1px",
          backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
          transition: "all 0.3s",
          backgroundColor: "#cdcdcd",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#a6a6a6",
        },
      },
      ".u-dropdown-item": {
        padding: "12px 24px 12px 16px",
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[600],

        "&:hover, &.Mui-selected": {
          backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[100] : "#F2F4FA",
          color:
            theme.palette.mode === "dark"
              ? theme.palette.common.white
              : theme.palette.grey[900],
        },

        "&.Mui-disabled": {
          opacity: 1,
          pointerEvents: "initial",
          cursor: "not-allowed",
          color: theme.palette.grey[300],

          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.grey[300],
          },
        },

        ".MuiTouchRipple-root": {
          display: "none",
        },
      },
    },
  },
}));

const options = [
  AssetButtonGroupOptions.ADD_NEW_MODEL,
  AssetButtonGroupOptions.ADD_NEW_TRIM,
  AssetButtonGroupOptions.DOWNLOAD_TEMPLATE,
  AssetButtonGroupOptions.UPLOAD_FILE,
];

const AssetCatalogue: FC = () => {
  const [open, setOpen] = useState(false);
  const { actions } = useStoreContext();
  const anchorRef = useRef<HTMLDivElement>(null);
  const hiddenFileInput = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [state, dispatch] = useSetupsStore();
  const { mutate: AddAssetCatalogue } = useMutation_BulkAddAssetCatalogue();
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  const [assetData, setAssetData] = useState(null);
  const [assetTrimState, setAssetTrimState] = useState<boolean>(false);
  const [assetModelState, setAssetModelState] = useState<boolean>(false);
  const [assetMakeState, setAssetMakeState] = useState<boolean>(false);
  const [makeActionTitle, setMakeActionTitle] =
    useState<string>("Add New Make");
  const [modelActionTitle, setModelActionTitle] =
    useState<string>("Add New Model");
  const [trimActionTitle, setTrimActionTitle] =
    useState<string>("Add New Trim");

  const [makeActionData, setMakeActionData] = useState<IAssetMake>(undefined);
  const [modelActionData, setModelActionData] =
    useState<IAssetModel>(undefined);
  const [trimActionData, setTrimActionData] = useState<IAssetTrim>(undefined);
  const [openAddMoreOptionDialog, setOpenAddMoreOptionDialog] = useState(false);

  const [ActionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  useEffect(() => {
    checkS3Credentials(JSON.parse(localStorage.getItem("settings"))?.tenant_id);
  }, []);
  const handleClick = () => {
    setMakeActionTitle("Add New Make");
    setAssetModelState(false);
    setAssetTrimState(false);
    setActionType(ActionOptions.ADD);
    setMakeActionData(undefined);
    setAssetMakeState(true);
    dispatch(setOpenDrawer(true));
    return;
  };
  const handleMenuItemClick = (index) => {
    switch (options[index]) {
      case AssetButtonGroupOptions.ADD_NEW_MODEL:
        setOpen(false);
        setAssetMakeState(false);
        setAssetTrimState(false);
        setModelActionTitle("Add New Model");
        setActionType(ActionOptions.ADD);
        setModelActionData(undefined);
        setAssetModelState(true);
        dispatch(setOpenDrawer(true));
        return;
      case AssetButtonGroupOptions.ADD_NEW_TRIM:
        setOpen(false);
        setAssetMakeState(false);
        setAssetModelState(false);
        setTrimActionTitle("Add New Trim");
        setActionType(ActionOptions.ADD);
        setTrimActionData(undefined);
        setAssetTrimState(true);
        dispatch(setOpenDrawer(true));
        return;
      case AssetButtonGroupOptions.DOWNLOAD_TEMPLATE:
        window.location.href = `${
          process.env.DMS_BUCKET_BASE_URL + TEMPLATE_EXCEL_FILE_ENDPOINT
        }`;
        actions.setToast({
          toastMessage: "File Downloading, Please wait.",
          toastState: true,
        });
        return;
      case AssetButtonGroupOptions.UPLOAD_FILE:
        if (s3Response?.data) handleBtn(index);
        return;

      default:
        return;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleBtn = (e) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    setOpenAddMoreOptionDialog(true);
    const obj: any = {
      tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
    };
    const body = new FormData();
    body.append("files", event.target.files[0]);
    obj.files = body;
    AddAssetCatalogue(obj, {
      onSuccess: (response) => {
        setAssetData(response.data);
      },
      onError: (error) => {
        // if (error?.response?.data?.detail?.code === 400) {
        //   setError("code", { message: "Code should be unique" });
        //   return;
        // }
      },
    });
    event.target.value = null;
  };
  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (
      assetMakeState === false &&
      assetModelState === false &&
      assetTrimState === false
    ) {
      dispatch(setOpenDrawer(false));
    }
  }, [assetMakeState, assetModelState, assetTrimState]);

  const theme = useTheme();
  const onEditAssetMake = (data: IAssetMake) => {
    setAssetModelState(false);
    setAssetTrimState(false);
    setActionType(ActionOptions.EDIT);
    setMakeActionTitle("Edit Make");
    setMakeActionData(data);
    setAssetMakeState(true);
    dispatch(setOpenDrawer(true));
  };
  const onEditAssetModel = (data: IAssetModel) => {
    setAssetMakeState(false);
    setAssetTrimState(false);
    setActionType(ActionOptions.EDIT);
    setModelActionTitle("Edit Model");
    setModelActionData(data);
    setAssetModelState(true);
    dispatch(setOpenDrawer(true));
  };
  const onEditAssetTrim = (data: IAssetTrim) => {
    setAssetModelState(false);
    setAssetMakeState(false);
    setActionType(ActionOptions.EDIT);
    setTrimActionTitle("Edit Trim");
    setTrimActionData(data);
    setAssetTrimState(true);
    dispatch(setOpenDrawer(true));
  };
  const onAddAssetModel = (data: IAssetMake) => {
    const modelData = {} as IAssetModel;
    modelData.make_id = data.id;
    setAssetMakeState(false);
    setAssetTrimState(false);
    setActionType(ActionOptions.ADD_MODEL);
    setModelActionTitle("Add New Model");
    setModelActionData(modelData);
    setAssetModelState(true);
    dispatch(setOpenDrawer(true));
  };
  const onAddAssetTrim = (data: IAssetMake) => {
    const trimData = {} as IAssetTrim;
    trimData.make_id = data.id;
    setAssetModelState(false);
    setAssetMakeState(false);
    setActionType(ActionOptions.ADD_TRIM);
    setTrimActionTitle("Add New Trim");
    setTrimActionData(trimData);
    setAssetTrimState(true);
    dispatch(setOpenDrawer(true));
  };
  const onAddAssetModelTrim = (data: IAssetModel) => {
    const trimData = {} as IAssetTrim;
    trimData.make_id = data.make_id;
    trimData.model_id = data.id;
    setAssetModelState(false);
    setAssetMakeState(false);
    setActionType(ActionOptions.ADD_TRIM);
    setTrimActionTitle("Add New Trim");
    setTrimActionData(trimData);
    setAssetTrimState(true);
    dispatch(setOpenDrawer(true));
  };

  return (
    <>
      <Grid theme={theme} xs={12} md={12}>
        <AssetCatalogueTable
          onEditAssetMake={(data) => onEditAssetMake(data)}
          onEditAssetModel={(data) => onEditAssetModel(data)}
          onEditAssetTrim={(data) => onEditAssetTrim(data)}
          onAddAssetModel={(data) => onAddAssetModel(data)}
          onAddAssetTrim={(data) => onAddAssetTrim(data)}
          onAddAssetModelTrim={(data) => onAddAssetModelTrim(data)}
        />
        <Button onClick={handleBtn}>Upload Image</Button>
      </Grid>
      <input
        type="file"
        accept=".xls,.xlsx"
        id="AssetCatalogueUploadId"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onChange={handleChange}
      />

      <CustomButton
        theme={theme}
        className="custom-buttons"
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr" },
          gap: 1,
        }}
        onClose={handleClose}
      >
        <ButtonGroup
          theme={theme}
          className="button-group"
          variant="outlined"
          ref={anchorRef}
          aria-label="split button"
          fullWidth
        >
          <Button
            theme={theme}
            secondary
            className="group-btn btn-secondary"
            onClick={handleClick}
            text="Add New Make"
          />

          <Button
            theme={theme}
            secondary
            className="group-btn btn-secondary only-icon"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            iconText={<Icon name="ChevronDown" />}
          />
        </ButtonGroup>
        <Popper
          className="u-dropdown-menu"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            position: "absolute",
            top: "auto",
            left: "auto",
            right: 0,
            bottom: 53,
            zIndex: 1200,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItemCenter
                        disabled={
                          option === AssetButtonGroupOptions.UPLOAD_FILE &&
                          !s3Response?.data
                        }
                        className="u-dropdown-item"
                        key={option}
                        selected={index === selectedIndex}
                        onClick={() => handleMenuItemClick(index)}
                        sx={{ display: "flex", justifyContent: "left" }}
                      >
                        {option === AssetButtonGroupOptions.UPLOAD_FILE &&
                        !s3Response?.data ? (
                          <Tooltip
                            disablePortal
                            theme={theme}
                            placement="left"
                            title={ActionTypes.STORAGE_PATH_NOT_CONFIGURED}
                          >
                            <div>
                              <Typography theme={theme} component={""}>
                                {option}
                              </Typography>
                            </div>
                          </Tooltip>
                        ) : (
                          <Typography theme={theme} component={""}>
                            {option}
                          </Typography>
                        )}
                      </MenuItemCenter>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </CustomButton>
      {assetMakeState && (
        <AddNewAssetMake
          openPopUp={assetMakeState}
          setPopUpState={setAssetMakeState}
          title={makeActionTitle}
          actionType={ActionType}
          assetMakeData={makeActionData}
        />
      )}
      {assetModelState && (
        <NewAssetModel
          openPopUp={assetModelState}
          setPopUpState={setAssetModelState}
          title={modelActionTitle}
          actionType={ActionType}
          assetModelData={modelActionData}
        />
      )}
      {assetTrimState && (
        <NewAssetTrim
          openPopUp={assetTrimState}
          setPopUpState={setAssetTrimState}
          title={trimActionTitle}
          actionType={ActionType}
          assetTrimData={trimActionData}
          s3Response={s3Response}
        />
      )}
      {openAddMoreOptionDialog && (
        <AssetCataloguePopup
          openPopup={openAddMoreOptionDialog}
          assetData={assetData}
          setAssetData={setAssetData}
          setopenAssetDialog={setOpenAddMoreOptionDialog}
        ></AssetCataloguePopup>
      )}
    </>
  );
};
export default AssetCatalogue;
