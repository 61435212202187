import { FC, useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Checkbox } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";

import { useSetupsStore } from "Stores/SetupStore/Store";
import AssetModelTable from "./asset-model-table";
import { IAssetMake, IFpAssetGroup, IImage } from "Interface";
import { ActionOptions } from "Enums";
import { addFpAssetGroup, removeFpAssetGroup } from "Stores/SetupStore/Actions";

interface AssetMakeProps {
  assetMake: IAssetMake;
  actionType?: ActionOptions;
  setValue?: any;
  setDeletedAssetGroup?: any;
  deletedAssetGroup?: Array<IFpAssetGroup>;
  handleImage?: (data: IImage[]) => unknown;
}

let detetedArr = [];

const AssetMakeTable: FC<AssetMakeProps> = ({
  assetMake,
  actionType,
  setValue,
  setDeletedAssetGroup,
  deletedAssetGroup,
  handleImage,
}) => {
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useSetupsStore();
  const { fpAssetGroup } = state;
  const theme = useTheme();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    const found = fpAssetGroup?.find(
      (element) => element.asset_make_id == assetMake.id
    );
    if (found) {
      setIsChecked(true);
      setOpen(true);
    } else {
      setIsChecked(false);
    }
  }, [fpAssetGroup, isChecked]);

  useEffect(() => {
    if (actionType == ActionOptions.VIEW) {
      setIsDisabled(true);
    } else {
      assetMake?.is_active ? setIsDisabled(false) : setIsDisabled(true);
    }
  }, [actionType, isDisabled, assetMake]);

  const onCheckChange = (e: any) => {
    setValue("assetGroup", "undefined", { shouldDirty: true });
    if (e.target.checked == true) {
      const attachedModels = assetMake?.asset_models
        ?.filter((row) => row.make_id == assetMake.id && row.is_active)
        ?.map((model) => {
          const obj: IFpAssetGroup = {
            asset_make_id: assetMake.id,
            asset_model_id: model.id,
          };

          let attachedModelTrims = model?.asset_trims
            ?.filter((row) => row.model_id == model.id && row.is_active)
            ?.map((trim) => {
              const trimobj: IFpAssetGroup = {
                asset_make_id: assetMake.id,
                asset_model_id: model.id,
                asset_trim_id: trim.id,
              };
              dispatch(addFpAssetGroup(trimobj));
            });
          if (attachedModelTrims?.length == 0) {
            dispatch(addFpAssetGroup(obj));
          }
        });
      if (attachedModels.length == 0) {
        const obj: IFpAssetGroup = { asset_make_id: assetMake.id };
        dispatch(addFpAssetGroup(obj));
      }
    } else {
      let dataArr = fpAssetGroup;

      dataArr?.map((row) => {
        if (row.asset_make_id == assetMake.id) {
          detetedArr.push(row);
          setDeletedAssetGroup([...deletedAssetGroup, row]);
        }
      });
      // setDeletedAssetGroup(detetedArr);
      dataArr = dataArr.filter((row) => row.asset_make_id != assetMake.id);
      dispatch(removeFpAssetGroup(dataArr));
    }
  };

  return (
    <>
      <TableRow className="child-tr">
        <TableCell className="checkbox-cell fixed-cell">
          <Checkbox
            theme={theme}
            checkBoxDisabled={isDisabled}
            checkBoxChecked={isChecked}
            onChange={onCheckChange}
            label={""}
          />
        </TableCell>
        <TableCell className="indent-cell">
          {open ? (
            <Icon
              name="ChevronDown"
              className="toggle-btn ch-right"
              onClick={() => setOpen(!open)}
            />
          ) : (
            <Icon
              name="ChevronDown"
              className="toggle-btn"
              onClick={() => setOpen(!open)}
            />
          )}
          {assetMake.name}
        </TableCell>
        <TableCell className="img-cell"></TableCell>
        <TableCell>{assetMake.description}</TableCell>
        <TableCell>{assetMake.external_code}</TableCell>
      </TableRow>
      {open &&
        assetMake.asset_models?.length > 0 &&
        assetMake.asset_models
          .filter((x) => x.make_id === assetMake.id)
          .map((item, index) => (
            <AssetModelTable
              setValue={setValue}
              key={index}
              assetModel={item}
              assetMake={assetMake}
              actionType={actionType}
              handleImage={handleImage}
            />
          ))}
    </>
  );
};

export default AssetMakeTable;
