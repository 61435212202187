import { styled } from "@mui/material/styles";

export const AutocompleteWrap: any = styled("div")(({ theme }) => ({
  "&.auto-complete-wrap": {
    ".u-custom-autocomplete": {
      ".MuiAutocomplete-root .u-form-group .MuiInputBase-formControl": {
        height: "auto",
      },
    },
    ".MuiCheckbox-root": {
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  },
}));
