import React, { FC, useState, useEffect, useRef, useContext } from "react";
import { Link, TablePagination, useTheme } from "@mui/material";
import { LayoutWithSideNav } from "layout";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  DataTable,
  Tooltip,
  IButtonProps,
  Menu,
  Input,
  Typography,
  Grid,
  Button,
  Box,
  Switch,
  ImageViewer,
  PersistentDrawer,
} from "@ntpkunity/controls";
import {
  DrawerWrap,
  PageHeader,
  DataTableWrap,
  ConfirmationDialog,
  MenuTooltipWrap,
  PaginationWrap,
} from "@sharedComponents";

import {
  useGetInventoryData,
  useMutation_BulkAddInventory,
  // useSendReferralInvite,
  useGetInventoryFilter,
  useUpdateVehicleByVin,
  useUpdateVehicleDetailByVin,
  useAddNewVehcile,
} from "services/inventory-management.service";
import { useGetDealerintegrationByIntegrationType } from "services/dealer-integration.service";
import { useMutation_CheckS3Credentials } from "services";
import { validation } from "shared/helper/methods";
import {
  EMAIL_REGEX,
  INVENTORY_EXCEL_FILE_ENDPOINT,
} from "shared/config/constants";
import {
  Vehicle_Type,
  Lisiting_Status,
  Daily_Update,
  Status,
  QueryKeys,
  ActionOptions,
  DialogMessages,
  ActionTypes,
} from "Enums";

import { IInventory, IVehicle } from "Interface";
import { useForm } from "react-hook-form";
import { useStoreContext } from "Stores/SetupStore/Store";
import ChevronDown from "shared/assets/images/chevron-down";
import { AttachedOptions, InventoryPopup } from "modules/inventory-options";
import { useQueryClient } from "react-query";
import { DealerContext } from "context";
import { AddNewVehicle } from "../components/AddNewVehicle/AddNewVehicle";

interface IFormInputs {
  id?: number;
  vin: string;
  year: string;
  make: string;
  model: string;
  model_code: string;
  trim_description: string;
  internet_price: number;
  type: string;
  exterior_color_description: string;
  interior_color_description: string;
  transmission_type: string;
  doors: number;
  engine_cylinders: number;
  engine_description: string;
  fuel_type: string;
  drive_train: string;
  dealer_code: string;
  mileage: string;
  dealer_id: string;
  container_file_name: string;
}
interface IFilters {
  make_model?: string;
  stockNumber?: string;
  vin?: string;
  listingStatus?: string;
  status?: string;
  assetCondition?: string;
  mileage?: number;
  msrp?: number;
  internetPrice?: number;
  dailyUpdate?: boolean | null;
}
const InventoryManagement: FC = () => {
  const theme = useTheme();
  const { states, actions } = useStoreContext();
  const { dealer_code } = useContext(DealerContext);
  const [popUpState, setPopUpState] = useState(false);
  const [popUpStateDrawer, setPopUpStateDrawer] = useState(false);
  const [editableCell, setEditableCell] = useState<number>(-1);
  const [selectedVin, setSelectedVin] = useState("");
  const [editedInternetPrice, setEditedInternetPrice] = useState(-1);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [images, setImages] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [index, setIndex] = useState(0);
  const [provider, setProvider] = useState(false);
  const [options, setOptions] = useState([]);
  const [columnFilters, setColumnFilters] = useState(
    `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
  );
  const [searchFilters, setSearchFilters] = useState<IFilters>({});
  const { mutate: getInventory, data: inventoryData1 } = useGetInventoryData();
  const { mutate: AddInventory } = useMutation_BulkAddInventory();
  const { mutate: addNewVehcile } = useAddNewVehcile();
  // const { mutate: saveReferralInvite } = useSendReferralInvite();
  const { mutate: updateVehicleDetail } = useUpdateVehicleDetailByVin();
  const { mutate: getDealerintegration, data: dealerIntegration } =
    useGetDealerintegrationByIntegrationType(dealer_code);
  const { mutate: updateVehicle } = useUpdateVehicleByVin();
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  const [openAddMoreInventoryDialog, setOpenAddMoreInventoryDialog] =
    useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [dealer_id, setDealerId] = useState("");
  const [warningPopupState, setWarningPopupState] = useState<boolean>(false);
  const [modalClose, setModalClose] = useState<boolean>(false);
  let statusIcon;

  const { data } = useGetInventoryFilter(columnFilters);
  const inventoryFilterData = data;
  const queryClient = useQueryClient();
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const dealerData: any = queryClient.getQueryData(
    QueryKeys.GET_ACTIVE_DEALERS
  );
  useEffect(() => {
    if (dealerData) {
      setDealerId(
        dealerData?.find((obj) => obj?.dealer_code === dealer_code)?.id
      );
    }
  }, [dealer_code, dealerData]);
  const handleshow = () => {
    setPopUpStateDrawer(true);
  };
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  useEffect(() => {
    checkS3Credentials(JSON.parse(localStorage.getItem("settings"))?.tenant_id);
  }, []);
  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  useEffect(() => {
    getDealerintegration(dealer_code);
  }, [dealer_code]);
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };
  useEffect(() => {
    dealerIntegration?.data?.providers.forEach((provider) => {
      if (provider.is_active === true) {
        setProvider(true);
        return;
      }
    });
  }, [dealerIntegration]);

  useEffect(() => {
    setOptions(
      provider
        ? tableOptions.filter(
            (option) => option.optionkey !== ActionOptions.UPLOAD_FILE
          )
        : tableOptions
    );
  }, [provider, dealer_code]);
  const [assetMakeId, setAssetMakeId] = useState(null);
  const [assetModelId, setAssetModelId] = useState(null);
  const [assetTrimId, setAssetTrimId] = useState(null);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const form = useForm<any>({ mode: "all" });
  const { getValues, reset, setValue, handleSubmit } = form;
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setAssetMakeId(null);
      setAssetModelId(null);
      setAssetTrimId(null);
      setPopUpStateDrawer(false);
      reset();
      reset({ ...getValues() });
      reset(data);
    }
  };
  const handleSaveVehicle = () => {
    const data = {
      vin: getValues("vin"),
      stock_number: getValues("stock_number"),
      year: getValues("year"),
      make: getValues("make.label"),
      model: getValues("model.label"),
      model_code: getValues("trim_code.label"),
      trim_description: getValues("trim_description"),
      internet_price: getValues("selling_price"),
      msrp: getValues("msrp"),
      type: getValues("asset_condition"),
      exterior_color_description: getValues("exterior_color_description"),
      interior_color_description: getValues("interior_color_description"),
      transmission_type: getValues("trasnmission_type"),
      body_type: getValues("body_type"),
      body_style: getValues("body_style"),
      doors: getValues("doors"),
      engine_cylinders: getValues("engine_cylinders"),
      engine_description: getValues("engine_description"),
      // "fuel_type": getValues(""),
      drive_train: getValues(""),
      dealer_code: dealer_code,
      mileage: getValues("mileage"),
      dealer_id: dealer_id,
      container_file_name: "front_end",
      // "photo_urls":[getValues("image_url")],
    };
    addNewVehcile(data, {
      onSuccess() {
        onClose();
        form.reset({} as IFormInputs);
        actions.setToast({
          toastMessage: "Record Added Successfully",
          toastState: true,
        });
      },
      onError(error: any) {
        setPopUpStateDrawer(false);
        actions.setToast({
          toastMessage: error?.detail?.msg?.toString(),
          toastState: true,
          variant: "error",
        });
      },
    });
  };
  const updateVehicleStatuses = async (
    vehicleDetail: any,
    valueIdentifier: string
  ) => {
    switch (valueIdentifier) {
      case "dailyUpdate":
        await updateVehicleDetail(vehicleDetail, {
          onSuccess() {
            actions.setToast({
              toastMessage: "Record Updated Successfully",
              toastState: true,
            });
            queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
          },
        });
        return;
      case "listingStatus":
        await updateVehicleDetail(vehicleDetail, {
          onSuccess() {
            actions.setToast({
              toastMessage: "Record Updated Successfully",
              toastState: true,
            });
            queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
          },
        });
        return;
    }
  };
  const updateVehicleInternetPrice = async (id: number, vehicle: IVehicle) => {
    await updateVehicle(
      { id: id, vehicle },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: "Record Updated Successfully",
            toastState: true,
          });
          queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
        },
      }
    );
    return;
  };

  const handleImage = (imagesData) => {
    const image_url = imagesData?.map((image) => {
      return image?.location;
    });

    setImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);
  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    }
  }, [dealer_code]);

  const [openOptionDialog, setopenOptionDialog] = React.useState(false);
  const [vehicleObject, setvehicleObject] = React.useState<any>(null);
  const handleClickProductOpen = () => {
    setopenOptionDialog(true);
  };
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `dealer_code=${dealer_code}&page_number=${pgNo}&page_size=${pgSize}`;
    if (
      searchFilters?.make_model?.trimStart() &&
      searchFilters?.make_model?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&make_model=${searchFilters.make_model}`
      );
    }
    if (
      searchFilters?.stockNumber?.trimStart() &&
      searchFilters?.stockNumber?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&stock_number=${searchFilters.stockNumber}`
      );
    }
    if (
      searchFilters?.vin?.trimStart() &&
      searchFilters?.vin?.trimStart() != ""
    ) {
      query_string = query_string.concat(`&vin=${searchFilters.vin}`);
    }
    if (searchFilters?.mileage) {
      query_string = query_string.concat(`&mileage=${searchFilters.mileage}`);
    }
    if (searchFilters?.msrp) {
      query_string = query_string.concat(`&msrp=${searchFilters.msrp}`);
    }
    if (searchFilters?.internetPrice) {
      query_string = query_string.concat(
        `&internet_price=${searchFilters.internetPrice}`
      );
    }
    if (searchFilters.dailyUpdate != null) {
      query_string = query_string.concat(
        `&daily_update=${searchFilters.dailyUpdate}`
      );
    }
    if (
      searchFilters?.listingStatus?.trimStart() != null &&
      searchFilters?.listingStatus?.trimStart() != ""
    ) {
      let listingStatus = "";
      if (
        "listed".startsWith(
          searchFilters?.listingStatus
            .trimStart()
            .toLowerCase()
            .slice(0, Math.max("listed".length - 1, 1))
        )
      ) {
        listingStatus = "listed";
      } else if (
        "not listed".startsWith(
          searchFilters?.listingStatus
            .trimStart()
            .toLowerCase()
            .slice(0, Math.max("not listed".length - 1, 1))
        )
      ) {
        listingStatus = "not listed";
      } else if (searchFilters?.listingStatus.trimStart() !== "") {
        listingStatus = "none";
      }
      query_string = query_string.concat(`&listing_status=${listingStatus}`);
    }
    if (
      searchFilters?.status?.trimStart() != null &&
      searchFilters?.status?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&vehicle_status=${searchFilters.status}`
      );
    }
    if (
      searchFilters?.assetCondition?.trimStart() != null &&
      searchFilters?.assetCondition?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&asset_condition=${searchFilters.assetCondition}`
      );
    }
    return query_string;
  };
  const getStatusIcon = (status) => {
    switch (status) {
      case Status.Available:
        statusIcon = "ind-success";
        break;
      case Status.Inquiry:
        statusIcon = "ind-danger";
        break;
      case Status.InContarct:
        statusIcon = "ind-contract";
        break;
      case Status.NotAvailable:
        statusIcon = "ind-danger";
        break;
      default:
        statusIcon = "ind-success";
    }
  };

  const handleSelection = (event, key, value) => {
    if (key == ActionOptions.UPLOAD_FILE) {
      if (!s3Response?.data) {
        return;
      }
      if (inventoryFilterData?.totalResults >= 0) {
        setWarningPopupState(true);
      } else {
        hiddenFileInput.current.click();
      }
    }
    if (key == ActionOptions.DOWNLOAD_INVENTORY_FILE) {
      window.location.href = `${
        process.env.DMS_BUCKET_BASE_URL + INVENTORY_EXCEL_FILE_ENDPOINT
      }`;
      actions.setToast({
        toastMessage: "File Downloading, Please wait.",
        toastState: true,
      });
    }
  };
  const tableOptions = [
    {
      optionText: <>{ActionOptions.DOWNLOAD_INVENTORY_FILE}</>,
      optionkey: ActionOptions.DOWNLOAD_INVENTORY_FILE,
    },
    {
      optionText: <>{ActionOptions.UPLOAD_FILE}</>,
      optionkey: ActionOptions.UPLOAD_FILE,
    },
  ];

  const handleChange = (event) => {
    // const dealerId = localStorage?.getItem("dealer_code");
    setOpenAddMoreInventoryDialog(true);
    setInventoryData([]);
    const obj: any = {
      tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
    };
    const body = new FormData();
    body.append("files", event.target.files[0]);
    obj.files = body;
    AddInventory(
      { dealer_code: dealer_code, obj },
      {
        onSuccess: (response) => {
          setInventoryData(response.data);
          queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
          setModalClose(true);
        },
      }
    );
    event.target.value = null;
  };
  const hiddenFileInput = useRef(null);
  const confirmationText =
    inventoryFilterData?.totalResults > 0
      ? `
  - The file you are uploading will override the existing records.
  - The image URLs provided in the file should be publicly accessible otherwise the images will not be displayed.

  Are you sure you want to continue?
`
      : `
- The image URLs provided in the file should be publicly accessible otherwise the images will not be displayed.

Are you sure you want to continue?
`;
  const handleBtn = (e) => {
    hiddenFileInput.current.click();
  };
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  return (
    <DrawerWrap open={popUpState}>
      <LayoutWithSideNav theme={theme}>
        <PageHeader className="main-page-header">
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
              <Typography
                data-testId="heading"
                variant="h2"
                component="h2"
                theme={theme}
              >
                Manage Inventory
              </Typography>
            </Grid>
            <Grid
              theme={theme}
              item
              xs={12}
              lg={5}
              sm={12}
              md={6}
              textAlign="right"
              className="action-area"
            >
              <Menu
                theme={theme}
                disablePortal
                options={options?.map((option) => {
                  if (
                    option.optionText.props.children.toString() ===
                      ActionOptions.UPLOAD_FILE &&
                    !s3Response?.data
                  )
                    return {
                      optionText: (
                        <Tooltip
                          title={ActionTypes.STORAGE_PATH_NOT_CONFIGURED}
                          disablePortal
                          theme={theme}
                          placement="left"
                        >
                          {option.optionText}
                        </Tooltip>
                      ),
                      optionkey: option.optionkey,
                      optionValue: {},
                      disabled: true,
                    };
                  else
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: {},
                    };
                })}
                handleOptionClick={handleSelection}
                render={(onMenuSelection) => (
                  <Button
                    theme={theme}
                    secondary
                    iconText={<Icon name="MoreIcon" />}
                    id="programCancel"
                    onClick={onMenuSelection}
                  />
                )}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
              <Button
                theme={theme}
                primary
                text="Add New Vehicle"
                onClick={handleshow}
              />
            </Grid>
          </Grid>
        </PageHeader>
        <input
          type="file"
          accept=".xls,.xlsx"
          ref={hiddenFileInput}
          id="InventoryUploadId"
          style={{ display: "none" }}
          onChange={handleChange}
        />
        <DataTableWrap className="table-pagination">
          <Box theme={theme} className="scroll">
            <Box
              className="scroll-hide sh-fixed-cell spr-border"
              theme={theme}
            />
          </Box>
          <DataTable
            theme={theme}
            variant="basic"
            sx={{ mb: 3 }}
            theadChildren={
              <>
                <TableRow>
                  {/* <TableCell className="checkbox-cell">
                    <Checkbox label=""></Checkbox>
                  </TableCell> */}
                  <TableCell className="img-cell">
                    <Icon name="ImageIcon" />
                  </TableCell>
                  <TableCell id="modelTrimYearHead" className="w-auto">
                    Year/Make/Model/Trim
                  </TableCell>
                  <TableCell id="stockHead">Stock Number</TableCell>
                  <TableCell id="VINHead">VIN</TableCell>
                  <TableCell id="listingStatusHead">Listing Status</TableCell>
                  <TableCell id="statusHead">Status</TableCell>
                  <TableCell id="assetConditionHead">Asset Condition</TableCell>
                  <TableCell id="optionHead">Options</TableCell>
                  <TableCell id="mileageHead">Mileage</TableCell>
                  <TableCell id="MSRPHead">MSRP</TableCell>
                  <TableCell id="selingPriceHead">Seling Price</TableCell>
                  <TableCell id="dailyUpdateHead">Daily Update</TableCell>
                </TableRow>
                <TableRow className="filters-row">
                  {/* <TableCell className="checkbox-cell"></TableCell> */}
                  <TableCell className="img-cell"></TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="makeModelTrimYearSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.make_model}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            make_model: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="makeModelTrimYearFilter"
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="stockSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.stockNumber}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            stockNumber: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="stockFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="VINSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.vin}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            vin: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="VINFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="listingStatusSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.listingStatus}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            listingStatus: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      />
                      <Button
                        defaultBtn
                        id="listingStatusFilter"
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.status}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            status: e.toLowerCase(),
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="statusFilter"
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      {/* <MultiSelect
                        id="assetConditionSearch"
                        theme={theme}
                        label={"Select"}
                        placeholder={"Select"}
                        value={searchFilters.assetCondition || []}
                        items={Object.keys(Vehicle_Type)
                          .filter((value) => isNaN(Number(value)) === false)
                          .map((key: any) => ({
                            value: key,
                            text: Vehicle_Type[key],
                          }))}
                        sxProps={{ m: 0, width: "100%" }}
                        onChange={(e: any) => {
                          setSearchFilters({
                            ...searchFilters,
                            assetCondition: e.target.value,
                          });
                        }}
                        renderValue={() =>
                          searchFilters.assetCondition
                            ?.map((item) => item)
                            .join(", ")
                        }
                      ></MultiSelect> */}

                      <Input
                        id="assetConditionSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.assetCondition}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            assetCondition: e.toLowerCase(),
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      />
                      <Button
                        defaultBtn
                        id="assetConditionFilter"
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="optionSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                      ></Input>
                      <Button
                        defaultBtn
                        id="optionFilter"
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="mileageSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="number"
                        dynamic={false}
                        value={searchFilters.mileage}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            mileage: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="mileageFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="MSRPSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="number"
                        dynamic={false}
                        value={searchFilters.msrp}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            msrp: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="MSRPFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="internetPriceSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="number"
                        dynamic={false}
                        value={searchFilters.internetPrice}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            internetPrice: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="internetPriceFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      {/* <MultiSelect
                        theme={theme}
                        label={"Select"}
                        placeholder={"Select"}
                        value={searchFilters.dailyUpdate || []}
                        items={[
                          { text: "Enabled", value: true },
                          { text: "Disabled", value: false },
                        ]}
                        sxProps={{ m: 0, width: "100%" }}
                        onChange={(e: any) => {
                          setSearchFilters({
                            ...searchFilters,
                            dailyUpdate: e.target.value,
                          });
                        }}
                        renderValue={() =>
                          searchFilters.dailyUpdate
                            ?.map((item) => item)
                            .join(", ")
                        }
                      ></MultiSelect> */}
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        onChange={(e) => {
                          if (e.toLowerCase().trimStart() != "")
                            setSearchFilters({
                              ...searchFilters,
                              dailyUpdate: "enabled".includes(e.toLowerCase())
                                ? true
                                : false,
                            });
                          else {
                            setSearchFilters({
                              ...searchFilters,
                              dailyUpdate: null,
                            });
                          }
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      />
                      <Button
                        defaultBtn
                        id="dailyUpdateFilter"
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                {inventoryFilterData?.result?.map(
                  (vehicleDetail: IInventory, index: number) => (
                    <TableRow key={index} className="child-tr">
                      {/* <TableCell className="checkbox-cell">
                        <Checkbox label=""></Checkbox>
                      </TableCell> */}
                      <TableCell className="img-cell">
                        {vehicleDetail?.vehicle?.photo_urls?.[0]?.location !=
                          "" &&
                          vehicleDetail?.vehicle?.photo_urls?.[0]?.location !=
                            "https://" && (
                            <Link
                              onClick={() => {
                                handleImage(vehicleDetail?.vehicle?.photo_urls);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                className="prd-img"
                                src={
                                  vehicleDetail?.vehicle?.photo_urls?.[0]
                                    ?.location
                                }
                                alt="Car"
                              />
                            </Link>
                          )}
                      </TableCell>
                      <TableCell>
                        <Box theme={theme} display="flex" alignItems="center">
                          <Tooltip
                            theme={theme}
                            title={
                              <>
                                {vehicleDetail?.vehicle?.year}{" "}
                                {vehicleDetail?.vehicle?.make}{" "}
                                {vehicleDetail?.vehicle?.model}{" "}
                                {vehicleDetail?.vehicle?.trim_description}
                              </>
                            }
                            children={
                              <>
                                {vehicleDetail?.vehicle?.year}{" "}
                                {vehicleDetail?.vehicle?.make}{" "}
                                {vehicleDetail?.vehicle?.model}{" "}
                                {vehicleDetail?.vehicle?.trim_description}
                              </>
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        {vehicleDetail?.vehicle?.stock_number}
                      </TableCell>
                      <TableCell>
                        <Box theme={theme} display="flex" alignItems="center">
                          <Tooltip
                            theme={theme}
                            title={vehicleDetail?.vehicle?.vin}
                            children={<>{vehicleDetail?.vehicle?.vin}</>}
                          />
                        </Box>
                        {/* {vehicleDetail?.vehicle?.vin} */}
                      </TableCell>
                      <TableCell>
                        <Switch
                          theme={theme}
                          varient={"basic"}
                          label={
                            Lisiting_Status[
                              Number(vehicleDetail.listing_status)
                            ]
                          }
                          switchEnabled={vehicleDetail?.listing_status}
                          onChange={(event) => {
                            updateVehicleStatuses(
                              {
                                ...vehicleDetail,
                                listing_status: event.target.checked,
                              },
                              "listingStatus"
                            );
                            actions.updateInventoryRow({
                              ...vehicleDetail,
                              listing_status: event.target.checked,
                            });
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {getStatusIcon(vehicleDetail?.vehicle_status)}
                        <span
                          className={`status-indicator ${statusIcon}`}
                        ></span>

                        {vehicleDetail?.vehicle_status as keyof typeof Status}
                      </TableCell>
                      <TableCell>{vehicleDetail?.vehicle?.type}</TableCell>
                      <TableCell>
                        <Box theme={theme} className="add-option">
                          {vehicleDetail.dealer_option_descriptions ? (
                            <Link
                              className="link"
                              variant="body2"
                              component={"a"}
                              onClick={(_e: any) => {
                                setvehicleObject(vehicleDetail);
                                handleClickProductOpen();
                              }}
                            >
                              {vehicleDetail?.dealer_option_descriptions}
                            </Link>
                          ) : (
                            <Link
                              className="link add"
                              variant="body2"
                              component={"a"}
                              onClick={(_e: any) => {
                                setvehicleObject(vehicleDetail);
                                handleClickProductOpen();
                              }}
                            >
                              <Icon name="AddIcon" /> Add Options
                            </Link>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {vehicleDetail?.vehicle?.mileage.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        <Box theme={theme} sx={{ mr: 4.3 }}>
                          {defaultCurrency}{" "}
                          {vehicleDetail?.vehicle?.msrp.toLocaleString()}{" "}
                        </Box>
                      </TableCell>
                      <TableCell className="editable-cell">
                        {editableCell === index ? (
                          <Box
                            theme={theme}
                            className="cell-content"
                            justifyContent={"flex-end"}
                          >
                            <Input
                              theme={theme}
                              label=""
                              type="text"
                              startAdornment={defaultCurrency}
                              id={`editInternetPrice${index}`}
                              dynamic={false}
                              value={vehicleDetail?.vehicle?.internet_price}
                              onBlur={(e) =>
                                setEditedInternetPrice(e.target.value)
                              }
                              masking
                              maskDecimalScale={2}
                              maskNumeric
                              // scale={2}
                              // numeric
                            />
                            <Button
                              defaultBtn
                              iconText={<Icon name="TickIcon" />}
                              onClick={(_e) => {
                                updateVehicleInternetPrice(
                                  vehicleDetail.vehicle.id,
                                  {
                                    ...vehicleDetail.vehicle,
                                    internet_price: parseFloat(
                                      editedInternetPrice
                                        .toString()
                                        .replace(/,/g, "")
                                    ),
                                  }
                                );
                                actions.updateInventoryRow({
                                  ...vehicleDetail,
                                  vehicle: {
                                    ...vehicleDetail.vehicle,
                                    internet_price: parseFloat(
                                      editedInternetPrice
                                        .toString()
                                        .replace(/,/g, "")
                                    ),
                                  },
                                });
                                setEditableCell(-1);
                                setEditedInternetPrice(-1);
                              }}
                            />
                            <Button
                              defaultBtn
                              iconText={
                                <Icon
                                  name="CloseBlack"
                                  onClick={(_e) => {
                                    setEditableCell(-1);
                                    setEditedInternetPrice(-1);
                                  }}
                                />
                              }
                            />
                          </Box>
                        ) : (
                          <Box
                            theme={theme}
                            className="cell-content"
                            justifyContent={"flex-end"}
                          >
                            <span>
                              {defaultCurrency}{" "}
                              {vehicleDetail?.vehicle?.internet_price.toLocaleString()}{" "}
                            </span>
                            <Button
                              defaultBtn
                              iconText={
                                <Icon
                                  name="EditIcon"
                                  onClick={(_e) => {
                                    setEditedInternetPrice(
                                      vehicleDetail?.vehicle?.internet_price
                                    );
                                    setEditableCell(index);
                                  }}
                                />
                              }
                            />
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>
                        <Switch
                          theme={theme}
                          varient={"basic"}
                          label={
                            Daily_Update[Number(vehicleDetail?.daily_update)]
                          }
                          switchEnabled={vehicleDetail?.daily_update}
                          onChange={(event) => {
                            updateVehicleStatuses(
                              {
                                ...vehicleDetail,
                                daily_update: event.target.checked,
                              },
                              "dailyUpdate"
                            );
                            actions.updateInventoryRow({
                              ...vehicleDetail,
                              daily_update: event.target.checked,
                            });
                          }}
                        />
                      </TableCell>
                      {/* <TableCell className="action-cell space-fixed">
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: "Send for Referral",
                              optionkey: "referral",
                              optionValue: "referral",
                            },
                          ]}
                          handleOptionClick={(_event, key, _value) => {
                            handleSelection(_event, key, _value);
                            setSelectedVin(vehicleDetail.vin);
                          }}
                          render={(onMenuSelection) => (
                            <Button
                              defaultBtn
                              iconText={<Icon name="MoreIcon" />}
                              onClick={onMenuSelection}
                            />
                          )}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        />
                      </TableCell> */}
                    </TableRow>
                  )
                )}
              </>
            }
          />
          {inventoryFilterData?.totalResults > 0 && (
            <PaginationWrap>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={inventoryFilterData?.totalResults ?? -1}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  IconComponent: ChevronDown,
                  MenuProps: { disablePortal: true },
                }}
              />
            </PaginationWrap>
          )}
        </DataTableWrap>
        <PersistentDrawer
          title={"Add New Vehicle"}
          openPopUp={popUpStateDrawer}
          setPopUpState={setPopUpStateDrawer}
          theme={theme}
          enabledPin={true}
          customFooter={
            <Button
              theme={theme}
              primary
              type="submit"
              text="Save Vehicle"
              fullWidth
              onClick={handleSubmit(handleSaveVehicle)}
            />
          }
        >
          <AddNewVehicle
            form={form}
            assetMakeId={assetMakeId}
            setAssetMakeId={setAssetMakeId}
            setAssetModelId={setAssetModelId}
            assetModelId={assetModelId}
            setAssetTrimId={setAssetTrimId}
            assetTrimId={assetTrimId}
          />
        </PersistentDrawer>
        <ImageViewer
          theme={theme}
          sliderImagesWithVisible={true}
          sliderImages={images}
          isLoading={false}
          singleTriggerNode={true}
          overlayCheck={true}
          visible={visibility}
          index={index}
          setIndex={setIndex}
          onClose={handleClose}
        />
        {openOptionDialog && (
          <AttachedOptions
            openPopup={openOptionDialog}
            vehicleData={vehicleObject}
            setopenOptionDialog={setopenOptionDialog}
            dealer_code={dealer_code}
            setModalClose={setModalClose}
          ></AttachedOptions>
        )}
        {openAddMoreInventoryDialog && (
          <InventoryPopup
            openPopup={openAddMoreInventoryDialog}
            inventoryData={inventoryData}
            setopenInventoryDialog={setOpenAddMoreInventoryDialog}
          ></InventoryPopup>
        )}
        {warningPopupState && (
          <ConfirmationDialog
            openPopUp={warningPopupState}
            onConfirm={handleBtn}
            setPopUpState={setWarningPopupState}
            confirmationTitle={DialogMessages.uploadTitle}
            confirmationText={confirmationText}
            primaryBtnText={DialogMessages.uploadBtnText}
            icon={<Icon name="ErrorTriangle" />}
          />
        )}
      </LayoutWithSideNav>
    </DrawerWrap>
  );
};
export default InventoryManagement;
