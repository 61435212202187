import { FC, useEffect, useRef, useState } from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from "@mui/material";
import {
  Box,
  Icon,
  Button,
  ButtonGroup,
  Typography,
  Tooltip,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { styled } from "@mui/material/styles";
import { ActionOptions, QueryKeys } from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import LanguageTable from "./LanguageTable";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import Translation from "./Translation";
import AddLanguage from "./AddLanguage";
import {
  UseQuery_getAllTenantLanguages,
  UseQuery_getLanguagesAndTranslations,
} from "services";
import {
  ITenantLanguagesModel,
  ILanguageTranslationModel,
  IPlaceholderTranslationModel,
} from "Interface";

const MenuItemCenter = styled(
  MenuItem,
  {}
)<any>(({ theme }) => ({
  color: theme.palette.common.black,
  ".MuiTooltip-popper": {
    width: 250,
    right: "10px !important",
    ".MuiTooltip-tooltip": {
      maxWidth: "100%",
      whiteSpace: "break-spaces",
      display: "inline-block",
    },
  },
}));

const Language: FC = () => {
  const theme = useTheme();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [openGroupBtn, setOpenGroupBtn] = useState(false);
  const [state, dispatch] = useSetupsStore();
  const [open, setOpen] = useState(false);
  const [openTranslation, setOpenTranslation] = useState(false);
  const [action, setAction] = useState<ActionOptions>();
  const [selectedData, setselectedData] =
    useState<IPlaceholderTranslationModel>();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );
  const queryClient = useQueryClient();

  UseQuery_getAllTenantLanguages();

  UseQuery_getLanguagesAndTranslations(columnFilters);

  const tenantLanguages: ITenantLanguagesModel = queryClient.getQueryData(
    QueryKeys.GET_ALL_TENANT_LANGUAGES
  );

  const languagesAndTranslation: ILanguageTranslationModel =
    queryClient.getQueryData([
      QueryKeys.GET_LENDER_LANGUAGES_AND_TRANSLATIONS,
      columnFilters,
    ]);

  const CustomButton = styled(
    "div",
    {}
  )<any>(({ theme }) => ({
    "&.custom-buttons": {
      position: "relative",
      ".button-group": {
        ".group-btn": {
          width: "auto",
          padding: "12px 24px",
          cursor: "pointer",
          "&:not(:last-of-type)": {
            flexGrow: 1,
          },
          "&.only-icon": {
            padding: 12,
            "&.btn-secondary": {
              svg: {
                path: {
                  stroke: theme.palette.grey[900],
                },
              },
              "&:hover": {
                svg: {
                  path: {
                    stroke: theme.palette.common.white,
                  },
                },
                "&:focus": {
                  svg: {
                    path: {
                      stroke: theme.palette.common.white,
                    },
                  },
                },
              },
              "&:focus": {
                svg: {
                  path: {
                    stroke: theme.palette.common.black,
                  },
                },
              },
              "&:focus-visible": {
                svg: {
                  path: {
                    stroke: theme.palette.common.white,
                  },
                },
              },
              "&:disabled": {
                svg: {
                  path: {
                    stroke: theme.palette.grey[300],
                  },
                },
              },
            },
          },
          "&.btn-secondary": {
            backgroundColor: "transparent",
            borderColor: theme.palette.grey[300],
            color: theme.palette.grey[900],
            "&:hover": {
              backgroundColor: theme.palette.grey[900],
              borderColor: theme.palette.grey[900],
              color: theme.palette.common.white,
            },
            "&:disabled": {
              borderColor: theme.palette.grey[100],
              backgroundColor: "transparent",
              color: theme.palette.grey[300],
            },
            "&.disabled": {
              borderColor: theme.palette.grey[100],
              backgroundColor: "transparent",
              color: theme.palette.grey[300],
            },
          },
        },
      },
      ".u-dropdown-menu": {
        ".MuiPaper-root": {
          borderStyle: "solid",
          borderWidth: 1,
          borderColor:
            theme.palette.mode === "dark"
              ? [theme.palette.common.black]
              : theme.palette.grey[50],
          boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
          minWidth: 145,
          padding: 8,
          color: theme.palette.grey[600],
          "@supports (-moz-appearance:none)": {
            scrollbarWidth: "thin",
          },
          ".MuiList-root": {
            padding: 0,
          },
          "&::-webkit-scrollbar": {
            width: 7,
            height: 7,
          },
          "&::-webkit-scrollbar-track": {
            padding: "0 1px",
            backgroundColor: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            transition: "all 0.3s",
            backgroundColor: "#cdcdcd",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#a6a6a6",
          },
        },
        ".u-dropdown-item": {
          padding: "12px 24px 12px 16px",
          borderRadius: theme.shape.borderRadius,
          // backgroundColor: theme.palette.common.white,
          color: theme.palette.grey[600],

          "&:hover, &.Mui-selected": {
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[100]
                : "#F2F4FA",
            color:
              theme.palette.mode === "dark"
                ? theme.palette.common.white
                : theme.palette.grey[900],
          },

          "&.Mui-disabled": {
            opacity: 1,
            pointerEvents: "initial",
            cursor: "not-allowed",
            color: theme.palette.grey[300],

            "&:hover": {
              backgroundColor: "transparent",
              color: theme.palette.grey[300],
            },
          },

          ".MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
    },
  }));

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpenGroupBtn((prevOpen) => !prevOpen);
  };

  const handleClick = () => {
    dispatch(setOpenDrawer(true));
    setOpen(false);
    setOpenTranslation(true);
    setAction(ActionOptions.ADD);
    setOpenGroupBtn(false);
    return;
  };

  const handleAddNewLanguageClick = () => {
    setOpen(true);
    setOpenGroupBtn(false);
    setOpenTranslation(false);
    dispatch(setOpenDrawer(true));
  };

  const onEditPlaceholder = (data: IPlaceholderTranslationModel) => {
    setselectedData(data);
    setOpen(false);
    setAction(ActionOptions.EDIT);
    dispatch(setOpenDrawer(true));
    setOpenTranslation(true);
  };

  useEffect(() => {
    if (!open && !openTranslation) {
      dispatch(setOpenDrawer(false));
    }
  }, [open, openTranslation]);

  useEffect(() => {
    return () => dispatch(setOpenDrawer(false));
  }, []);

  return (
    <>
      <DataTableWrap className="table-tabs-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <LanguageTable
          tenantLanguages={tenantLanguages}
          languagesAndTranslation={languagesAndTranslation}
          onEditPlaceholder={onEditPlaceholder}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
        />
        <CustomButton
          theme={theme}
          className="custom-buttons"
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr" },
            gap: 1,
          }}
          onClose={handleClose}
        >
          <ButtonGroup
            theme={theme}
            className="button-group"
            variant="outlined"
            ref={anchorRef}
            aria-label="split button"
            fullWidth
          >
            <Button
              theme={theme}
              secondary
              className="group-btn btn-secondary"
              onClick={handleClick}
              disabled={!languagesAndTranslation || !tenantLanguages}
              text="Add New Placeholder"
            />

            <Button
              theme={theme}
              secondary
              className="group-btn btn-secondary only-icon"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              iconText={<Icon name="ChevronDown" />}
            />
          </ButtonGroup>
          <Popper
            className="u-dropdown-menu"
            open={openGroupBtn}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{
              position: "absolute",
              top: "auto",
              left: "auto",
              right: 0,
              bottom: 53,
              zIndex: 1200,
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleToggle}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      <MenuItemCenter
                        className="u-dropdown-item"
                        onClick={handleAddNewLanguageClick}
                        sx={{ display: "flex", justifyContent: "left" }}
                      >
                        <Tooltip
                          disablePortal
                          theme={theme}
                          placement="left"
                          title=""
                        >
                          <div>
                            <Typography theme={theme} component={""}>
                              Add New Language
                            </Typography>
                          </div>
                        </Tooltip>
                      </MenuItemCenter>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </CustomButton>
      </DataTableWrap>

      {openTranslation && (
        <Translation
          open={openTranslation}
          setOpen={setOpenTranslation}
          languagesAndTranslation={languagesAndTranslation}
          selectedData={selectedData}
          action={action}
          setselectedData={setselectedData}
        />
      )}

      {open && <AddLanguage open={open} setOpen={setOpen} />}
    </>
  );
};

export default Language;
