import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme, styled } from "@mui/material";
import {
  Box,
  IBoxProps,
  Button,
  PersistentDrawer,
  Icon,
} from "@ntpkunity/controls";

import {
  useAddIndividualizedAgreement,
  useDeleteIndividualizedAgreement,
  useGetIndividualizedAgreementByDealerCode,
  useUpdateIndividualizedAgreement,
} from "services/dealer-configurations.service";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IDealerAgreement } from "Interface";
import { DialogMessages, ToastMessages } from "Enums";
import { ConfirmationDialog } from "@sharedComponents";

import IndividualizedAgreementDrawer from "./individualized-agreement-drawer";
import IndividualizedAgreementList from "./individualized-agreement-list";

export const IndividualizedAgreementWrap: any = styled(Box)<Partial<IBoxProps>>(
  ({ theme }) => ({
    "&.individualized-agreement-wrap": {
      ".individualized-agreement-list": {
        width: "100%",
        maxHeight: "calc(100vh - 367px)",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid" + theme.palette.grey[100],
        overflowX: "hidden",
        overflowY: "auto",
        "@supports (-moz-appearance:none)": {
          scrollbarWidth: "thin",
        },
        "&::-webkit-scrollbar": {
          width: 7,
          height: 7,
          borderRadius: 8,
        },
        "&::-webkit-scrollbar-track": {
          padding: "0 1px",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#f0f0f0"
              : theme.palette.grey[700],
          borderRadius: 8,
        },
        "&::-webkit-scrollbar-thumb": {
          transition: "all 0.3s",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#cdcdcd"
              : theme.palette.grey[500],
          borderRadius: 8,
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor:
            theme.palette.mode === "light"
              ? "#a6a6a6"
              : theme.palette.grey[600],
        },

        ".agreement": {
          display: "inline-block",
          width: "100%",
          padding: 24,
          borderBottom: "1px solid" + theme.palette.grey[100],
          "&:last-of-type": {
            borderBottom: "0 solid transparent",
          },
          ".MuiTypography-root": {
            wordWrap: "break-word",
            whiteSpace: "break-spaces",
            width: "calc(100% - 24px)",
          },
          ".stack": {
            borderLeft: "1px solid" + theme.palette.grey[100],
            paddingLeft: 16,
            float: "right",
            marginRight: 0,
            div: {
              padding: 0,
            },
            ".btn": {
              padding: 0,
              "svg path": {
                stroke: theme.palette.grey[300],
              },
            },
            ".MuiDivider-root": {
              borderColor: theme.palette.grey[100],
            },
          },
        },
      },
    },
  })
);

interface IFormInputs {
  id?: number;
  agreement_clause?: string;
}

const IndividualizedAgreement: FC<{
  dealer_code?: string;
  onPopupStateChange: (popUpState: any) => unknown;
}> = ({ dealer_code, onPopupStateChange }) => {
  const theme = useTheme();
  const form = useForm<IFormInputs>();
  const { actions } = useStoreContext();

  const { mutate: getIndividualizedAgreements } =
    useGetIndividualizedAgreementByDealerCode();
  const { mutate: addIndividualizedAgreement } =
    useAddIndividualizedAgreement();
  const { mutate: updateIndividualizedAgreement } =
    useUpdateIndividualizedAgreement();
  const { mutate: deleteIndividualizedAgreement, isLoading: deletionLoading } =
    useDeleteIndividualizedAgreement();

  const [popUpState, setPopUpState] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [deleteAgreementId, setDeleteAgreementId] = useState<
    number | undefined
  >(undefined);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [individualizedAgreements, setIndividualizedAgreements] = useState<
    IDealerAgreement[]
  >([]);

  useEffect(() => {
    if (dealer_code) {
      getAllDealersIndividualizedAgreements();
    }
  }, [dealer_code]);

  useEffect(() => {
    onPopupStateChange(popUpState);
    if (!popUpState) setIsEditMode(false);
  }, [popUpState]);

  const getAllDealersIndividualizedAgreements = (isAddition?: boolean) => {
    getIndividualizedAgreements(
      { id: dealer_code },
      {
        onSuccess(data: IDealerAgreement[]) {
          setIndividualizedAgreements(data || []);
          if (isAddition) {
            actions.setToast({
              toastMessage: ToastMessages.Record_Added_Success,
              toastState: true,
            });
          }
        },
      }
    );
  };

  const handleshow = () => {
    form.reset({} as IFormInputs);
    setPopUpState(true);
  };

  const handleshowEdit = (values: IFormInputs) => {
    form.reset({ ...values } as IFormInputs);
    setIsEditMode(true);
    setPopUpState(true);
  };

  const onSubmit = (formValues: IFormInputs): void => {
    const id = formValues?.id;
    if (isEditMode && id) {
      updateIndividualizedAgreement(
        {
          id: id,
          agreement_clause: formValues.agreement_clause,
          dealer_code: dealer_code,
        },
        {
          onSuccess() {
            const updatedAgreements = individualizedAgreements?.map(
              (item: IDealerAgreement) => {
                return item.id === id
                  ? { ...item, agreement_clause: formValues.agreement_clause }
                  : { ...item };
              }
            );

            setIndividualizedAgreements(updatedAgreements || []);
            form.reset({} as IFormInputs);
            setIsEditMode(false);

            actions.setToast({
              toastMessage: ToastMessages.Record_Updated_Success,
              toastState: true,
            });
          },
          onError: async (error: any) => {
            if (error?.response?.data?.detail?.code != 400) {
              actions.setToast({
                toastMessage: error?.error?.response?.data?.detail?.message,
                toastState: true,
                variant: "error",
              });
            }
          },
        }
      );

      setPopUpState(false);
    } else {
      addIndividualizedAgreement(
        {
          ...formValues,
          dealer_code: dealer_code,
        },
        {
          onSuccess() {
            form.reset({} as IFormInputs);
            getAllDealersIndividualizedAgreements(true);
          },
          onError: async (error: any) => {
            if (error?.response?.data?.detail?.code != 400) {
              actions.setToast({
                toastMessage: error?.error?.response?.data?.detail?.message,
                toastState: true,
                variant: "error",
              });
            }
          },
        }
      );

      setPopUpState(false);
    }
  };

  const handleDefaultValueChange = (id: number, isDefault: boolean) => {
    if (id) {
      updateIndividualizedAgreement(
        {
          id,
          is_default: isDefault,
          dealer_code: dealer_code,
        },
        {
          onSuccess: () => {
            getAllDealersIndividualizedAgreements();
          },
          onError: async (error: any) => {
            if (error?.response?.data?.detail?.code != 400) {
              actions.setToast({
                toastMessage: error?.error?.response?.data?.detail?.message,
                toastState: true,
                variant: "error",
              });
            }
          },
        }
      );
    }
  };

  const handleDeleteAgreement = () => {
    if (deleteAgreementId) {
      deleteIndividualizedAgreement(
        {
          id: deleteAgreementId,
        },
        {
          onSuccess() {
            const updatedAgreements = individualizedAgreements?.filter(
              (item: IDealerAgreement) => item.id !== deleteAgreementId
            );
            setIndividualizedAgreements(updatedAgreements || []);
            setDeleteAgreementId(undefined);

            actions.setToast({
              toastMessage: ToastMessages.Record_Deleted_Success,
              toastState: true,
            });
          },
          onError: async (error: any) => {
            if (error?.response?.data?.detail?.code != 400) {
              actions.setToast({
                toastMessage: error?.error?.response?.data?.detail?.message,
                toastState: true,
                variant: "error",
              });
            }
          },
        }
      );
    }
  };

  const handleDeleteAgreementPopup = (id: number) => {
    if (deletionLoading) {
      return;
    }

    if (id) {
      setDeleteAgreementId(id);
      setConfirmationPopupState(true);
    }
  };

  return (
    <>
      <ConfirmationDialog
        openPopUp={confirmationPopupState}
        onConfirm={handleDeleteAgreement}
        onCancel={() => setDeleteAgreementId(undefined)}
        setPopUpState={setConfirmationPopupState}
        confirmationTitle={DialogMessages.confirmationTitle}
        confirmationText={DialogMessages.confirmationText}
        primaryBtnText={DialogMessages.deleteBtnText}
        icon={<Icon name="DialogDeleteIcon" />}
      />
      <IndividualizedAgreementWrap
        theme={theme}
        className="individualized-agreement-wrap"
      >
        {(individualizedAgreements || [])?.length > 0 && (
          <IndividualizedAgreementList
            individualizedAgreements={individualizedAgreements}
            handleDefaultValueChange={handleDefaultValueChange}
            handleshowEdit={handleshowEdit}
            handleDeleteAgreementPopup={handleDeleteAgreementPopup}
          />
        )}
        <Button
          theme={theme}
          secondary
          fullWidth
          text={"Add New Agreement"}
          onClick={handleshow}
        />
      </IndividualizedAgreementWrap>
      <PersistentDrawer
        title={isEditMode ? "Edit Agreement" : "Add New Agreement"}
        openPopUp={popUpState}
        setPopUpState={setPopUpState}
        enabledPin={false}
        theme={theme}
        children={
          <>
            <IndividualizedAgreementDrawer form={form} />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            type="submit"
            text="Save Agreement"
            fullWidth
            onClick={form.handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default IndividualizedAgreement;
