import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { StyleVariables } from "../../styles/theme";

export const PaginationWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".MuiButtonBase-root-MuiIconButton-root:hover": {
    backgroundColor: "transparent",
  },
  ".MuiTablePagination-root": {
    color: theme.palette.grey[600],
    fontSize: theme.typography.htmlFontSize,
    fontFamily: theme.typography.fontFamily,
  },
  ".MuiTablePagination-select:focus": {
    backgroundColor: "transparent",
  },
  ".MuiTablePagination-selectIcon": {
    top: "calc(35% - 0.5em)",
    left: 24,
  },
  ".MuiPopover-root.MuiMenu-root": {
    ".MuiPaper-root.MuiMenu-paper": {
      ".MuiList-root.MuiMenu-list": {
        ".MuiButtonBase-root.MuiTablePagination-menuItem": {
          color: theme.palette.grey[600],
          "&:hover": {
            backgroundColor: theme.customVariables.tableStripedBg,
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor:
              theme.palette.mode === "light"
                ? StyleVariables.lightGrey
                : theme.palette.grey[300] + "!important",
            fontWeight: theme.customVariables.fontWeightSemiBold,
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[700]
                : theme.palette.common.white,
          },
        },
      },
    },
  },
}));
